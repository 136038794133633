import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap, startWith, map } from 'rxjs/operators';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { BrandsService } from '../../../services/brands/brands.service';
import { Brand } from '../../../types';
import { AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-brand-field',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    CommonModule,
    AsyncPipe,
    MatIcon,
  ],
  templateUrl: './brand-field.component.html',
  styleUrls: ['./brand-field.component.css'],
})
export class BrandFieldComponent implements OnInit {
  @Input() labelClass: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() icon: boolean = false;
  @Input() iconLocation: string = '';
  @Input() className: string = '';
  @Input() formGroup!: FormGroup;
  @Input() controlName: string = '';

  allBrands: Brand[] = [];
  filteredBrands: Observable<Brand[]> = of([]);

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private brandService: BrandsService
  ) {}

  get control(): FormControl {
    return this.formGroup.get(this.controlName) as FormControl;
  }

  ngOnInit() {
    this.fetchBrands();

    this.filteredBrands = this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value) => this.filterBrands(value))
    );
  }

  fetchBrands() {
    this.brandService.getAllBrands().subscribe({
      next: (resp) => {
        this.allBrands = resp;
        this.allBrands.sort((a, b) => a.name.localeCompare(b.name, 'da'));

        this.changeDetectorRef.detectChanges();
      },
      error: (err) => {
        this.changeDetectorRef.detectChanges();
      },
      complete: () => {},
    });
  }

  filterBrands(query: any): Observable<Brand[]> {
    let queryStr: string;

    if (typeof query === 'string') {
      queryStr = query.toLowerCase();
    } else if (typeof query === 'object' && query !== null && 'name' in query) {
      queryStr = query.name.toLowerCase();
    } else {
      return of(this.allBrands.slice(0, 20));
    }

    const filtered = this.allBrands
      .filter((brand) => brand.name.toLowerCase().includes(queryStr))
      .slice(0, 20);

    return of(filtered);
  }

  onOptionSelected(event: any) {
    const selectedBrand = this.allBrands.find(
      (brand) => brand.name === event.option.value
    );
    if (selectedBrand) {
      this.control.setValue(selectedBrand.id);
    }
  }

  displayBrandName(brand: Brand): string {
    return brand ? brand.name : '';
  }
}
