import { Injectable } from '@angular/core';
import {
  CreateSendout,
  Event,
  Sendout,
  Store,
  Template,
  UserType,
  UserTypeEnum,
} from '../../types';
import { BehaviorSubject, Observable } from 'rxjs';

export type SessionData = {
  initialized: boolean;
  token: string;
  userId: number;
  userType: UserTypeEnum;
  store: Store | null;
  storeImpersonation: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  private sessionData$: BehaviorSubject<SessionData>;
  private storeImpersonation$: BehaviorSubject<boolean>;
  private sendoutId: number | null = null;
  private sendout: CreateSendout | Sendout | null = null;
  private event: Event | null = null;
  private sendoutDate: string | null = null;

  constructor() {
    this.sessionData$ = new BehaviorSubject<SessionData>(
      this.getFromSessionStorage<SessionData>('sessionData') || {
        initialized: false,
        token: '',
        userId: 0,
        userType: UserType.Unknown,
        store: null,
        storeImpersonation: false,
      }
    );
    this.storeImpersonation$ = new BehaviorSubject<boolean>(false);
    this.sessionData$.subscribe((data) => {
      if (data.initialized) {
        if (data.storeImpersonation != this.storeImpersonation$.value) {
          this.storeImpersonation$.next(data.storeImpersonation);
        }
        this.saveToSessionStorage<SessionData>('sessionData', data);
      } else {
        sessionStorage.removeItem('sessionData');
      }
    });
  }

  private saveToSessionStorage<T>(key: string, value: T): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  private getFromSessionStorage<T>(key: string): T | null {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  getSessionData(): BehaviorSubject<SessionData> {
    return this.sessionData$;
  }

  setSessionData(data: SessionData): void {
    this.sessionData$.next(data);
  }
  clearSessionData(): void {
    this.sessionData$.next({
      initialized: false,
      token: '',
      userId: 0,
      userType: UserType.Unknown,
      store: null,
      storeImpersonation: false,
    });
    this.storeImpersonation$.next(false);
  }

  getStoreImpersonation(): BehaviorSubject<boolean> {
    return this.storeImpersonation$;
  }

  startStoreImpersonation(store: Store): void {
    this.sessionData$.next({
      ...this.sessionData$.value,
      storeImpersonation: true,
      store: store,
    });
  }

  stopStoreImpersonation(): void {
    this.sessionData$.next({
      ...this.sessionData$.value,
      storeImpersonation: false,
      store: null,
    });
  }

  setSendoutDate(date: string | null) {
    this.sendoutDate = date;
  }

  getSendoutDate() {
    return this.sendoutDate;
  }

  setEvent(event: Event | null): void {
    this.event = event;
  }

  getEvent(): Event | null {
    return this.event;
  }

  setSendout(sendout: CreateSendout | Sendout | null): void {
    this.sendout = sendout;
  }

  getSendout(): CreateSendout | Sendout | null {
    return this.sendout;
  }

  setSendoutId(sendoutId: number | null): void {
    this.sendoutId = sendoutId;
  }

  getSendoutId(): number | null {
    return this.sendoutId;
  }
}
