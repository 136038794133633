import { Component, Output, EventEmitter } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'navlink-toggle-btn',
  standalone: true,
  imports: [MatIconButton, MatIconModule],
  templateUrl: './navlink-toggle-btn.component.html',
  styleUrl: './navlink-toggle-btn.component.css',
})
export class NavlinkToggleBtnComponent {
  @Output() mobileToggleBtnClicked = new EventEmitter<void>();

  handleClick() {
    this.mobileToggleBtnClicked.emit();
  }
}
