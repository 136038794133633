import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MainHeadingSubtextComponent } from '../../atoms/main-heading-subtext/main-heading-subtext.component';
import { SearchFieldComponent } from '../../atoms/search-field/search-field.component';
import { FilterComponent } from '../../molecules/filter/filter.component';
import { TemplateService } from '../../../services/template/template.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import {
  GroupedDataSet,
  Template,
  TableColumns,
  Filter,
  UserType,
} from '../../../types';
import { GlobalDataService } from '../../../services/global-data/global-data.service';
import { SkeletonComponent } from '../../molecules/skeleton/skeleton.component';
import { DynamicAccordionComponent } from '../../molecules/dynamic-accordion/dynamic-accordion.component';
import { DynamicTableComponent } from '../../molecules/dynamic-table/dynamic-table.component';
import { templateFilters } from '../../../constants/filters.constants';
import { SharedFunctionalityService } from '../../../services/shared-functionality/shared-functionality.service';
import { templateCategoryGrouping } from '../../../constants/initial.constants';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-templates',
  standalone: true,
  imports: [
    MainHeadingSubtextComponent,
    SearchFieldComponent,
    FilterComponent,
    CommonModule,
    SkeletonComponent,
    DynamicAccordionComponent,
    DynamicTableComponent,
    FormsModule,
    MatSlideToggleModule,
  ],
  templateUrl: './templates.component.html',
  styleUrl: './templates.component.css',
})
export class TemplatesComponent implements OnInit {
  sessionData$ = this.globalDataService.getSessionData();
  allTemplates: Template[] = [];
  templates: Template[] = [];

  allTemplatesAccordion: GroupedDataSet<Template> = null!;
  templatesAccordion: GroupedDataSet<Template> = null!;

  isLoading: boolean = false;
  slideToggleState: boolean = true;
  storeImpersonation: boolean = false;
  emptyStateText: string = 'Ingen Skabelon';

  templateFilters: Filter[] = templateFilters;

  columns: TableColumns[] = [
    { field: 'templateLabel', header: 'Navn', sort: true },
    {
      field: 'brand',
      header: 'Brand',
      sort: (a, b) => {
        if (!a || !a.name) return -1;
        if (!b || !b.name) return -1;
        return a.name.localeCompare(b.name, 'da');
      },
    },
    { field: 'description', header: 'Beskrivelse', sort: false },
    { field: 'status', header: 'Status', sort: false },
    { field: 'edit', header: 'Redigér ', sort: false },
  ];

  constructor(
    private templateService: TemplateService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private snackBar: SnackbarService,
    private globalDataService: GlobalDataService,
    private sharedFunctionalityService: SharedFunctionalityService
  ) {}

  ngOnInit(): void {
    this.getAllTemplates();
  }

  getAllTemplates = () => {
    this.isLoading = true;
    this.templateService.getAllTemplates(UserType.ActiveDirectory).subscribe({
      next: (data) => {
        this.templates = data;
        this.templates.sort((a, b) =>
          a.templateLabel.localeCompare(b.templateLabel)
        );
        this.allTemplates = [...this.templates];

        this.templatesAccordion =
          this.sharedFunctionalityService.getGroupedDataSet<Template>(
            this.allTemplates,
            templateCategoryGrouping,
            (e) => e.category
          );
        this.allTemplatesAccordion = { ...this.templatesAccordion };

        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error fetching templates', error);
        this.isLoading = false;
      },
      complete: () => {
        this.changeDetectorRef.detectChanges();
      },
    });
  };

  deleteById = (id: number) => {
    this.templateService.deleteTemplateById(id).subscribe({
      next: () => {},
      error: (error) => {
        console.error('Error fetching templates', error);
      },
      complete: () => {
        this.templateService.notifyTemplatesRefresh();
        this.snackBar.show('Skabelonen er slettet');
        this.changeDetectorRef.detectChanges();
      },
    });
  };

  handleCreateTemplateClick = () => {
    this.router.navigate(['template', 'create']);
  };

  applySearchFilter(filterValue: string) {
    const { filteredItems, slideToggleState } =
      this.sharedFunctionalityService.applySearchFilter(
        filterValue,
        this.allTemplates,
        ['templateLabel'],
        this.changeDetectorRef,
        this.slideToggleState
      );
    this.templates = filteredItems;
    this.slideToggleState = slideToggleState;
  }

  onSlideToggleChange() {
    this.slideToggleState = this.sharedFunctionalityService.onSlideToggleChange(
      this.slideToggleState
    );
  }

  onCategoryFilterChange() {
    this.templatesAccordion = this.sharedFunctionalityService.onFilterChange(
      this.templateFilters,
      this.allTemplatesAccordion,
      'accordionHeading'
    );
  }

  handleRowClick(row: Template) {
    this.router.navigate(['template', row.id]);
  }
}
