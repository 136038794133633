import { Component, Input } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Options, Option } from '../../../types';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    CommonModule
  ],
  templateUrl: './select-field.component.html',
  styleUrl: './select-field.component.css',
})
export class SelectFieldComponent {
  @Input() placeholder: string = '';
  @Input() className: string = '';
  @Input() label: string = '';
  @Input() labelClass: string = '';
  @Input() option: (Option | Options)[] = [];

  @Input() formGroup!: FormGroup;
  @Input() controlName: string = '';

  get control(): FormControl {
    return this.formGroup.get(this.controlName) as FormControl;
  }
}
