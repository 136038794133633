@if (showDropDown) {
    <div
        class="dropdown-container position-absolute"
        (outsideClick)="onOutsideClick()"
        appClickOutside
    >
        <app-search-field
            extraClasses="search-field-container-100"
            placeholder="Søg butik"
            (valueChange)="filterStores($event)"
        ></app-search-field>

        <div class="store-list-container">
            @for (store of filteredStores; track store.id) {
                <div class="store" (click)="handleStoreClick(store)">
                    <span>{{ store.place }}</span
                    ><span class="store-id">{{ store.id }}</span>
                </div>
            }
        </div>
    </div>
}
