<div class="form-field-container d-flex flex-column" [formGroup]="formGroup" [ngStyle]="{
    '--border-color': control.touched && control.invalid ? 'red' : '#afcbe0'
  }">
  @if (label !== "") {
  <label class="{{ labelClass }}">{{ label }}</label>
  }
  <mat-form-field appearance="outline">
    <input matInput [matDatepicker]="picker" [min]="overrideLocks ? hqUserMinDate : minDate" placeholder="{{ placeholder }}" [formControlName]="controlName" [matDatepickerFilter]="weekendsDatesFilter" />
    <!-- <mat-datepicker-toggle matSuffix [for]="picker" style="position: relative">
      <mat-icon matDatepickerToggleIcon svgIcon="calendar" style="position: absolute; top: 8px; right: 12px"></mat-icon>
    </mat-datepicker-toggle> -->
    <mat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
    </mat-datepicker-toggle>

    <mat-datepicker #picker [startAt]="control.value ? control.value : minDate" [dateClass]="dateClass">
      <mat-datepicker-actions>
        <button mat-button matDatepickerCancel>Annuler</button>
        <button mat-raised-button matDatepickerApply>Vælg</button>
      </mat-datepicker-actions>
    </mat-datepicker>
    @if (control.touched && control.invalid) {
    <div class="position-absolute form-validation-error-container">
      @if (control.errors?.['required']) {
      <small>Dette felt er påkrævet.</small>
      }
      @if (control.errors?.['minlength']) {
      <small>Minimum length is 3 characters.</small>
      }
      @if (control.errors?.['email']) {
      <small>Must be an email.</small>
      }
      @if (control.errors?.['matDatepickerMin']) {
      <small>Tidligste udsendelsesdato er {{ minDate | dfnsFormat : 'd.m.yyyy' }}</small>
      }
      @if (control.errors?.['dateBefore']) {
      <small>Udsendelsesdato skal være før eventdato</small>
      }
    </div>
    }
  </mat-form-field>
</div>
