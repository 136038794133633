import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Sendout } from '../../../types';
import { CommonModule } from '@angular/common';
import { DateFnsModule } from 'ngx-date-fns';
import { SendoutStatusPipe } from '../../../pipes/SendoutStatus.pipe';

@Component({
  selector: 'app-broadcast-info',
  standalone: true,
  imports: [CommonModule, DateFnsModule, SendoutStatusPipe],
  templateUrl: './broadcast-info.component.html',
  styleUrl: './broadcast-info.component.css',
})
export class BroadcastInfoComponent {
  @Input() week: string = '';
  @Input() allSendouts: Sendout[] = [];
  @Output() buttonClick = new EventEmitter();

  handleSendoutClick(sendout: Sendout) {
    this.buttonClick.emit(sendout);
  }
}
