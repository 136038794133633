import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, mergeMap, Observable, of, Subject, switchMap, zip } from 'rxjs';
import { MainService } from '../main.service';
import {
  Sendout,
  Template,
  Event,
  UserType,
  SendoutStatus,
  TemplateStatus,
  UserTypeEnum,
  TemplateCategoryEnum,
} from '../../types';
import { getISOWeek } from 'date-fns';
import { BrandsService } from '../brands/brands.service';

interface RawTemplate {
  id: number;
  createdAt: string;
  createdBy: number;
  updatedAt: string;
  updatedBy: number;
  status: string;
  templateName: string;
  templateLabel: string;
  description: string;
  targetGroupDescription: string;
  brandId: string;
  category: TemplateCategoryEnum;
  storeAvailability: string;
  eventRequired: boolean;
  defaultContentSubjectLine: string;
  defaultContentPreheader: string;
  defaultContentHeadline: string;
  defaultContentBodyCopy: string;
  defaultContentCtaText: string;
  defaultContentCtaUrl: string;
  defaultContentDisclaimer: string;
  availableImages: string;
  allowOverrideSubjectLine: boolean;
  allowOverridePreheader: boolean;
  allowOverrideHeadline: boolean;
  allowOverrideBodyCopy: boolean;
  allowOverrideCtaText: boolean;
  allowOverrideCtaUrl: boolean;
  allowOverrideDisclaimer: boolean;
  isDeleted: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private refreshTemplatesSubject = new Subject<void>();
  public refreshTemplates$ = this.refreshTemplatesSubject.asObservable();

  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private brandService: BrandsService
  ) {}

  transformRawTemplate(rawTemplate: RawTemplate): Template {
    const t: Template = {
      id: rawTemplate.id,
      createdAt: new Date(rawTemplate.createdAt),
      createdBy: rawTemplate.createdBy,
      updatedAt: new Date(rawTemplate.updatedAt),
      updatedBy: rawTemplate.updatedBy,
      status: rawTemplate.status,
      templateName: rawTemplate.templateName,
      templateLabel: rawTemplate.templateLabel,
      description: rawTemplate.description,
      targetGroupDescription: rawTemplate.targetGroupDescription,
      brandId: rawTemplate.brandId ? parseInt(rawTemplate.brandId, 10) : null,
      category: rawTemplate.category,
      storeAvailability: rawTemplate.storeAvailability,
      eventRequired: rawTemplate.eventRequired,
      defaultContentSubjectLine: rawTemplate.defaultContentSubjectLine,
      defaultContentPreheader: rawTemplate.defaultContentPreheader,
      defaultContentHeadline: rawTemplate.defaultContentHeadline,
      defaultContentBodyCopy: rawTemplate.defaultContentBodyCopy,
      defaultContentCtaText: rawTemplate.defaultContentCtaText,
      defaultContentCtaUrl: rawTemplate.defaultContentCtaUrl,
      defaultContentDisclaimer: rawTemplate.defaultContentDisclaimer,
      availableImages: [],
      allowOverrideSubjectLine: rawTemplate.allowOverrideSubjectLine,
      allowOverridePreheader: rawTemplate.allowOverridePreheader,
      allowOverrideHeadline: rawTemplate.allowOverrideHeadline,
      allowOverrideBodyCopy: rawTemplate.allowOverrideBodyCopy,
      allowOverrideCtaText: rawTemplate.allowOverrideCtaText,
      allowOverrideCtaUrl: rawTemplate.allowOverrideCtaUrl,
      allowOverrideDisclaimer: rawTemplate.allowOverrideDisclaimer,
      isDeleted: rawTemplate.isDeleted,
    };

    try {
      t.availableImages = JSON.parse(rawTemplate.availableImages);
    } catch (e) {
      t.availableImages = [];
    }
    return t;
  }

  getAllTemplates(userType: UserTypeEnum): Observable<Template[]> {
    return zip(
      this.http.get<RawTemplate[]>(
        this.mainService.getApiURL() +
          `Template/Templates?userType=${userType}`,
        {
          headers: this.mainService.getHeaders(),
        }
      ),
      this.brandService.getAllBrands()
    ).pipe(
      map(([rawTemplates, brands]) => {
        const templates = rawTemplates.map(this.transformRawTemplate);
        templates.forEach((template) => {
          if (template.brandId) {
            template.brand = brands.find((b) => b.id === template.brandId);
          }
        });
        return templates;
      })
    );
  }

  getTemplateById(id: number): Observable<Template> {
    return this.http
      .get<RawTemplate>(this.mainService.getApiURL() + `Template/${id}`, {
        headers: this.mainService.getHeaders(),
      })
      .pipe(
        map(this.transformRawTemplate),
        mergeMap((template) => {
          return this.brandService.getBrandById(template.brandId || 0).pipe(
            map((brand) => {
              if (brand) {
                template.brand = brand;
              }
              return template;
            })
          );
        })
      );
  }

  deleteTemplateById(id: number): Observable<any> {
    return this.http.delete<any>(
      this.mainService.getApiURL() + `Template/${id}`,
      {
        headers: this.mainService.getHeaders(),
      }
    );
  }

  createTemplate(body: any): Observable<any> {
    return this.http.post<any>(
      this.mainService.getApiURL() + 'Template',
      body,
      {
        headers: this.mainService.getHeaders(),
      }
    );
  }

  updateTemplate(body: any): Observable<any> {
    return this.http.put<any>(this.mainService.getApiURL() + 'Template', body, {
      headers: this.mainService.getHeaders(),
    });
  }

  notifyTemplatesRefresh(): void {
    this.refreshTemplatesSubject.next();
  }

  createSendoutFromTemplate(
    sendoutDate: Date,
    template: Template,
    event?: Event | null
  ): Sendout {
    let sendoutName = `${
      event ? event.name : template.templateName
    } - Uge ${getISOWeek(sendoutDate)}`;

    const s: Sendout = {
      id: 0,
      sendoutTemplateId: template.id,
      storeId: 0,

      createdAt: new Date(),
      createdBy: 0,
      updatedByName: '',
      createdByName: '',
      updatedAt: new Date(),
      updatedBy: 0,
      reviewedAt: null,
      reviewedBy: 0,
      sendoutName: sendoutName,
      status: SendoutStatus.Draft,
      eventId: event ? event.id : '',
      contentSubjectLine: template.defaultContentSubjectLine,
      contentPreheader: template.defaultContentPreheader,
      contentHeadline: template.defaultContentHeadline,
      contentBodyCopy: template.defaultContentBodyCopy,
      contentCtaText: template.defaultContentCtaText,
      contentCtaUrl: template.defaultContentCtaUrl,
      contentImageUrl:
        template.availableImages.length > 0 ? template.availableImages[0] : '',
      contentDisclaimer: template.defaultContentDisclaimer,
      contactPerson: '',
      sendoutDate: sendoutDate,
      isDeleted: false,
    };
    if (event) {
      s.event = event;
    }
    return s;
  }
}
