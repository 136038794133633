import { Component, Input } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';

export enum RadioFieldDirection {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

@Component({
  selector: 'app-radio-field',
  standalone: true,
  imports: [MatRadioModule, ReactiveFormsModule, CommonModule],
  templateUrl: './radio-field.component.html',
  styleUrl: './radio-field.component.css',
})
export class RadioFieldComponent {
  RadioFieldDirection = RadioFieldDirection;

  @Input() labelClass: string = '';
  @Input() labelText: string = '';
  @Input() subTextClass: string = '';
  @Input() subText: string = '';

  @Input() direction: RadioFieldDirection = RadioFieldDirection.Horizontal;
  @Input() formGroup!: FormGroup;
  @Input() controlName: string = '';

  get control(): FormControl {
    return this.formGroup.get(this.controlName) as FormControl;
  }
}
