import { Component, Input } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-text-area',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    CommonModule,
  ],
  templateUrl: './text-area.component.html',
  styleUrl: './text-area.component.css',
})
export class TextAreaComponent {
  @Input() labelClass: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() icon: boolean = false;
  @Input() iconLocation: string = '';
  @Input() formGroup!: FormGroup;
  @Input() controlName: string = '';
  @Input() enablePasteButton: boolean = false;
  @Input() hint?: string;
  get control(): FormControl {
    return this.formGroup.get(this.controlName) as FormControl;
  }

  onPasteButtonClick() {
    navigator.clipboard
      .readText()
      .then((text) => {
        this.control.setValue(this.control.value + text);
      })
      .catch((err) => {
        console.error('Failed to read clipboard contents: ', err);
      });
  }
}
