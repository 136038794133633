import {
  Component,
  ViewEncapsulation,
  Input,
  OnInit,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarConfigModule } from '../../../calendar-config';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import {
  startOfDay,
  addDays,
  isSameDay,
  startOfMonth,
  differenceInCalendarMonths,
  format,
  isBefore,
  isWithinInterval,
} from 'date-fns';
import { EventColor } from 'calendar-utils';
import { CalendarHelpersComponent } from '../../atoms/calendar-helpers/calendar-helpers.component';
import { FilterComponent } from '../filter/filter.component';
import { Filter, Sendout, Event, Template } from '../../../types';
import { Router } from '@angular/router';
import { DialogService } from '../../../services/dialog/dialog.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { TemplateService } from '../../../services/template/template.service';
import { isSunday, isMonday, getISOWeek } from 'date-fns';
import { SendoutService } from '../../../services/sendout/sendout.service';

const colors: Record<string, EventColor> = {
  blue: {
    primary: '#002643',
    secondary: 'none',
  },
  silver: {
    primary: '#AFCBE0',
    secondary: 'none',
  },
};

@Component({
  selector: 'app-full-calendar',
  standalone: true,
  imports: [
    CommonModule,
    CalendarConfigModule,
    CalendarHelpersComponent,
    FilterComponent,
  ],
  templateUrl: './full-calendar.component.html',
  styleUrls: ['./full-calendar.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FullCalendarComponent implements OnInit, OnChanges {
  @Input() sendouts: Sendout[] = [];
  @Input() events: Event[] = [];
  locale: string = 'da';

  templateFilters: Filter[] = [
    {
      id: 1,
      text: 'Udsendelse',
      classes: 'custom-underline udsendelse-bg',
      checked: true,
    },
    {
      id: 2,
      text: 'Event',
      classes: 'custom-underline event-bg',
      checked: true,
    },
    {
      id: 3,
      text: 'Låst periode',
      classes: 'custom-underline last-period-bg',
      checked: true,
    },
  ];
  today = startOfDay(new Date());
  view: CalendarView = CalendarView.Month;
  startOfMonthDate: Date = startOfMonth(this.today);
  viewDate: Date = startOfMonth(this.today);

  displayPrevButton = false;
  displayNextButton = true;

  calenderEvents: CalendarEvent[] = [];
  lockPeriods: Date[] = [];

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private templateService: TemplateService,
    private changeDetectorRef: ChangeDetectorRef,
    private sendoutService: SendoutService
  ) {}

  ngOnInit(): void {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    await this.onFilterChange();
  }

  viewDateChanged(date: Date) {
    this.displayPrevButton = !isSameDay(date, this.startOfMonthDate);
    this.displayNextButton =
      differenceInCalendarMonths(date, this.startOfMonthDate) <= 3;
  }

  isSunday(date: Date): boolean {
    return isSunday(date);
  }

  isMonday(date: Date): boolean {
    return isMonday(date);
  }

  getISOWeek(date: Date): number {
    return getISOWeek(date);
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return isSameDay(date1, date2);
  }

  selectDayAndRoute = (date: Date) => {
    this.router.navigate(['sendout', 'create'], {
      queryParams: { sendoutDate: format(date, 'yyyy-MM-dd') },
    });
  };

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isBefore(date, this.today)) {
      this.snackbarService.show('Der kan ikke oprettes udsendelser i fortiden');
    } else if (
      isWithinInterval(date, { start: this.today, end: addDays(this.today, 2) })
    ) {
      this.snackbarService.show(
        'Der kan ikke oprettes udsendelser indeværende dag eller de næste to dage'
      );
    } else if (isSunday(date)) {
      this.snackbarService.show('Der kan ikke oprettes udsendelser på søndage');
    } else {
      if (this.isLockPeriod(date)) {
        this.dialogService.openDialog(
          'Er du sikker på, at du vil oprette en udsendelse?',
          'Du er ved at oprette en udsendelse, som er mindre end syv dage før eller efter en anden udsendelse.',
          'Opret udsendelse',
          () => {
            this.selectDayAndRoute(date);
          },
          undefined,
          '450px',
          'content-container',
          '',
          '',
          'dialog-actions'
        );
      } else {
        this.selectDayAndRoute(date);
      }
    }
  }

  getTemplateById = (templateId: number): Promise<Template> => {
    return new Promise((resolve, reject) => {
      this.templateService.getTemplateById(templateId).subscribe({
        next: (response) => {
          resolve(response);
        },
        error: (error) => {
          console.error('Error fetching templates', error);
          reject(error);
        },
        complete: () => {
          this.changeDetectorRef.detectChanges();
        },
      });
    });
  };

  eventClicked(event: CalendarEvent): void {
    if (event.color?.primary === '#AFCBE0') {
      const eventLink = event.meta;
      window.open(eventLink, '_blank');
    } else if (event.color?.primary === '#002643') {
      const sendoutId = event.id as number;
      this.router.navigate(['sendout', sendoutId]);
    }
  }

  calculateLockPeriod(sendoutDate: Date) {
    const startLock = addDays(sendoutDate, -7);
    const endLock = addDays(sendoutDate, 7);

    for (let date = startLock; date <= endLock; date = addDays(date, 1)) {
      this.lockPeriods.push(startOfDay(date));
    }
  }

  async onFilterChange(): Promise<void> {
    const showSendouts = this.templateFilters.find(
      (f) => f.text === 'Udsendelse'
    )?.checked;
    const showEvents = this.templateFilters.find(
      (f) => f.text === 'Event'
    )?.checked;

    const calenderEvents: CalendarEvent<any>[] = [];

    if (showSendouts) {
      this.sendouts.forEach((sendout) => {
        if (sendout.sendoutDate !== null) {
          calenderEvents.push({
            id: sendout.id,
            start: new Date(sendout.sendoutDate),
            end: new Date(sendout.sendoutDate),
            title: sendout.sendoutName,
            color: { ...colors['blue'] },
          });
        }
      });
    }
    const sendoutDateLocks =
      await this.sendoutService.getUpcomingSendoutDateLocks(this.sendouts);
    this.lockPeriods = sendoutDateLocks.map((lock) => new Date(lock.date));
    // this.lockPeriods = [];

    if (showEvents) {
      this.events.forEach((event) => {
        calenderEvents.push({
          id: event.id,
          start: new Date(event.startsAt),
          end: new Date(event.endsAt),
          title: event.name,
          color: { ...colors['silver'] },
          meta: event.link,
        });
      });
    }
    this.calenderEvents = calenderEvents;
    this.changeDetectorRef.detectChanges();
  }

  isLockPeriod(date: Date): boolean {
    return this.lockPeriods.some((lockDate) => isSameDay(lockDate, date));
  }

  isLockPeriodStyled(date: Date): boolean {
    const showLockPeriods = this.templateFilters.find(
      (f) => f.text === 'Låst periode'
    )?.checked;
    if (!showLockPeriods) {
      return false;
    }
    return this.isLockPeriod(date);
  }
}
