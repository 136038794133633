<div class="d-flex flex-column justify-content-center align-items-center login-outer-container">
  <app-logo></app-logo>
  <div class="login-inner-container inner-container-width">
    <app-main-heading extraClasses="login" headingText="Store Activation Module"></app-main-heading>

    <div class="d-flex justify-content-between flex-sm-column flex-column flex-md-row gap-md-0 gap-2">
      <filled-button (childEvent)="onSubmit()" [buttonText]="'Login Store'" className="custom-padding full-width"></filled-button>

      <filled-button (childEvent)="loginAD()" [buttonText]="'Login HQ'" className="custom-padding full-width"></filled-button>
    </div>
  </div>
</div>
