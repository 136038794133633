import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  NgZone,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MainHeadingSubtextComponent } from '../../atoms/main-heading-subtext/main-heading-subtext.component';
import { UpcommingBroadcastsComponent } from '../../molecules/upcomming-broadcasts/upcomming-broadcasts.component';
import { BroadcastEventsComponent } from '../../molecules/broadcast-events/broadcast-events.component';
import { FullCalendarComponent } from '../../molecules/full-calendar/full-calendar.component';
import { CardComponent } from '../../molecules/card/card.component';
import { SendoutService } from '../../../services/sendout/sendout.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { EventsService } from '../../../services/events/events.service';
import {
  SendoutByWeek,
  Sendout,
  Event,
  EventWithSendouts,
  Template,
} from '../../../types';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CommonModule } from '@angular/common';
import {
  GlobalDataService,
  SessionData,
} from '../../../services/global-data/global-data.service';
import { Router } from '@angular/router';
import { TemplateService } from '../../../services/template/template.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  getISOWeek,
  isAfter,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfToday,
} from 'date-fns';
import { SkeletonComponent } from '../../molecules/skeleton/skeleton.component';

@Component({
  selector: 'app-overview',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    UpcommingBroadcastsComponent,
    MainHeadingSubtextComponent,
    BroadcastEventsComponent,
    FullCalendarComponent,
    CardComponent,
    NgxSkeletonLoaderModule,
    CommonModule,
    SkeletonComponent,
  ],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.css',
})
export class OverviewComponent implements OnInit, OnDestroy {
  allSendouts: Sendout[] = [];
  segregatedSendouts: SendoutByWeek[] = [];
  isLoading: boolean = false;
  events: Event[] = [];
  eventsWithSendoutDate: EventWithSendouts[] = [];
  storeImpersonation: boolean = false;
  template: Template | null = null;
  onlyEvents: Event[] = [];
  allEvents: Event[] = [];

  sessionData$: BehaviorSubject<SessionData> =
    this.globalDataService.getSessionData();

  sessionDataSubscription?: Subscription;

  constructor(
    private sendoutService: SendoutService,
    private snackbarService: SnackbarService,
    private changeDetectorRef: ChangeDetectorRef,
    private eventsService: EventsService,
    private globalDataService: GlobalDataService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    console.log(`[OverviewComponent] ngOnInit`);
    this.isLoading = true;
    this.sessionDataSubscription = this.sessionData$.subscribe(
      (sessionData) => {
        this.isLoading = true;
        if (sessionData?.store?.id) {
          Promise.all([
            this.getAllSendouts(sessionData?.store?.id),
            this.getEventsUsingStoreId(sessionData?.store?.id),
          ]).then(() => {
            this.ngZone.run(() => {
              this.isLoading = false;
              this.changeDetectorRef.detectChanges();
            });
          });
        }
      }
    );
  }

  ngOnDestroy(): void {
    console.log(`[OverviewComponent] ngOnDestroy`);
    this.sessionDataSubscription?.unsubscribe();
  }

  isSendoutDateInRange(
    startDateStr: string,
    endDateStr: string,
    sendoutDateStr: string
  ): boolean {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const sendoutDate = new Date(sendoutDateStr);

    return sendoutDate >= startDate && sendoutDate <= endDate;
  }

  segregateSendoutsFn = (sendouts: Sendout[]): SendoutByWeek[] => {
    const sendoutWeekMap = new Map<string, Sendout[]>();

    sendouts.forEach((sendout: Sendout) => {
      const week = `Uge ${getISOWeek(sendout.sendoutDate)}`;
      if (!sendoutWeekMap.has(week)) {
        sendoutWeekMap.set(week, []);
      }
      sendoutWeekMap.get(week)?.push(sendout);
    });

    const sortedSendoutByWeekArray: SendoutByWeek[] = [];

    Array.from(sendoutWeekMap.entries())
      .sort((a, b) => a[0].localeCompare(b[0]))
      .forEach(([week, sendouts]) => {
        sortedSendoutByWeekArray.push({ week, sendouts });
      });

    return sortedSendoutByWeekArray;
  };

  getEventsUsingStoreId = (storeId: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.eventsService.getAllEventsByStore(storeId).subscribe({
        next: (response) => {
          const currentDate = startOfToday();
          this.onlyEvents = response.filter((event: Event) => {
            return (
              event.type === 'Event' &&
              (isSameDay(event.startsAt, currentDate) ||
                isAfter(event.startsAt, currentDate))
            );
          });
          this.addSendoutDateToEvents([...this.onlyEvents]);
          console.log(`[OverviewComponent][getEventsUsingStoreId] Done`);
          this.changeDetectorRef.detectChanges();
          resolve();
        },
        error: (error) => {
          console.log('Event API Failure:', error);
          this.changeDetectorRef.detectChanges();
          reject(error);
        },
        complete: () => {},
      });
    });
  };

  addSendoutDateToEvents = (events: Event[]) => {
    if (events.length > 0) {
      this.eventsWithSendoutDate = events.map(
        (event: Event): EventWithSendouts => {
          const sendouts = this.allSendouts
            .filter((sendout: Sendout) => sendout.eventId === event.id)
            .sort((a, b) => b.sendoutDate.getTime() - a.sendoutDate.getTime());

          return {
            ...event,
            sendouts: sendouts,
          };
        }
      );

      this.eventsWithSendoutDate.sort((a, b) => {
        return a.startsAt.getTime() - b.startsAt.getTime();
      });
    }
  };

  getAllSendouts = (storeId: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.sendoutService.getAllSendouts().subscribe({
        next: (response) => {
          const today = startOfDay(new Date());
          const startOfCurrentMonth = startOfMonth(today);
          this.allSendouts = response.filter((sendout: Sendout) => {
            return (
              sendout.sendoutDate !== null &&
              (isAfter(sendout.sendoutDate, startOfCurrentMonth) ||
                isSameDay(sendout.sendoutDate, startOfCurrentMonth))
            );
          });

          this.segregatedSendouts = this.segregateSendoutsFn(
            this.allSendouts.filter((s) => {
              return (
                isSameDay(s.sendoutDate, today) || isAfter(s.sendoutDate, today)
              );
            })
          );
          console.log(`[OverviewComponent][getAllSendouts] Done`);
          resolve();
        },
        error: (error) => {
          this.snackbarService.show('Udsendelser kunne ikke hentes');
          console.log('Overview get all sendout api Failure :', error);
          reject(error);
        },
        complete: () => {},
      });
    });
  };

  sendoutDateClick(sendout: Sendout) {
    this.router.navigate(['sendout', sendout.id]);
  }
}
