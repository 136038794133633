import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BroadcastInfoComponent } from '../broadcast-info/broadcast-info.component';
import { Sendout, SendoutByWeek } from '../../../types';

@Component({
  selector: 'app-upcomming-broadcasts',
  standalone: true,
  imports: [BroadcastInfoComponent],
  templateUrl: './upcomming-broadcasts.component.html',
  styleUrl: './upcomming-broadcasts.component.css',
})
export class UpcommingBroadcastsComponent {
  @Input() mainHeading: string = '';
  @Input() sendouts: SendoutByWeek[] = [];
  @Output() buttonClick = new EventEmitter();

  sendoutClick(sendout: Sendout) {
    this.buttonClick.emit(sendout);
  }
}
