<div class="form-field-container d-flex flex-column" [formGroup]="formGroup" [ngStyle]="{
    '--border-color': control.touched && control.invalid ? 'red' : '#afcbe0'
  }">
  <label class="{{ labelClass }}">{{ label }}</label>
  <mat-form-field appearance="outline">
    <textarea matInput placeholder="{{ placeholder }}" rows="5" class="resize" [formControlName]="controlName"></textarea>
    @if (hint) {
    <mat-hint>{{ hint }}</mat-hint>
    }
    @if (icon) {
    <mat-icon mat-suffix class="position-absolute icon">
      <img src="{{ iconLocation }}" alt="icon" class="position-absolute icon-img" />
    </mat-icon>
    }
    @if (enablePasteButton && !control.disabled) {
    <mat-icon matSuffix class="icon" style="margin-top:-8px;" matTooltip="Indsæt fra udklipsholder" (click)="onPasteButtonClick()">content_paste</mat-icon>
    }
    @if (control.touched && control.invalid) {
    <div class="position-absolute form-validation-error-container">
      @if (control.errors?.['required']) {
      <small>Dette felt er påkrævet.</small>
      } @if (control.errors?.['minlength']) {
      <small>Minimum length is 3 characters.</small>
      }
    </div>
    }
  </mat-form-field>
</div>
