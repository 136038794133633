import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { MainHeadingSubtextComponent } from '../../atoms/main-heading-subtext/main-heading-subtext.component';
import { FilterComponent } from '../../molecules/filter/filter.component';
import { SendoutService } from '../../../services/sendout/sendout.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import {
  Sendout,
  Filter,
  GroupedDataSet,
  Event,
  UserType,
  SendoutStatus,
  SendoutViewModel,
} from '../../../types';
import {
  GlobalDataService,
  SessionData,
} from '../../../services/global-data/global-data.service';
import { Router } from '@angular/router';
import { CardComponent } from '../../molecules/card/card.component';
import { CommonModule } from '@angular/common';
import { SearchFieldComponent } from '../../atoms/search-field/search-field.component';
import { DynamicAccordionComponent } from '../../molecules/dynamic-accordion/dynamic-accordion.component';
import { DynamicTableComponent } from '../../molecules/dynamic-table/dynamic-table.component';
import { TableColumns } from '../../../types';
import { CardsGroupComponent } from '../../molecules/cards-group/cards-group.component';
import { SkeletonComponent } from '../../molecules/skeleton/skeleton.component';
import { sendoutFilters } from '../../../constants/filters.constants';
import { SharedFunctionalityService } from '../../../services/shared-functionality/shared-functionality.service';
import { Subscription } from 'rxjs';
import { differenceInDays } from 'date-fns';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SendoutStatusPipe } from '../../../pipes/SendoutStatus.pipe';
import { GenericCardComponent } from '../../molecules/generic-card/generic-card.component';
import { DateFnsModule } from 'ngx-date-fns';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-email-sendout',
  standalone: true,
  imports: [
    MainHeadingSubtextComponent,
    FilterComponent,
    CardComponent,
    CommonModule,
    SearchFieldComponent,
    DynamicAccordionComponent,
    DynamicTableComponent,
    CardsGroupComponent,
    SkeletonComponent,
    FormsModule,
    MatSlideToggleModule,
    SendoutStatusPipe,
    GenericCardComponent,
    DateFnsModule,
    MatExpansionModule,
  ],
  templateUrl: './email-sendout.component.html',
  styleUrl: './email-sendout.component.css',
})
export class EmailSendoutComponent implements OnInit, OnDestroy {
  UserType = UserType;

  sessionDataSubscription?: Subscription;
  sessionData!: SessionData;

  isLoading: boolean = false;
  slideToggleState: boolean = true;

  cardButtonText: string = 'Se udsendelse';
  emptyStateText: string = 'Ingen udsendelser';
  cardTitle: string = 'Udsendelse';

  allSendouts: SendoutViewModel[] = [];
  sendouts: SendoutViewModel[] = [];

  allSendoutsAccordion: GroupedDataSet<SendoutViewModel> = {
    groups: [],
  };
  sendoutsAccordion: GroupedDataSet<SendoutViewModel> = {
    groups: [],
  };

  events: Event[] = [];

  statusFilters: Filter[] = sendoutFilters;
  statusFiltersAll: Filter[] = sendoutFilters;

  columns: TableColumns[] = [
    { field: 'sendoutName', header: 'Navn', sort: false },
    { field: 'store', header: 'Butik', sort: false },
    { field: 'before', header: 'Dage fra foregående udsendelse', sort: false },
    { field: 'sendoutDate', header: 'Udsendelsesdato', sort: true },
    { field: 'after', header: 'Dage til næste udsendelse', sort: false },
    { field: 'createdAt', header: 'Oprettelsesdato ', sort: true },
    { field: 'contactPerson', header: 'Kontaktperson ', sort: false },
    { field: 'edit', header: 'Redigér ', sort: false },
  ];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private sendoutService: SendoutService,
    private snackbarService: SnackbarService,
    private globalDataService: GlobalDataService,
    private router: Router,
    private sharedFunctionalityService: SharedFunctionalityService
  ) {}

  ngOnInit(): void {
    this.sessionDataSubscription = this.globalDataService
      .getSessionData()
      .subscribe((sessionData) => {
        this.sessionData = sessionData;

        // Check if impersonation was just disabled
        if (
          sessionData &&
          !sessionData.storeImpersonation &&
          sessionData.userType == UserType.ActiveDirectory
        ) {
          window.location.reload(); // This isn't great, but it works and it's a lot easier than messing with the router
        } else {
          this.getAllSendouts();
          this.filterFilters();
        }
      });
  }

  ngOnDestroy(): void {
    this.sessionDataSubscription?.unsubscribe();
  }

  filterFilters() {
    if (
      this.sessionData.userType == UserType.ActiveDirectory &&
      !this.sessionData.storeImpersonation
    ) {
      this.statusFilters = this.statusFiltersAll.filter(
        (link) => link.id !== 1
      );
    }
  }

  removeKladderCategory() {
    if (
      this.sessionData.userType == UserType.ActiveDirectory &&
      this.sessionData.storeImpersonation == false &&
      this.sendoutsAccordion != null
    ) {
      this.sendoutsAccordion.groups = this.allSendoutsAccordion.groups.filter(
        (group) => {
          return group.id !== SendoutStatus.Draft;
        }
      );
    }
  }

  applySearchFilter(filterValue: string) {
    const { filteredItems, slideToggleState } =
      this.sharedFunctionalityService.applySearchFilter(
        filterValue,
        this.allSendouts,
        ['sendoutName', 'storeId', 'store.address'],
        this.changeDetectorRef,
        this.slideToggleState
      );
    this.sendouts = filteredItems;
    this.slideToggleState = slideToggleState;
  }

  onSlideToggleChange() {
    this.slideToggleState = this.sharedFunctionalityService.onSlideToggleChange(
      this.slideToggleState
    );
  }

  sendoutClick = async (sendout: Sendout) => {
    this.router.navigate(['sendout', sendout.id]);
  };

  processSendouts(data: Sendout[]): SendoutViewModel[] {
    data.sort((a, b) => a.sendoutDate?.getTime() - b.sendoutDate?.getTime());

    const groupedByStore: Map<number, Sendout[]> = data.reduce(
      (acc, sendout) => {
        if (!acc.has(sendout.storeId)) {
          acc.set(sendout.storeId, []);
        }
        acc.get(sendout.storeId)?.push(sendout);
        return acc;
      },
      new Map<number, Sendout[]>()
    );

    const processedData: SendoutViewModel[] = [];

    for (const [storeId, sendouts] of groupedByStore) {
      sendouts.forEach((sendout, index) => {
        const before: number | null =
          index > 0
            ? differenceInDays(
                sendout.sendoutDate,
                sendouts[index - 1].sendoutDate
              )
            : null;
        const after: number | null =
          index < sendouts.length - 1
            ? differenceInDays(
                sendouts[index + 1].sendoutDate,
                sendout.sendoutDate
              )
            : null;

        processedData.push({
          ...sendout,
          before,
          after,
        });
      });
    }
    processedData.sort(
      (a, b) => a.sendoutDate?.getTime() - b.sendoutDate?.getTime()
    );
    // const processedData = data.map((sendout, index) => {
    //   const before =
    //     index > 0
    //       ? differenceInDays(sendout.sendoutDate, data[index - 1].sendoutDate)
    //       : 0;
    //   const after =
    //     index < data.length - 1
    //       ? differenceInDays(data[index + 1].sendoutDate, sendout.sendoutDate)
    //       : 0;

    //   return {
    //     ...sendout,
    //     before,
    //     after,
    //   };
    // });

    return processedData;
  }

  getAllSendouts = () => {
    this.isLoading = true;

    this.sendoutService.getAllSendouts().subscribe({
      next: (data) => {
        this.allSendouts = this.processSendouts(data);
        this.sendouts = [...this.allSendouts];

        this.allSendoutsAccordion =
          this.sharedFunctionalityService.getGroupedDataSet(
            this.allSendouts,
            [
              {
                id: SendoutStatus.Draft,
                label: 'Kladder',
              },
              {
                id: SendoutStatus.AwaitingApproval,
                label: 'Afventer godkendelse',
              },
              {
                id: SendoutStatus.Approved,
                label: 'Godkendt',
              },
              {
                id: SendoutStatus.Rejected,
                label: 'Afvist',
              },
              {
                id: SendoutStatus.Executing,
                label: 'Under udsendelse',
              },
              {
                id: SendoutStatus.Completed,
                label: 'Afsluttet',
              },
            ],
            (e) => e.status
          );

        this.sendoutsAccordion = { ...this.allSendoutsAccordion };

        this.removeKladderCategory();
      },
      error: (error) => {
        console.error('Error fetching templates', error);
        this.snackbarService.show('Udsendelser kunne ikke hentes');
      },
      complete: () => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      },
    });
  };

  onCategoryFilterChange() {
    this.sendoutsAccordion = this.sharedFunctionalityService.onFilterChange(
      this.statusFilters,
      this.allSendoutsAccordion,
      'accordionHeading'
    );
  }
}
