import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MainHeadingSubtextComponent } from '../../atoms/main-heading-subtext/main-heading-subtext.component';
import { FilterComponent } from '../../molecules/filter/filter.component';
import { SearchFieldComponent } from '../../atoms/search-field/search-field.component';
import { FilledButtonComponent } from '../../atoms/filled-button/filled-button.component';
import { StrokedButtonComponent } from '../../atoms/stroked-button/stroked-button.component';
import { EventType, Filter, Template, UpdateSendout } from '../../../types';
import { GlobalDataService } from '../../../services/global-data/global-data.service';
import { SendoutService } from '../../../services/sendout/sendout.service';
import { EventsService } from '../../../services/events/events.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { GroupedDataSet, Event } from '../../../types';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '../../../services/dialog/dialog.service';
import { CardComponent } from '../../molecules/card/card.component';
import { DynamicAccordionComponent } from '../../molecules/dynamic-accordion/dynamic-accordion.component';
import { CardsGroupComponent } from '../../molecules/cards-group/cards-group.component';
import { eventFilter } from '../../../constants/filters.constants';
import { SharedFunctionalityService } from '../../../services/shared-functionality/shared-functionality.service';
import { firstValueFrom } from 'rxjs';
import { TemplateService } from '../../../services/template/template.service';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { formatISO, isAfter, isSameDay } from 'date-fns';
import { FormsModule } from '@angular/forms';
import {
  MatSlideToggle,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { GenericCardComponent } from '../../molecules/generic-card/generic-card.component';
import { DateFnsModule } from 'ngx-date-fns';
import { EventTypePipe } from '../../../pipes/EventType.pipe';

enum SelectEventMode {
  Create = 'CREATE',
  Edit = 'EDIT',
}

@Component({
  selector: 'app-select-event',
  standalone: true,
  imports: [
    MainHeadingSubtextComponent,
    FilterComponent,
    SearchFieldComponent,
    FilledButtonComponent,
    StrokedButtonComponent,
    CardComponent,
    DynamicAccordionComponent,
    CardsGroupComponent,
    AsyncPipe,
    JsonPipe,
    FormsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    GenericCardComponent,
    DateFnsModule,
    EventTypePipe,
  ],
  templateUrl: './select-event.component.html',
  styleUrl: './select-event.component.css',
})
export class SelectEventComponent implements OnInit {
  SelectEventMode = SelectEventMode;
  EventType = EventType;
  mode: SelectEventMode = SelectEventMode.Create;

  sessionData$ = this.globalDataService.getSessionData();

  template: Template | null = null;
  sendoutId?: number;
  isLoading: boolean = false;
  slideToggleState: boolean = true;

  isEventRequired: boolean = true;
  buttonText: string = 'Vælg';
  emptyStateText: string = 'Ingen events';

  events: Event[] = [];
  allEvents: Event[] = [];

  eventsAccordion: GroupedDataSet<Event> = null!;
  allEventsAccordion: GroupedDataSet<Event> = null!;

  selectEventFilter: Filter[] = eventFilter;

  constructor(
    private sendoutService: SendoutService,
    private templateService: TemplateService,
    private globalDataService: GlobalDataService,
    private eventsService: EventsService,
    private snackbarService: SnackbarService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private dialogService: DialogService,
    private sharedFunctionalityService: SharedFunctionalityService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(async (url) => {
      let templateId: number;
      if (this.activatedRoute.snapshot.params['sendoutId']) {
        this.mode = SelectEventMode.Edit;
        const sendoutId = parseInt(
          this.activatedRoute.snapshot.params['sendoutId'],
          10
        );
        this.sendoutId = sendoutId;
        // Load sendout
        const sendout = await firstValueFrom(
          this.sendoutService.getSendoutById(sendoutId)
        );
        templateId = sendout.sendoutTemplateId;
      } else if (this.activatedRoute.snapshot.queryParams['templateId']) {
        this.mode = SelectEventMode.Create;
        templateId = this.activatedRoute.snapshot.queryParams['templateId'];
      } else {
        throw Error('No templateId or sendoutId provided');
      }
      this.template = await firstValueFrom(
        this.templateService.getTemplateById(templateId)
      );
      const sessionData = this.sessionData$.value;
      if (sessionData && sessionData.store !== null) {
        this.getAllEvents(sessionData.store.id);
      } else {
        this.snackbarService.show('Butikken kunne ikke findes');
      }

      this.changeDetectorRef.detectChanges();
    });
  }
  getAllEvents = (storeId: number) => {
    this.isLoading = true;
    this.eventsService.getAllEventsByStore(storeId).subscribe({
      next: (data) => {
        const currentDate = new Date();

        this.allEvents = data.filter((event: Event) => {
          return (
            isSameDay(event.startsAt, currentDate) ||
            isAfter(event.startsAt, currentDate)
          );
        });
        this.allEvents.sort((a, b) => a.name.localeCompare(b.name, 'da-DK'));

        this.events = [...this.allEvents];

        this.allEventsAccordion =
          this.sharedFunctionalityService.getGroupedDataSet<Event>(
            this.allEvents,
            [
              {
                id: EventType.Event,
                label: 'Events',
              },
              {
                id: EventType.PersonalAppointment,
                label: 'Personlige aftaler',
              },
            ],
            (e) => e.type
          );

        this.eventsAccordion = { ...this.allEventsAccordion };
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
      },
      complete: () => {
        this.changeDetectorRef.detectChanges();
        this.isLoading = false;
      },
    });
  };

  async addEventUpdateSendout(eventData: any): Promise<void> {
    const eventId: string = eventData.id;
    if (this.mode === SelectEventMode.Create) {
      this.router.navigate(['sendout', 'new'], {
        queryParams: {
          templateId: this.template?.id,
          eventId: eventId,
          sendoutDate:
            this.activatedRoute.snapshot.queryParams['sendoutDate'] || null,
        },
      });
    } else {
      // Update sendout
      if (this.sendoutId) {
        const sendout = await firstValueFrom(
          this.sendoutService.getSendoutById(this.sendoutId)
        );
        const dto: UpdateSendout = {
          id: sendout.id,
          sendoutTemplateId: sendout.sendoutTemplateId,
          sendoutDate: formatISO(sendout.sendoutDate, {
            representation: 'date',
          }),
          storeId: sendout.storeId,
          updatedBy: this.sessionData$.value?.userId || 0,
          // updatedAt: new Date(),
          reviewedBy: sendout.reviewedBy,
          reviewedAt: sendout.reviewedAt ? formatISO(sendout.reviewedAt) : null,
          sendoutName: sendout.sendoutName,
          status: sendout.status,
          eventId: eventId,
          contentSubjectLine: sendout.contentSubjectLine,
          contentPreheader: sendout.contentPreheader,
          contentHeadline: sendout.contentHeadline,
          contentBodyCopy: sendout.contentBodyCopy,
          contentCtaText: sendout.contentCtaText,
          contentCtaUrl: sendout.contentCtaUrl,
          contentImageUrl: sendout.contentImageUrl,
          contentDisclaimer: sendout.contentDisclaimer,
          contactPerson: sendout.contactPerson || '',
        };
        const response = await firstValueFrom(
          this.sendoutService.updateSendout(dto)
        );

        this.router.navigate(['sendout', this.sendoutId]);
      }
    }
  }

  onCategoryFilterChange() {
    this.eventsAccordion = this.sharedFunctionalityService.onFilterChange(
      this.selectEventFilter,
      this.allEventsAccordion,
      'accordionHeading'
    );
  }

  springOverClick = () => {
    this.router.navigate(['sendout', 'new'], {
      queryParams: {
        templateId: this.template?.id,
        sendoutDate:
          this.activatedRoute.snapshot.queryParams['sendoutDate'] || null,
      },
    });
  };

  onSlideToggleChange() {
    this.slideToggleState = this.sharedFunctionalityService.onSlideToggleChange(
      this.slideToggleState
    );
  }

  applySearchFilter(filterValue: string) {
    const { filteredItems, slideToggleState } =
      this.sharedFunctionalityService.applySearchFilter(
        filterValue,
        this.allEvents,
        ['name'],
        this.changeDetectorRef,
        this.slideToggleState
      );
    this.events = filteredItems;
    this.slideToggleState = slideToggleState;
  }

  async saveWithoutEvent() {
    if (this.sendoutId) {
      const sendout = await firstValueFrom(
        this.sendoutService.getSendoutById(this.sendoutId)
      );
      const dto: UpdateSendout = {
        id: sendout.id,
        sendoutTemplateId: sendout.sendoutTemplateId,
        sendoutDate: formatISO(sendout.sendoutDate, {
          representation: 'date',
        }),
        storeId: sendout.storeId,
        updatedBy: this.sessionData$.value?.userId || 0,
        // updatedAt: new Date(),
        reviewedBy: sendout.reviewedBy,
        reviewedAt: sendout.reviewedAt ? formatISO(sendout.reviewedAt) : null,
        sendoutName: sendout.sendoutName,
        status: sendout.status,
        eventId: '',
        contentSubjectLine: sendout.contentSubjectLine,
        contentPreheader: sendout.contentPreheader,
        contentHeadline: sendout.contentHeadline,
        contentBodyCopy: sendout.contentBodyCopy,
        contentCtaText: sendout.contentCtaText,
        contentCtaUrl: sendout.contentCtaUrl,
        contentImageUrl: sendout.contentImageUrl,
        contentDisclaimer: sendout.contentDisclaimer,
        contactPerson: sendout.contactPerson || '',
      };
      const response = await firstValueFrom(
        this.sendoutService.updateSendout(dto)
      );

      this.router.navigate(['sendout', this.sendoutId]);
    }
  }
}
