<div class="custom-pt custom-container">
  @if (isLoading || !this.sendout || !this.template) {
  <app-skeleton></app-skeleton>
  } @else {
  @if (this.sendout.status == SendoutStatus.Draft || this.sendoutState == SendoutState.New) {
  <app-main-heading-subtext headingText="Redigér udsendelse" subText="På denne side kan du redigere alle elementer af din udsendelse, så den kommer til at se ud, som du gerne vil have det. Billede, overskrift, brødtekst og meget mere. Når du laver ændringer vil du se det i visningen på højre hånd." extraClasses="activity-margin"></app-main-heading-subtext>
  } @else {
  <app-main-heading-subtext headingText="Udsendelse" extraClasses="activity-margin"></app-main-heading-subtext>
  }

  <div class="form-container">
    <form [formGroup]="sendoutForm">
      <app-main-heading extraClasses="create-template" headingText="Generelt"></app-main-heading>

      <!-- general data fields -->
      <div class="general-fields-container d-flex">
        <div class="w-md-50" style="padding-right: 2em;">

          <app-date-field labelClass="create-template-label" label="Udsendelsesdato" placeholder="Udsendelsesdato" [formGroup]="sendoutForm" controlName="sendoutDate" [minDate]="minSendoutDate" [overrideLocks]="sessionData && sessionData.userType == UserType.ActiveDirectory && sessionData.storeImpersonation"></app-date-field>
          <app-form-field labelClass="create-template-label" label="Kontaktperson i butik" placeholder="Kontaktperson i butik" [icon]="false" [formGroup]="sendoutForm" controlName="contactPerson" [enablePasteButton]="true"></app-form-field>

        </div>

        <div class="w-md-50" style="padding-right: 2em;">
          <app-form-field labelClass="create-template-label" label="Event eller personlig aftale" placeholder="Intet event valgt" [icon]="true" iconLocation="../../../../assets/edit-event.svg" iconClass="top" className="login-form-field-width" [formGroup]="sendoutForm" controlName="eventName" iconTooltip="Tilføj event" (iconEvent)="editEventIconClick()"></app-form-field>
          <app-form-field labelClass="create-template-label" label="Udsendelsesnavn" placeholder="Udsendelsesnavn" className="login-form-field-width" [icon]="false" [formGroup]="sendoutForm" controlName="sendoutName" [enablePasteButton]="true"></app-form-field>
        </div>
      </div>

      <app-main-heading extraClasses="create-template" headingText="Udsendelse"></app-main-heading>

      <!-- email fields -->
      <div class="d-flex flex-column-reverse flex-md-row gap-4 gap-md-0 email-details-container">
        <div class="w-md-50" style="padding-right: 2em;">
          <app-form-field labelClass="edit-sendout-label" label="Emnelinje" placeholder="Emnelinje" [icon]="false" [formGroup]="sendoutForm" controlName="contentSubjectLine" [enablePasteButton]="true"></app-form-field>

          <app-form-field labelClass="edit-sendout-label" label="Preheader" placeholder="Preheader" [icon]="false" [formGroup]="sendoutForm" controlName="contentPreheader" [enablePasteButton]="true"></app-form-field>


          <app-img-grid [formGroup]="sendoutForm" controlName="contentImageUrl" [templateImages]="template.availableImages"></app-img-grid>


          <app-form-field labelClass="edit-sendout-label" label="Overskrift" placeholder="Overskrift" [icon]="false" [formGroup]="sendoutForm" controlName="contentHeadline" [enablePasteButton]="true"></app-form-field>

          <app-text-editor label="Brødtekst" placeholder="Brødtekst" [MAX_LENGTH]="1000" [showLimit]="true" labelClass="labels text-light-blue" editorClass="custom-editor-class paste-editor w-100" mainClass="position-relative d-flex flex-column editor-container" iconClass="icon-default position-absolute" [formGroup]="sendoutForm" controlName="contentBodyCopy" [enablePasteButton]="true"></app-text-editor>

          <app-form-field labelClass="edit-sendout-label" label="Link" placeholder="Link" [icon]="false" [formGroup]="sendoutForm" controlName="contentCtaUrl" [enablePasteButton]="true"></app-form-field>

          <app-form-field labelClass="edit-sendout-label" label="CTA tekst" placeholder="CTA tekst" [icon]="false" [formGroup]="sendoutForm" controlName="contentCtaText" [enablePasteButton]="true"></app-form-field>

          <app-text-editor label="Fodnote" placeholder="Fodnote" [formGroup]="sendoutForm" controlName="contentDisclaimer" [enablePasteButton]="true" [MAX_LENGTH]="1000" labelClass="labels text-light-blue" editorClass="default-editor-class paste-editor w-100" mainClass="position-relative d-flex flex-column editor-container" iconClass="icon-49-height position-absolute" [formGroup]="sendoutForm"></app-text-editor>
        </div>

        <div class="w-md-50 email-container">
          <app-email [templateName]="template.templateName" [subjectLine]="sendoutForm.get('contentSubjectLine')?.value" [preheader]="sendoutForm.get('contentPreheader')?.value" [headline]="sendoutForm.get('contentHeadline')?.value" [bodyCopy]="sendoutForm.get('contentBodyCopy')?.value" [ctaUrl]="this.sendoutForm.get('contentCtaUrl')?.value == '{{event URL}}' ? (this.selectedEvent != null ? this.selectedEvent.link : '' ): this.sendoutForm.get('contentCtaUrl')?.value" [ctaText]="sendoutForm.get('contentCtaText')?.value" [disclaimer]="sendoutForm.get('contentDisclaimer')?.value" [contentImageUrl]="sendoutForm.get('contentImageUrl')?.value" [points]="previewPoints" [storeStreetName]="previewStoreAddress" [storeCity]="previewStoreCity"></app-email>
        </div>
      </div>

      <!-- email fields ends -->
    </form>

    <form [formGroup]="emailForm" (ngSubmit)="sendProofEmail()">
      <div class="d-flex flex-column-reverse flex-md-row gap-4 gap-md-0 email-details-container">
        <div class="w-md-50" style="padding-right: 2em;">
          <app-main-heading extraClasses="create-template mt-25" headingText="Test"></app-main-heading>
          <app-form-field labelClass="edit-sendout-label" label="Email adresse" placeholder="Email adresse" [icon]="false" [sendBtn]="true" [formGroup]="emailForm" controlName="email" (childEvent)="sendProofEmail()"></app-form-field>
        </div>
        <div class="w-md-50" style="padding-right: 49px;"></div>
      </div>

    </form>
  </div>

  <!-- buttons -->
  <div class="d-flex flex-column flex-md-row align-items-center justify-content-between margin-bottom margin-btn gap-3">
    <div class="d-flex gap-3 flex-column flex-md-row width-100 button-container">

      @if (sendoutState == SendoutState.Existing && (sessionData.userType == UserType.AgillicAdvisor || sessionData.storeImpersonation) && sendout.status != SendoutStatus.Executing && sendout.status != SendoutStatus.Completed) {
      <app-stroked-button buttonText="Slet udsendelse" className="full-width-btn edit-email-sendout" (navLinkClicked)="sletUdsendelseClick()"></app-stroked-button>
      } @else if (sendoutState == SendoutState.Existing && (sessionData.userType == UserType.ActiveDirectory) && sendout.status != SendoutStatus.Executing && sendout.status != SendoutStatus.Completed) {
      <app-stroked-button buttonText="Slet udsendelse" className="full-width-btn edit-email-sendout" (navLinkClicked)="sletUdsendelseClick()"></app-stroked-button>
      }
      @if (sendoutState == SendoutState.New) {
      <filled-button buttonText="Tilbage" className="back-btn width" (childEvent)="goToSelectEventPage()"></filled-button>
      }
    </div>

    <div class="d-flex gap-3 flex-column flex-md-row width-100 button-container">
      @if (sessionData.userType == UserType.AgillicAdvisor || sessionData.storeImpersonation) {
      @if (sendout.status == SendoutStatus.Draft) {
      <app-stroked-button buttonText="Gem" className="full-width-btn edit-email-sendout" [disabled]="!sendoutForm.dirty" (navLinkClicked)="onSendoutFormSubmit(SendoutStatus.Draft)"></app-stroked-button>
      <filled-button buttonText="Send til godkendelse" className="approval-btn margin width" [disabled]="!sendoutForm.valid" (childEvent)="onSendoutFormSubmit(SendoutStatus.AwaitingApproval, sendoutForm.dirty)"></filled-button>
      } @else if (sendoutState == SendoutState.New) {
      <app-stroked-button buttonText="Gem" className="full-width-btn edit-email-sendout" [disabled]="!sendoutForm.dirty" (navLinkClicked)="onSendoutFormSubmit(SendoutStatus.Draft)"></app-stroked-button>
      <filled-button buttonText="Send til godkendelse" className="approval-btn margin width" [disabled]="!sendoutForm.valid" (childEvent)="onSendoutFormSubmit(SendoutStatus.AwaitingApproval, sendoutForm.dirty)"></filled-button>
      }
      @if (sendout.status == SendoutStatus.Approved || sendout.status == SendoutStatus.Rejected || sendout.status == SendoutStatus.AwaitingApproval) {
      <app-stroked-button buttonText="Rediger" className="full-width-btn edit-email-sendout" (navLinkClicked)="onSendoutFormSubmit(SendoutStatus.Draft)"></app-stroked-button>
      }

      }
      @else {
      @if (sendout.status == SendoutStatus.AwaitingApproval || sendout.status == SendoutStatus.Approved || sendout.status == SendoutStatus.Rejected) {
      <filled-button buttonText="Afvis" className="create-template width" [disabled]="sendout.status == SendoutStatus.Rejected" (childEvent)="onSendoutFormSubmit(SendoutStatus.Rejected)"></filled-button>
      <filled-button buttonText="Godkend" className="approval-btn margin width" [disabled]="sendout.status == SendoutStatus.Approved" (childEvent)="onSendoutFormSubmit(SendoutStatus.Approved)"></filled-button>
      }
      }


    </div>
  </div>
  }
</div>
