import { Pipe, PipeTransform } from '@angular/core';
import { EventType, EventTypeEnum } from '../types';

@Pipe({
  name: 'eventType',
  standalone: true,
})
export class EventTypePipe implements PipeTransform {
  transform(value: EventTypeEnum, mode: 'label' = 'label'): string {
    if (mode === 'label') {
      return this.getLabel(value);
    }
    return String(value);
  }

  private getLabel(value: EventTypeEnum): string {
    switch (value) {
      case EventType.Event:
        return 'Event';
        break;
      case EventType.PersonalAppointment:
        return 'Personlig aftale';
        break;
      default:
        return '';
        break;
    }
  }
}
