<div style="display: flex; flex-direction: column;align-items: center; justify-content: center;height: 100%;gap: 20px;">
  <app-main-heading headingText="Siden kunne ikke findes"></app-main-heading>
  @if (sessionData$|async; as sessionData) {
  @if (sessionData.initialized && sessionData.userType == UserType.ActiveDirectory) {
  <app-stroked-button buttonText="Gå til oversigt" (navLinkClicked)="goToSendouts()"></app-stroked-button>
  } @else if (sessionData.initialized && sessionData.userType == UserType.AgillicAdvisor) {
  <app-stroked-button buttonText="Gå til oversigt" (navLinkClicked)="goToOverview()"></app-stroked-button>
  } @else {
  <app-stroked-button buttonText="Gå til login" (navLinkClicked)="goToLogin()"></app-stroked-button>
  }
  } @else {
  <app-stroked-button buttonText="Gå til login" (navLinkClicked)="goToLogin()"></app-stroked-button>
  }
</div>
