<div class="custom-pt custom-container" [ngClass]="storeImpersonation === true ? 'impersonation-height' : ''">
  @defer (when isLoading) {
  <app-main-heading-subtext headingText="Skabeloner" subText="Denne side giver dig overblik over alle de skabeloner som er tilgængelige for butikkerne. Det er samtidig he,  at du kan tilføje nye eller opdatere og slette eksisterende skabeloner." extraClasses="activity-margin"></app-main-heading-subtext>
  <div class="d-flex gap-4 flex-column flex-md-row position-relative mb-5">
    <app-search-field placeholder="Søg skabelon" (valueChange)="applySearchFilter($event)"></app-search-field>

    <div class="d-flex gap-4 positioning">
      <img src="../../../../assets/plus_icon.svg" alt="plus_icon" class="plus-icon" (click)="handleCreateTemplateClick()" />
      <span class="search-field-span-text">Opret skabelon</span>
    </div>
    <div style="flex: 1 1 auto;"></div>
    <div class="custom-margin">
      <mat-slide-toggle [(ngModel)]="slideToggleState" labelPosition="before">Gruppér</mat-slide-toggle>
    </div>
  </div>



  @if (isLoading) {
  <p>loading ...</p>
  }
  @else {
  @if (slideToggleState) {
  <app-dynamic-accordion [columns]="columns" [dataset]="templatesAccordion" (clickEvent)="handleRowClick($event)" [emptyStateText]="emptyStateText"></app-dynamic-accordion>
  } @else {
  <app-dynamic-table [columns]="columns" [data]="templates" [emptyStateText]="emptyStateText" (clickEvent)="handleRowClick($event)">
  </app-dynamic-table>
  }
  }
  }
  @placeholder(minimum 1s) {
  <app-skeleton></app-skeleton>
  }
</div>
