import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MainHeadingSubtextComponent } from '../../atoms/main-heading-subtext/main-heading-subtext.component';
import { MainHeadingComponent } from '../../atoms/main-heading/main-heading.component';
import { FormFieldComponent } from '../../atoms/form-field/form-field.component';
import { TextAreaComponent } from '../../atoms/text-area/text-area.component';
import { FilledButtonComponent } from '../../atoms/filled-button/filled-button.component';
import { StrokedButtonComponent } from '../../atoms/stroked-button/stroked-button.component';
import { DateFieldComponent } from '../../atoms/date-field/date-field.component';
import { SimpleTextComponent } from '../../atoms/simple-text/simple-text.component';
import { ImgGridComponent } from '../../molecules/img-grid/img-grid.component';
import { EmailComponent } from '../../molecules/email/email.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import {
  SendoutDateLock,
  SendoutService,
} from '../../../services/sendout/sendout.service';
import {
  GlobalDataService,
  SessionData,
} from '../../../services/global-data/global-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import {
  CreateSendout,
  Options,
  Sendout,
  SendoutStatus,
  SendoutStatusEnum,
  Store,
  Template,
  UpdateSendout,
  UserType,
} from '../../../types';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TemplateService } from '../../../services/template/template.service';
import { DialogService } from '../../../services/dialog/dialog.service';
import { SelectFieldComponent } from '../../atoms/select-field/select-field.component';
import {
  MailPreviewData,
  MailPreviewService,
} from '../../../services/mail-preview/mail-preview.service';
import { ctaUrlValidator } from '../../../validation/cta-url.validator';
import { TextEditorComponent } from '../../atoms/text-editor/text-editor.component';
import { firstValueFrom, Subscription } from 'rxjs';
import { Event } from '../../../types';
import { EventsService } from '../../../services/events/events.service';
import {
  addDays,
  format,
  formatISO,
  isBefore,
  isSunday,
  parseISO,
  startOfDay,
} from 'date-fns';
import { SkeletonComponent } from '../../molecules/skeleton/skeleton.component';
import { StoreService } from '../../../services/store/store.service';
enum SendoutState {
  New = 'NEW',
  Existing = 'EXISTING',
}

@Component({
  selector: 'app-edit-sendout',
  standalone: true,
  imports: [
    MainHeadingSubtextComponent,
    MainHeadingComponent,
    FormFieldComponent,
    TextAreaComponent,
    FilledButtonComponent,
    StrokedButtonComponent,
    DateFieldComponent,
    SimpleTextComponent,
    ImgGridComponent,
    EmailComponent,
    ReactiveFormsModule,
    SelectFieldComponent,
    TextEditorComponent,
    SkeletonComponent,
  ],
  templateUrl: './edit-sendout.component.html',
  styleUrl: './edit-sendout.component.css',
})
export class EditSendoutComponent implements OnInit, OnDestroy {
  // export enums for views
  UserType = UserType;
  SendoutState = SendoutState;
  SendoutStatus = SendoutStatus;

  sessionDataSubscription?: Subscription;
  sessionData!: SessionData;

  sendoutState: SendoutState = SendoutState.New;

  sendoutForm: FormGroup;
  emailForm: FormGroup;

  sendout!: Sendout;
  template: Template | null = null;

  minSendoutDate: Date;
  isLoading: boolean = true;

  statusOptions: Options[] = [
    { value: 'afventer', viewValue: 'Afventer' },
    { value: 'godkendt', viewValue: 'Godkendt' },
    { value: 'udsendt', viewValue: 'Udsendt' },
  ];
  events: Event[] = [];
  selectedEvent: Event | null = null;

  sendoutDateLocks: SendoutDateLock[] = [];

  store: Store | null = null;
  previewStoreAddress: string = 'Vejnavn 1';
  previewStoreCity: string = 'By';
  previewPoints: string = '1.234';

  constructor(
    private sendoutService: SendoutService,
    private globalDataService: GlobalDataService,
    private router: Router,
    private snackbarService: SnackbarService,
    private templateService: TemplateService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private mailPreviewService: MailPreviewService,
    private activatedRoute: ActivatedRoute,
    private eventService: EventsService,
    private storeService: StoreService
  ) {
    this.minSendoutDate = addDays(startOfDay(new Date()), 3);
    if (isSunday(this.minSendoutDate)) {
      this.minSendoutDate = addDays(this.minSendoutDate, 1);
    }

    this.sendoutForm = new FormGroup({}); // Actual form initialization needs to happen after the template has been loaded

    this.emailForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required]),
    });
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.sessionDataSubscription = this.globalDataService
      .getSessionData()
      .subscribe(async (sessionData) => {
        this.sessionData = sessionData;
        if (sessionData.store) {
          this.events = await firstValueFrom(
            this.eventService.getAllEventsByStore(sessionData.store.id)
          );

          this.sendoutDateLocks =
            await this.sendoutService.getUpcomingSendoutDateLocks();
        }
      });
    this.activatedRoute.url.subscribe(async (url) => {
      if (this.activatedRoute.snapshot.params['sendoutId']) {
        this.sendoutState = SendoutState.Existing;
        const sendoutId = parseInt(
          this.activatedRoute.snapshot.params['sendoutId'],
          10
        );
        await this.loadDataForSendoutId(sendoutId);
      } else {
        this.sendoutState = SendoutState.New;
        const templateId =
          this.activatedRoute.snapshot.queryParams['templateId'];
        const eventId =
          this.activatedRoute.snapshot.queryParams['eventId'] || null;

        this.template = await firstValueFrom(
          this.templateService.getTemplateById(templateId)
        );
        this.initializeSendoutForm();
        let sendoutDate = this.activatedRoute.snapshot.queryParams[
          'sendoutDate'
        ]
          ? parseISO(this.activatedRoute.snapshot.queryParams['sendoutDate'])
          : this.minSendoutDate;

        if (this.sessionData.store) {
          this.store = this.sessionData.store;
        }

        if (this.events.length === 0 && this.sessionData.store) {
          this.events = await firstValueFrom(
            this.eventService.getAllEventsByStore(this.sessionData.store.id)
          );
        }
        if (eventId) {
          this.selectedEvent =
            this.events.find((event) => event.id === eventId) || null;
        }

        this.sendout = this.templateService.createSendoutFromTemplate(
          sendoutDate,
          this.template,
          this.selectedEvent
        );

        if (this.sessionData.store) {
          this.sendout.storeId = this.sessionData.store.id;
        } else {
          throw new Error("Couldn't find store in session data");
        }

        this.setFormValues({ ...this.sendout });
        this.sendoutForm.enable();
        this.sendoutForm.markAllAsTouched();
        this.sendoutForm.markAsDirty();
        this.sendoutForm.updateValueAndValidity();
        this.setPreviewData();
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      }
    });
  }
  ngOnDestroy(): void {
    this.sessionDataSubscription?.unsubscribe();
  }
  initializeSendoutForm() {
    if (!this.template) {
      throw new Error('Template is not loaded');
    }
    this.sendoutForm = new FormGroup({
      sendoutDate: new FormControl('', [Validators.required]),
      contactPerson: new FormControl('', []),
      sendoutName: new FormControl('', [Validators.required]),
      eventName: new FormControl(
        '',
        this.template.eventRequired === true ? [Validators.required] : null
      ),

      contentSubjectLine: new FormControl(
        {
          value: '',
          disabled: this.template.allowOverrideSubjectLine ? false : true,
        },
        [Validators.required]
      ),
      contentPreheader: new FormControl(
        {
          value: '',
          disabled: this.template.allowOverridePreheader ? false : true,
        },
        [Validators.required]
      ),
      contentHeadline: new FormControl(
        {
          value: '',
          disabled: this.template.allowOverrideHeadline ? false : true,
        },
        [Validators.required, Validators.minLength(3)]
      ),
      contentBodyCopy: new FormControl(
        {
          value: '',
          disabled: this.template.allowOverrideBodyCopy ? false : true,
        },
        [Validators.required]
      ),
      contentCtaUrl: new FormControl(
        {
          value: '',
          disabled: this.template.allowOverrideCtaUrl ? false : true,
        },
        [ctaUrlValidator]
      ),
      contentImageUrl: new FormControl('', [Validators.required]),
      contentCtaText: new FormControl({
        value: '',
        disabled: this.template.allowOverrideCtaText ? false : true,
      }),
      contentDisclaimer: new FormControl(
        {
          value: '',
          disabled: this.template.allowOverrideDisclaimer ? false : true,
        },
        []
      ),
    });

    this.sendoutForm.valueChanges.subscribe((values) => {
      this.changeDetectorRef.detectChanges();
    });

    this.sendoutForm.get('eventName')?.valueChanges.subscribe((eventName) => {
      const sendoutDateControl = this.sendoutForm.get('sendoutDate');

      if (eventName && this.selectedEvent?.type === 'Event') {
        sendoutDateControl?.setValidators([
          Validators.required,
          ...(this.selectedEvent
            ? [this.dateBeforeValidator(this.selectedEvent.startsAt)]
            : []),
        ]);
      } else if (eventName && this.selectedEvent?.type !== 'Event') {
        sendoutDateControl?.setValidators([
          Validators.required,
          ...(this.selectedEvent ? [this.dateBeforeNoEventValidator()] : []),
        ]);
      } else {
        sendoutDateControl?.clearValidators();
      }

      sendoutDateControl?.updateValueAndValidity();
    });
  }

  async loadDataForSendoutId(id: number) {
    this.isLoading = true;
    this.changeDetectorRef.detectChanges();
    // Load template, sendout and event data
    this.sendout = await firstValueFrom(this.sendoutService.getSendoutById(id));
    if (!this.sendout) {
      throw new Error(`Sendout with id ${id} not found`);
    }
    this.template = await firstValueFrom(
      this.templateService.getTemplateById(this.sendout.sendoutTemplateId)
    );
    if (this.sendout.eventId && this.events.length === 0) {
      const storeId = this.sessionData.store?.id || this.sendout.storeId;
      this.events = await firstValueFrom(
        this.eventService.getAllEventsByStore(storeId)
      );
    }
    if (this.sendout.eventId) {
      this.selectedEvent =
        this.events.find((e) => e.id === this.sendout.eventId) || null;
    }

    this.store = await firstValueFrom(
      this.storeService.getStoreById(this.sendout.storeId)
    );

    this.initializeSendoutForm();
    this.setFormValues({ ...this.sendout });
    this.sendoutForm.markAsPristine();
    this.sendoutForm.enable();

    if (
      this.sessionData.userType === UserType.ActiveDirectory &&
      this.sessionData.storeImpersonation === false
    ) {
      // Sendouts are always read-only for admin users not in store impersonation-mode
      this.sendoutForm.disable();
    } else if (this.sendout.status !== SendoutStatus.Draft) {
      // Non-draft sendouts are read-only
      this.sendoutForm.disable();
    }
    this.setPreviewData();
    this.isLoading = false;
    this.changeDetectorRef.detectChanges();
  }

  setPreviewData() {
    const intFormat = new Intl.NumberFormat('da-DK', {
      style: 'decimal',
      maximumFractionDigits: 0,
    });
    this.previewPoints = intFormat.format(parseInt(format(new Date(), 'Hmm')));

    if (this.store) {
      const [city, address] = this.store.place.split(' - ', 2);
      this.previewStoreAddress = address;
      this.previewStoreCity = city;
    }
  }

  dateBeforeValidator(referenceDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; // If the control is empty, let other validators handle it (like required validator)
      }

      const inputDate = new Date(control.value);

      // Allow dates before the startDate
      if (isBefore(inputDate, referenceDate)) {
        return null; // Date is valid
      }
      return { dateBefore: true }; // Date is not valid
    };
  }
  dateBeforeNoEventValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const inputDate = new Date(control.value);
      const currDate = startOfDay(new Date());

      if (inputDate > currDate) {
        return null;
      }
      return { dateBefore: true };
    };
  }

  /**
   *
   * @param newStatus
   * @param forceSave  - if true, the form will be saved even if we're not saving a draft
   * @returns
   */
  onSendoutFormSubmit(
    newStatus: SendoutStatusEnum,
    forceSave: boolean = false
  ) {
    // Check form validity - invalid forms may be saved to draft
    if (this.sendoutForm.invalid && newStatus !== SendoutStatus.Draft) {
      this.sendoutForm.markAllAsTouched();
      this.sendoutForm.updateValueAndValidity();
      return;
    }

    if (this.sendoutState === SendoutState.New) {
      const payload: CreateSendout = {
        sendoutTemplateId: this.sendout?.sendoutTemplateId,
        storeId: this.sendout?.storeId,
        createdBy: this.sessionData.userId,
        updatedBy: this.sessionData.userId,
        // updatedAt: new Date(),
        sendoutName:
          this.sendoutForm.value.sendoutName === undefined
            ? this.sendout.sendoutName
            : this.sendoutForm.value.sendoutName,
        status: newStatus,
        eventId: this.sendout?.eventId || null,
        reviewedBy: this.sendout?.reviewedBy || 0,

        contentSubjectLine:
          this.sendoutForm.value.contentSubjectLine === undefined
            ? this.sendout.contentSubjectLine
            : this.sendoutForm.value.contentSubjectLine,
        contentPreheader: this.sendout?.contentPreheader,
        contentHeadline:
          this.sendoutForm.value.contentHeadline === undefined
            ? this.sendout.contentHeadline
            : this.sendoutForm.value.contentHeadline,
        contentBodyCopy:
          this.sendoutForm.value.contentBodyCopy === undefined
            ? this.sendout.contentBodyCopy
            : this.sendoutForm.value.contentBodyCopy,
        contentCtaText:
          this.sendoutForm.value.contentCtaText === undefined
            ? this.sendout.contentCtaText
            : this.sendoutForm.value.contentCtaText,
        contentCtaUrl:
          this.sendoutForm.value.contentCtaUrl === undefined
            ? this.sendout.contentCtaUrl
            : this.sendoutForm.value.contentCtaUrl,
        contentImageUrl:
          this.sendoutForm.value.contentImageUrl === undefined
            ? this.sendout.contentImageUrl
            : this.sendoutForm.value.contentImageUrl,
        contentDisclaimer:
          this.sendoutForm.value.contentDisclaimer === undefined
            ? this.sendout.contentDisclaimer
            : this.sendoutForm.value.contentDisclaimer,
        contactPerson:
          (this.sendoutForm.value.contactPerson === undefined
            ? this.sendout.contactPerson
            : this.sendoutForm.value.contactPerson) || '',
        sendoutDate: formatISO(new Date(this.sendoutForm.value.sendoutDate), {
          representation: 'date',
        }),
      };
      return this.executeCreateSendout(payload);
    }

    if (newStatus === SendoutStatus.Draft || forceSave) {
      const payload: UpdateSendout = {
        sendoutTemplateId: this.sendout?.sendoutTemplateId,
        storeId: this.sendout?.storeId,
        updatedBy: this.sessionData.userId,
        // updatedAt: new Date(),
        sendoutName:
          this.sendoutForm.value.sendoutName === undefined
            ? this.sendout.sendoutName
            : this.sendoutForm.value.sendoutName,
        status: newStatus,
        eventId: this.sendout?.eventId,
        reviewedBy: this.sendout?.reviewedBy || 0,
        reviewedAt: this.sendout?.reviewedAt
          ? formatISO(this.sendout.reviewedAt)
          : null,
        contentSubjectLine:
          this.sendoutForm.value.contentSubjectLine === undefined
            ? this.sendout.contentSubjectLine
            : this.sendoutForm.value.contentSubjectLine,
        contentPreheader: this.sendout?.contentPreheader,
        contentHeadline:
          this.sendoutForm.value.contentHeadline === undefined
            ? this.sendout.contentHeadline
            : this.sendoutForm.value.contentHeadline,
        contentBodyCopy:
          this.sendoutForm.value.contentBodyCopy === undefined
            ? this.sendout.contentBodyCopy
            : this.sendoutForm.value.contentBodyCopy,
        contentCtaText:
          this.sendoutForm.value.contentCtaText === undefined
            ? this.sendout.contentCtaText
            : this.sendoutForm.value.contentCtaText,
        contentCtaUrl:
          this.sendoutForm.value.contentCtaUrl === undefined
            ? this.sendout.contentCtaUrl
            : this.sendoutForm.value.contentCtaUrl,
        contentImageUrl:
          this.sendoutForm.value.contentImageUrl === undefined
            ? this.sendout.contentImageUrl
            : this.sendoutForm.value.contentImageUrl,
        contentDisclaimer:
          this.sendoutForm.value.contentDisclaimer === undefined
            ? this.sendout.contentDisclaimer
            : this.sendoutForm.value.contentDisclaimer,
        contactPerson:
          (this.sendoutForm.value.contactPerson === undefined
            ? this.sendout.contactPerson
            : this.sendoutForm.value.contactPerson) || '',
        sendoutDate: formatISO(new Date(this.sendoutForm.value.sendoutDate), {
          representation: 'date',
        }),
        id: this.sendout?.id,
      };
      return this.executeUpdateSendout(payload);
    } else {
      return this.updateSendoutStatus(newStatus);
    }
  }

  async sendProofEmail() {
    if (
      this.emailForm.valid &&
      this.sendoutForm.valid &&
      this.sendout &&
      this.template
    ) {
      // Assemble preview data
      const data: MailPreviewData = {
        templateName: this.template.templateName,
        data: {
          CONTENT_BODY_COPY: this.sendoutForm.get('contentBodyCopy')?.value,
          CONTENT_CTA_TEXT: this.sendoutForm.get('contentCtaText')?.value,
          CONTENT_CTA_URL:
            this.sendoutForm.get('contentCtaUrl')?.value == '{{event URL}}'
              ? this.selectedEvent != null
                ? this.selectedEvent.link
                : ''
              : this.sendoutForm.get('contentCtaUrl')?.value,
          CONTENT_DISCLAIMER: this.sendoutForm.get('contentDisclaimer')?.value,
          CONTENT_HEADLINE: this.sendoutForm.get('contentHeadline')?.value,
          CONTENT_IMAGE_URL: this.sendoutForm.get('contentImageUrl')?.value,
          CONTENT_SUBJECT_LINE:
            this.sendoutForm.get('contentSubjectLine')?.value,
          CONTENT_PREHEADER: this.sendoutForm.get('contentPreheader')?.value,
          POINTS: this.previewPoints,
          'BUTIK.STREETNAME': this.previewStoreAddress,
          'BUTIK.CITY': this.previewStoreCity,
        },
      };
      this.emailForm.disable();
      this.mailPreviewService
        .sendMailPreview(this.emailForm.value.email, data)
        .then(() => {
          this.emailForm.enable();
          this.snackbarService.show('Emailen er afsendt', 1200);
        })
        .catch(() => {
          this.emailForm.enable();
          this.snackbarService.show('Emailen blev ikke afsendt', 5000);
        });
    } else if (this.emailForm.invalid) {
      this.emailForm.markAllAsTouched();
      this.emailForm.updateValueAndValidity();
    }
  }

  setFormValues(sendout: Sendout): void {
    this.sendoutForm.patchValue({
      sendoutDate: sendout.sendoutDate,
      sendoutName: sendout.sendoutName,
      contactPerson:
        sendout.contactPerson !== null ? sendout.contactPerson : '',
      contentImageUrl: sendout.contentImageUrl,
      eventName: this.selectedEvent ? this.selectedEvent.name : '',
      contentSubjectLine: sendout.contentSubjectLine,
      contentPreheader: sendout.contentPreheader,
      contentHeadline: sendout.contentHeadline,
      contentBodyCopy: sendout.contentBodyCopy,
      contentCtaUrl: sendout.contentCtaUrl,
      contentCtaText: sendout.contentCtaText,
      contentDisclaimer: sendout.contentDisclaimer,
    });
  }

  async executeCreateSendout(payload: CreateSendout): Promise<void> {
    try {
      const res = await firstValueFrom(
        this.sendoutService.createSendout(payload)
      );
      this.snackbarService.show('Ændringerne er gemt');
      this.router.navigate(['sendout', res.id]);
    } catch (error) {
      console.log('error :', error);
      this.snackbarService.show('Ændringerne kunne ikke gemmes');
      this.changeDetectorRef.detectChanges();
    }
  }

  async executeUpdateSendout(payload: UpdateSendout): Promise<void> {
    try {
      await firstValueFrom(this.sendoutService.updateSendout(payload));
      this.snackbarService.show('Ændringerne er gemt');
      await this.loadDataForSendoutId(payload.id);
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
    } catch (error) {
      console.log('error :', error);
      this.snackbarService.show('Ændringerne kunne ikke gemmes');
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Update the sendout status and possibly reviewedBy/reviewedAt fields
   * @param status
   * @returns
   */
  async updateSendoutStatus(status: SendoutStatusEnum): Promise<void> {
    const payload: UpdateSendout = {
      sendoutTemplateId: this.sendout.sendoutTemplateId,
      storeId: this.sendout.storeId,
      updatedBy: this.sendout.updatedBy,
      reviewedBy:
        status == SendoutStatus.Approved || status == SendoutStatus.Rejected
          ? this.sessionData.userId
          : this.sendout.reviewedBy,
      reviewedAt:
        status == SendoutStatus.Approved || status == SendoutStatus.Rejected
          ? formatISO(new Date())
          : this.sendout.reviewedAt
          ? formatISO(this.sendout.reviewedAt)
          : null,
      sendoutName: this.sendout.sendoutName,
      status: status,
      eventId: this.sendout.eventId,
      contentSubjectLine: this.sendout.contentSubjectLine,
      contentPreheader: this.sendout.contentPreheader,
      contentHeadline: this.sendout.contentHeadline,
      contentBodyCopy: this.sendout.contentBodyCopy,
      contentCtaText: this.sendout.contentCtaText,
      contentCtaUrl: this.sendout.contentCtaUrl,
      contentImageUrl: this.sendout.contentImageUrl,
      contentDisclaimer: this.sendout.contentDisclaimer,
      contactPerson: this.sendout.contactPerson || '',
      sendoutDate: formatISO(this.sendout.sendoutDate, {
        representation: 'date',
      }),
      id: this.sendout.id,
    };
    return this.executeUpdateSendout(payload);
  }

  goToSelectEventPage() {
    const nav = () => {
      this.router.navigate(['sendout', 'create', 'select-event'], {
        queryParams: {
          templateId: this.template?.id,
          eventId: this.selectedEvent?.id,
          sendoutDate: format(this.sendout.sendoutDate, 'yyyy-MM-dd'),
        },
      });
    };

    if (this.sendoutState == SendoutState.New) {
      if (this.sendoutForm.pending) {
        this.dialogService.openDialog(
          'Tilbage',
          'Er du sikker på at du vil forlade siden?',
          'Ja',
          () => {
            nav();
          },
          undefined,
          '347px',
          'content-container',
          '',
          '',
          'dialog-actions'
        );
      } else {
        nav();
      }
    }
  }

  afbrydClick = () => {
    this.router.navigate(['overview']);
  };

  sletUdsendelseClick() {
    this.dialogService.openDialog(
      'Slet udsendelse',
      'Er du sikker på, at du vil slette denne udsendelse?',
      'Slet',
      async () => {
        await this.deleteSendoutById(this.sendout.id).then(() => {
          this.router.navigate(['sendouts']);
        });
      },
      undefined,
      '347px',
      'content-container',
      '',
      '',
      'dialog-actions'
    );
  }

  editEventIconClick = () => {
    this.router.navigate(['sendout', this.sendout.id, 'select-event']);
  };

  deleteSendoutById = (id: number) => {
    return new Promise((resolve, reject) => {
      this.sendoutService.deleteSendoutById(id).subscribe({
        next: (data) => {
          this.snackbarService.show('Udsendelsen er slettet');
          this.changeDetectorRef.detectChanges();
          resolve(data);
        },
        error: (error) => {
          console.log('error :', error);
          this.changeDetectorRef.detectChanges();
          reject(error);
        },
        complete: () => {
          this.changeDetectorRef.detectChanges();
        },
      });
    });
  };
}
