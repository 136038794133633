import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  MatCalendarCellClassFunction,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateAdapter, provideNativeDateAdapter } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { FormGroup, FormControl } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DateFnsModule } from 'ngx-date-fns';
import { CustomDateAdapter } from './custom-date-adapter';
import { MatButtonModule } from '@angular/material/button';
import {
  SendoutDateLock,
  SendoutDateLockReason,
  SendoutService,
} from '../../../services/sendout/sendout.service';
import { isSameDay, isSunday, startOfTomorrow } from 'date-fns';

@Component({
  selector: 'app-date-field',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    ReactiveFormsModule,
    CommonModule,
    DateFnsModule,
  ],
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.css'],
})
export class DateFieldComponent implements OnInit {
  @Input() label: string = '';
  @Input() labelClass: string = '';
  @Input() placeholder: string = '';
  @Input() formGroup!: FormGroup;
  @Input() controlName: string = '';
  @Input() minDate!: Date;
  @Input() overrideLocks: boolean = false;
  private sendoutDateLocks: SendoutDateLock[] = [];

  hqUserMinDate = startOfTomorrow();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dateAdapter: DateAdapter<Date>,
    private sendoutService: SendoutService
  ) {
    this.matIconRegistry.addSvgIcon(
      'calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/calendar.svg'
      )
    );

    this.dateAdapter.setLocale('da');
  }

  async ngOnInit(): Promise<void> {
    this.sendoutDateLocks =
      await this.sendoutService.getUpcomingSendoutDateLocks();
  }

  get control(): FormControl {
    return this.formGroup.get(this.controlName) as FormControl;
  }

  weekendsDatesFilter = (d: Date | null): boolean => {
    if (!d) return false;
    if (this.overrideLocks) {
      return true;
    }

    if (isSunday(d)) return false;
    if (
      this.sendoutDateLocks.some(
        (lock) => isSameDay(d, lock.date) && !lock.softLock
      )
    )
      return false;
    return true;
  };

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    const classNames = [];
    if (view === 'month') {
      const locks = this.sendoutDateLocks.filter((lock) =>
        isSameDay(cellDate, lock.date)
      );
      if (
        locks.some(
          (lock) => lock.reason == SendoutDateLockReason.OtherSendoutOnSameDay
        )
      ) {
        classNames.push('has-sendout');
      }
      if (isSunday(cellDate)) {
        classNames.push('hard-lock');
      } else if (locks.some((lock) => !lock.softLock)) {
        classNames.push('hard-lock');
      } else if (locks.some((lock) => lock.softLock)) {
        classNames.push('soft-lock');
      }
    }
    return classNames.join(' ');
  };
}
