import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilledButtonComponent } from '../../atoms/filled-button/filled-button.component';
import { MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [
    FilledButtonComponent,
    MatDialogContent,
    MatDialogActions,
    CommonModule,
  ],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.css',
})
export class DialogComponent {
  heading: string;
  content: string;
  buttonText: string;
  buttonFunction: (id?: number) => void;
  id?: number;
  dialogContainerClass?: string = '';
  headingClass?: string = '';
  contentClass?: string = '';
  buttonContainerClass?: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.heading = data.heading;
    this.content = data.content;
    this.buttonText = data.buttonText;
    this.buttonFunction = data.buttonFunction;
    this.id = data.id;
    this.dialogContainerClass = data.dialogContainerClass;
    this.headingClass = data.headingClass;
    this.contentClass = data.contentClass;
    this.buttonContainerClass = data.buttonContainerClass;
  }

  onButtonClick(): void {
    this.buttonFunction(this.id);
    this.dialogRef.close();
  }
}
