import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormGroup, FormControl } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-form-field',
  standalone: true,
  imports: [
    MatRadioModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CommonModule,
    MatTooltipModule,
  ],
  templateUrl: './form-field.component.html',
  styleUrl: './form-field.component.css',
})
export class FormFieldComponent {
  @Input() labelClass: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() icon: boolean = false;
  @Input() iconLocation: string = '';
  @Input() iconTooltip?: string;
  @Input() sendBtn: boolean = false;
  @Input() className: string = '';
  @Output() childEvent = new EventEmitter<void>();
  @Input() formGroup!: FormGroup;
  @Input() controlName: string = '';
  @Output() iconEvent = new EventEmitter<void>();
  @Input() iconClass: string = '';
  @Input() enablePasteButton: boolean = false;
  get control(): FormControl {
    return this.formGroup.get(this.controlName) as FormControl;
  }

  onSendButtonClick() {
    this.childEvent.emit();
  }

  onIconButtonClick() {
    this.iconEvent.emit();
  }

  onPasteButtonClick() {
    navigator.clipboard
      .readText()
      .then((text) => {
        this.control.setValue(this.control.value + text);
      })
      .catch((err) => {
        console.error('Failed to read clipboard contents: ', err);
      });
  }
}
