import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from '../main.service';
import { map, Observable, of } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { UserType, UserTypeEnum } from '../../types';
import { GlobalDataService } from '../global-data/global-data.service';

export type RawAuthResponse = {
  id: number;
  userId: string;
  displayName: string;
  userType: UserTypeEnum;
};

export type AuthResponse = {
  userId: number;
  externalId: string;
  displayName: string;
  userType: UserTypeEnum;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private globalData: GlobalDataService
  ) {}

  authenticateToken(token: string): Observable<AuthResponse | null> {
    if (token !== '') {
      return this.http
        .post<RawAuthResponse>(
          this.mainService.getApiURL() + 'User/Authenticate',
          {},
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
            }),
          }
        )
        .pipe(
          map((response: RawAuthResponse): AuthResponse => {
            let userType: UserTypeEnum = UserType.Unknown;
            if (response.userType === UserType.ActiveDirectory) {
              userType = UserType.ActiveDirectory;
            } else if (response.userType === UserType.AgillicAdvisor) {
              userType = UserType.AgillicAdvisor;
            }
            return {
              userId: response.id,
              externalId: response.userId,
              displayName: response.displayName,
              userType: userType,
            };
          }),
          map((authResponse: AuthResponse) => {
            this.globalData.setSessionData({
              initialized: true,
              userId: authResponse.userId,
              userType: authResponse.userType,
              token: token,
              store: null,
              storeImpersonation: false,
            });
            return authResponse;
          })
        );
    } else {
      return of(null);
    }
  }
}
