import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, map, Observable, of, Subscription, zip } from 'rxjs';
import { MainService } from '../main.service';
import {
  CreateSendout,
  Sendout,
  SendoutStatus,
  SendoutStatusEnum,
  UpdateSendout,
  UserType,
} from '../../types';
import {
  GlobalDataService,
  SessionData,
} from '../global-data/global-data.service';
import { StoreService } from '../store/store.service';
import {
  addDays,
  differenceInMinutes,
  isAfter,
  isSameDay,
  isSunday,
  parseISO,
  startOfDay,
} from 'date-fns';

type RawSendout = {
  id: number;
  sendoutTemplateId: number;
  storeId: number;
  createdAt: string;
  createdBy: number;
  updatedByName: string;
  createdByName: string | null;
  updatedAt: string;
  updatedBy: number;
  reviewedAt: string | null;
  reviewedBy: number;
  sendoutName: string;
  status: SendoutStatusEnum;
  eventId: string | null;
  contentSubjectLine: string;
  contentPreheader: string;
  contentHeadline: string;
  contentBodyCopy: string;
  contentCtaText: string;
  contentCtaUrl: string;
  isDeleted: boolean;
  contentImageUrl: string;
  contentDisclaimer: string;
  contactPerson: string | null;
  sendoutDate: string;
};

type SendoutFilter = (s: RawSendout) => boolean;

export enum SendoutDateLockReason {
  Past,
  Sunday,
  OtherSendoutOnSameDay,
  TooCloseToOtherSendout,
  TooCloseToToday,
}

export type SendoutDateLock = {
  date: Date;
  softLock: boolean;
  reason: SendoutDateLockReason;
};

@Injectable({
  providedIn: 'root',
})
export class SendoutService implements OnDestroy {
  private sessionDataSubscription: Subscription;
  private sessionData?: SessionData;

  private cachedSendouts: Sendout[] = [];
  private cachedSendoutsRefreshed: Date = new Date(0);
  private cachedSendoutsTTLMinutes = 5;

  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private globalData: GlobalDataService,
    private storeService: StoreService
  ) {
    this.sessionDataSubscription = this.globalData
      .getSessionData()
      .subscribe((sessionData) => {
        if (this.sessionData?.store?.id !== sessionData?.store?.id) {
          this.cachedSendouts = [];
          this.cachedSendoutsRefreshed = new Date(0);
        }
        this.sessionData = sessionData;
      });
  }

  ngOnDestroy(): void {
    this.sessionDataSubscription?.unsubscribe();
  }

  fetchAllSendouts(): Observable<Sendout[]> {
    // Set up filter - default to all sendouts
    let filter: SendoutFilter = (s) => true;

    // Filter sendouts by storeId
    if (
      this.sessionData?.userType === UserType.AgillicAdvisor ||
      this.sessionData?.storeImpersonation
    ) {
      filter = (s: RawSendout) => s.storeId === this.sessionData?.store?.id;
    } else {
      // Disregard drafts for HQ user
      filter = (s: RawSendout) => s.status !== SendoutStatus.Draft;
    }

    return zip(
      this.http.get<RawSendout[]>(
        this.mainService.getApiURL() + 'Sendout/Sendouts',
        {
          headers: this.mainService.getHeaders(),
        }
      ),
      this.storeService.getAllStores()
    ).pipe(
      map(([sendouts, stores]) =>
        sendouts.filter(filter).map((sendout) => {
          const normalizedSendout: Sendout = {
            id: sendout.id,
            sendoutTemplateId: sendout.sendoutTemplateId,
            storeId: sendout.storeId,
            createdAt: parseISO(sendout.createdAt),
            createdBy: sendout.createdBy,
            updatedByName: sendout.updatedByName,
            createdByName: sendout.createdByName,
            updatedAt: parseISO(sendout.updatedAt),
            updatedBy: sendout.updatedBy,
            reviewedAt: sendout.reviewedAt
              ? parseISO(sendout.reviewedAt)
              : null,
            reviewedBy: sendout.reviewedBy,
            sendoutName: sendout.sendoutName,
            status: sendout.status,
            eventId: sendout.eventId,
            contentSubjectLine: sendout.contentSubjectLine,
            contentPreheader: sendout.contentPreheader,
            contentHeadline: sendout.contentHeadline,
            contentBodyCopy: sendout.contentBodyCopy,
            contentCtaText: sendout.contentCtaText,
            contentCtaUrl: sendout.contentCtaUrl,
            contentImageUrl: sendout.contentImageUrl,
            contentDisclaimer: sendout.contentDisclaimer,
            contactPerson: sendout.contactPerson || '',
            sendoutDate: parseISO(sendout.sendoutDate),
            isDeleted: sendout.isDeleted,
          };

          normalizedSendout.store = stores.find(
            (store) => store.id === sendout.storeId
          );

          return normalizedSendout;
        })
      )
    );
  }

  getAllSendouts(): Observable<Sendout[]> {
    const now = new Date();
    if (
      differenceInMinutes(now, this.cachedSendoutsRefreshed) >
      this.cachedSendoutsTTLMinutes
    ) {
      return this.fetchAllSendouts().pipe(
        map((sendouts) => {
          const status = new Set<string>();
          this.cachedSendouts = sendouts;
          this.cachedSendoutsRefreshed = new Date();
          sendouts.forEach((sendout) => status.add(sendout.status));
          return sendouts;
        })
      );
    } else {
      return of(this.cachedSendouts);
    }
  }

  getSendoutById(id: number): Observable<Sendout> {
    return zip(
      this.http.get<Sendout>(this.mainService.getApiURL() + `Sendout/${id}`, {
        headers: this.mainService.getHeaders(),
      }),
      this.storeService.getAllStores()
    ).pipe(
      map(([sendout, stores]) => {
        sendout.store = stores.find((store) => store.id === sendout.storeId);
        return sendout;
      })
    );
  }

  deleteSendoutById(id: number): Observable<any> {
    this.cachedSendoutsRefreshed = new Date(0);
    return this.http.delete<any>(
      this.mainService.getApiURL() + `Sendout/${id}`,
      {
        headers: this.mainService.getHeaders(),
      }
    );
  }

  createSendout(body: CreateSendout): Observable<Sendout> {
    this.cachedSendoutsRefreshed = new Date(0);
    return this.http.post<Sendout>(
      this.mainService.getApiURL() + 'Sendout',
      body,
      {
        headers: this.mainService.getHeaders(),
      }
    );
  }

  updateSendout(body: UpdateSendout): Observable<Sendout> {
    this.cachedSendoutsRefreshed = new Date(0);
    return this.http.put<Sendout>(
      this.mainService.getApiURL() + 'Sendout',
      body,
      {
        headers: this.mainService.getHeaders(),
      }
    );
  }

  getLockPeriods(sendoutDate: Date): Date[] {
    const startLock = addDays(sendoutDate, -7);
    const endLock = addDays(sendoutDate, 7);

    const lockPeriods: Date[] = [];
    for (let date = startLock; date <= endLock; date = addDays(date, 1)) {
      lockPeriods.push(startOfDay(date));
    }
    return lockPeriods;
  }

  async getUpcomingSendoutDateLocks(
    sendouts?: Sendout[]
  ): Promise<SendoutDateLock[]> {
    const today = startOfDay(new Date());
    const startDate = addDays(today, -7);

    let lockedDays: SendoutDateLock[] = [
      {
        date: today,
        reason: SendoutDateLockReason.TooCloseToToday,
        softLock: false,
      },
      {
        date: addDays(today, 1),
        reason: SendoutDateLockReason.TooCloseToToday,
        softLock: false,
      },
      {
        date: addDays(today, 2),
        reason: SendoutDateLockReason.TooCloseToToday,
        softLock: false,
      },
    ];

    const allSendouts =
      sendouts || (await firstValueFrom(this.getAllSendouts()));
    const filteredSendouts = allSendouts.filter(
      (s) =>
        isSameDay(s.sendoutDate, startDate) || isAfter(s.sendoutDate, startDate)
    );
    filteredSendouts.forEach((sendout) => {
      const lockPeriods = this.getLockPeriods(sendout.sendoutDate);
      lockPeriods.forEach((date) => {
        lockedDays.push({
          date: new Date(date),
          reason: isSameDay(sendout.sendoutDate, date)
            ? SendoutDateLockReason.OtherSendoutOnSameDay
            : SendoutDateLockReason.TooCloseToOtherSendout,
          softLock: true,
        });
      });
    });
    return lockedDays;
  }

  async getNextAvailableSendoutDate(allowSoftLocked: true): Promise<Date> {
    const sendouts = await firstValueFrom(this.getAllSendouts());
    for (let daysFromNow = 3; daysFromNow < 90; daysFromNow++) {
      const date = addDays(new Date(), daysFromNow);

      // Is sunday?
      if (isSunday(date)) {
        continue;
      }

      // Is date already taken?
      if (sendouts.some((s) => isSameDay(s.sendoutDate, date))) {
        continue;
      }
      return date;
    }
    throw new Error('No available sendout dates found');
  }
}
