import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
} from '@angular/core';
import { FilledButtonComponent } from '../../atoms/filled-button/filled-button.component';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateFnsModule } from 'ngx-date-fns';
import { SendoutStatusPipe } from '../../../pipes/SendoutStatus.pipe';
import { Template } from '../../../types';
import { TemplateCategoryPipe } from '../../../pipes/TemplateCategory.pipe';

@Component({
  selector: 'app-template-card',
  standalone: true,
  imports: [
    FilledButtonComponent,
    CommonModule,
    MatTooltipModule,
    DateFnsModule,
    SendoutStatusPipe,
    TemplateCategoryPipe,
  ],
  templateUrl: './template-card.component.html',
  styleUrl: './template-card.component.css',
})
export class TemplateCardComponent {
  @Input() template?: Template;
  @Input() buttonText: string = 'Vælg template';

  @Output() buttonClick = new EventEmitter<void>();

  onButtonClick() {
    this.buttonClick.emit();
  }
}
