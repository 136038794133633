<div class="d-flex flex-column position-relative" [formGroup]="formGroup" [ngStyle]="{
    '--border-color': control.touched && control.invalid ? 'red' : '#002643'
  }">
  @if (labelText !== "") {
  <label class="{{ labelClass }}">{{ labelText }}</label>
  }
  <p class="{{ subTextClass }}">{{ subText }}</p>
  <mat-radio-group aria-label="Select an option" class="d-flex flex-column radio-grp" [ngClass]="{ 'flex-column': direction == RadioFieldDirection.Vertical, 'flex-row': direction == RadioFieldDirection.Horizontal}" [formControlName]="controlName">
    <mat-radio-button [value]="true">Ja</mat-radio-button>
    <mat-radio-button [value]="false">Nej</mat-radio-button>
  </mat-radio-group>

  @if (control.touched && control.invalid) {
  <div class="position-absolute form-validation-error-container">
    @if (control.errors?.['required']) { }
  </div>
  }
</div>
