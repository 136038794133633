import { Component } from '@angular/core';

@Component({
  selector: 'app-calendar-helpers',
  standalone: true,
  imports: [],
  templateUrl: './calendar-helpers.component.html',
  styleUrl: './calendar-helpers.component.css'
})
export class CalendarHelpersComponent {

}
