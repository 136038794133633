import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalDataService } from '../../../services/global-data/global-data.service';
import { AuthService } from '../../../services/auth/auth.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { StoreService } from '../../../services/store/store.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-store-login',
  standalone: true,
  imports: [],
  templateUrl: './store-login.component.html',
  styleUrl: './store-login.component.css',
})
export class StoreLoginComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalData: GlobalDataService,
    private authService: AuthService,
    private storeService: StoreService,
    private snackbarService: SnackbarService
  ) {}
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.authService.authenticateToken(params['token']).subscribe({
        next: async (res) => {
          if (res) {
            // Load store
            const store = await firstValueFrom(
              this.storeService.getStoreById(parseInt(res.externalId, 10))
            );

            if (!store) {
              this.snackbarService.show('Store not found');
              return;
            }
            const sessionData = this.globalData.getSessionData().value;
            if (sessionData) {
              this.globalData.setSessionData({
                ...sessionData,
                store,
              });
            }
            this.router.navigate(['overview']);
          }
        },
        error: (error) => {
          console.error(`[StoreLoginComponent] error:`, error);
        },
      });
    });
  }
}
