import { Routes } from '@angular/router';
import { TemplateComponent } from './components/organisms/template/template.component';
import { LoginComponent } from './components/organisms/login/login.component';
import { SelectTemplateComponent } from './components/organisms/select-template/select-template.component';
import { EditSendoutComponent } from './components/organisms/edit-sendout/edit-sendout.component';
import { OverviewComponent } from './components/organisms/overview/overview.component';
import { EmailSendoutComponent } from './components/organisms/email-sendout/email-sendout.component';
import { EmailSendoutManagerComponent } from './components/organisms/email-sendout-manager/email-sendout-manager.component';
import { SelectEventComponent } from './components/organisms/select-event/select-event.component';
import { TemplatesComponent } from './components/organisms/templates/templates.component';
import { StoreLoginComponent } from './components/organisms/store-login/store-login.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { UserTypeGuard } from './guards/user-type/user-type.guard';
import { UserType } from './types';
import { NotFoundPageComponent } from './components/organisms/not-found-page/not-found-page.component';
export const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    title: 'Login',
    pathMatch: 'full',
  },
  {
    path: 'storelogin',
    component: StoreLoginComponent,
    title: 'StoreLogin',
    pathMatch: 'full',
  },
  {
    path: 'overview',
    component: OverviewComponent,
    title: 'Overblik',
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'template/:id',
    component: TemplateComponent,
    title: 'Template',
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'templates',
    component: TemplatesComponent,
    title: 'Templates',
    pathMatch: 'full',
  },
  {
    path: 'sendouts',
    component: EmailSendoutComponent,
    title: 'Udsendelser',
    canActivate: [AuthGuard],
    canMatch: [UserTypeGuard(UserType.AgillicAdvisor)],
    pathMatch: 'full',
  },
  {
    path: 'sendouts',
    component: EmailSendoutManagerComponent,
    title: 'Udsendelser',
    canActivate: [AuthGuard],
    canMatch: [UserTypeGuard(UserType.ActiveDirectory)],
    pathMatch: 'full',
  },

  {
    path: 'sendout/create',
    redirectTo: 'sendout/create/select-template',
    pathMatch: 'full',
  },
  {
    path: 'sendout/create/select-template',
    component: SelectTemplateComponent,
    title: 'Select Templates',
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'sendout/create/select-event',
    component: SelectEventComponent,
    title: 'Select event',
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'sendout/new',
    component: EditSendoutComponent,
    title: 'New sendout',
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'sendout/:sendoutId',
    component: EditSendoutComponent,
    title: 'Edit Sendout',
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'sendout/:sendoutId/select-event',
    component: SelectEventComponent,
    title: 'Select event',
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },

  {
    path: '**',
    component: NotFoundPageComponent,
    title: 'Not found',
  },
];
