<div style="margin-bottom: 24px">
  <h5 class="week-text">
    {{ week }}
  </h5>

  @for (sendout of allSendouts; track sendout.id) {
  <div class="inner-container" (click)="handleSendoutClick(sendout)" [ngClass]="sendout.status | sendoutStatus : 'class'">
    <h3 class="heading">
      {{ sendout.sendoutName }}
    </h3>
    <div class="date-container">
      <img src="../../../../assets/clock.svg" alt="clock" style="padding: 0; margin: 0" />
      <p class="date-text">
        {{ sendout.sendoutDate | dfnsFormat : 'd.M.yyyy' }}
      </p>
    </div>
  </div>
  }
</div>
