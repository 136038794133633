import { AbstractControl, ValidationErrors } from '@angular/forms';

const internalNamePattern = /^[a-zA-Z][a-zA-Z0-9 _\-]+?[a-zA-Z0-9]$/;

export function templateInternalNameValidator(
  control: AbstractControl
): ValidationErrors | null {
  const valid = internalNamePattern.test(control.value);
  return valid ? null : { templateInternalName: { value: control.value } };
}
