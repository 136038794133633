import {
  Component,
  Input,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CardComponent } from '../card/card.component';
import { EventBatch, Sendout, EventWithSendouts } from '../../../types';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-broadcast-events',
  standalone: true,
  imports: [CardComponent, MatIconModule, MatButtonModule, CommonModule],
  templateUrl: './broadcast-events.component.html',
  styleUrl: './broadcast-events.component.css',
})
export class BroadcastEventsComponent implements OnInit, OnChanges {
  @Input() mainHeading: string = '';
  @Input() events: EventWithSendouts[] = [];
  @Input() sendouts: Sendout[] = [];
  @Input() isLoading: boolean = false;
  @Output() buttonClick = new EventEmitter();
  eventBatches: EventBatch[] = [];

  screenWidth: number = 0;

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  ngOnChanges(): void {
    let batches: EventBatch[] = [];
    if (this.screenWidth > 1400) {
      for (let i = 0; i < this.events.length; i += 3) {
        batches.push({ id: i / 3 + 1, batch: this.events.slice(i, i + 3) });
      }
    } else if (this.screenWidth >= 768 && this.screenWidth < 1400) {
      for (let i = 0; i < this.events.length; i += 2) {
        batches.push({ id: i / 2 + 1, batch: this.events.slice(i, i + 2) });
      }
    } else if (this.screenWidth < 767) {
      for (let i = 0; i < this.events.length; i++) {
        batches.push({ id: i + 1, batch: this.events.slice(i, i + 1) });
      }
    }
    this.eventBatches = batches;
  }

  sendoutDateClicked(sendout: Sendout) {
    this.buttonClick.emit(sendout);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.screenWidth = event.target.innerWidth;
  }
}
