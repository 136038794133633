import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { MainService } from '../main.service';
import { Brand } from '../../types';
import { differenceInMinutes } from 'date-fns';

export interface RawBrand {
  id: string;
  name: string;
  description: null | string;
  isActive: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class BrandsService {
  private cacheTtlMinutes = 5;
  private allBrands: Brand[] = [];
  private loadedAt: Date = new Date(0);

  constructor(private http: HttpClient, private mainService: MainService) {}

  getBrandById(brandId: number): Observable<Brand | null> {
    return this.getAllBrands().pipe(
      map((brands) => {
        return brands.find((brand) => brand.id === brandId) || null;
      })
    );
  }

  getAllBrands(): Observable<Brand[]> {
    if (differenceInMinutes(new Date(), this.loadedAt) > this.cacheTtlMinutes) {
      return this.http
        .get<RawBrand[]>(this.mainService.getApiURL() + 'Brand', {
          headers: this.mainService.getHeaders(),
        })
        .pipe(
          map((brands) => {
            this.allBrands = brands.map((brand) => {
              return {
                id: parseInt(brand.id, 10),
                name: brand.name,
                description: brand.description,
                isActive: brand.isActive,
              };
            });
            this.loadedAt = new Date();
            return this.allBrands;
          })
        );
    } else {
      return of(this.allBrands);
    }
  }
}
