import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MenuComponent } from './components/molecules/menu/menu.component';
import { LoginComponent } from './components/organisms/login/login.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { StoreImpersonationHeaderComponent } from './components/molecules/store-impersonation-header/store-impersonation-header.component';
import {
  GlobalDataService,
  SessionData,
} from './services/global-data/global-data.service';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  imports: [
    RouterOutlet,
    MenuComponent,
    LoginComponent,
    NgxSkeletonLoaderModule,
    StoreImpersonationHeaderComponent,
    AsyncPipe,
  ],
})
export class AppComponent {
  title = 'Store Activation Module';
  sessionData$: BehaviorSubject<SessionData> =
    this.globalDataService.getSessionData();

  constructor(private globalDataService: GlobalDataService) {
    console.log('Env is ', environment.title);
  }
}
