<nav class="navbar sticky-top bg-white d-none d-lg-block d-xl-block nav-container" (outsideClick)="onOutsideClick()" appFilterDirective>
  <div class="d-flex justify-content-between h-100">
    <div>
      <app-nav-logo></app-nav-logo>
    </div>
    @if (sessionData$ | async; as sessionData) {
    <div class="d-flex flex-row justify-content-between align-items-center h-100 {{
        sessionData.userType === UserType.AgillicAdvisor || sessionData.storeImpersonation  ? 'menu-width-store' : 'menu-width'
      }}">
      @if (sessionData.userType === UserType.AgillicAdvisor || sessionData.storeImpersonation) {
      <app-stroked-button [routerLink]="'sendout/create'" buttonText="Opret udsendelse"></app-stroked-button>
      }

      @for (link of navLinksData; track link.id) {
      <app-navlink [routerLinkName]="link.routerLinkName" [routerLinkActiveName]="'active'" [extraClasses]="'custom-hover-animation'" [linkText]="link.linkText" />
      }

      @if (sessionData.userType == UserType.ActiveDirectory && !sessionData.storeImpersonation) {
      <img src="../../../../assets/impersonation_icon.svg" alt="impersonation icon" class="icon" (click)="toggleShowDropDown()" />
      }

      <filled-button [buttonText]="'Log ud'" (childEvent)="logout()"></filled-button>
    </div>}
  </div>
  @if (sessionData$ | async; as sessionData) {
  @if (sessionData.userType == UserType.ActiveDirectory && !sessionData.storeImpersonation && isLoading === false) {
  <app-store-impersonation-dropdown [showDropDown]="showDropDown" (storeSelected)="storeClickForImpersonation($event)"></app-store-impersonation-dropdown>
  }
  }
</nav>

<navlink-toggle-btn (mobileToggleBtnClicked)="toggleMenu()"></navlink-toggle-btn>

@if (showMenu) {
<nav class="navbar bg-white d-block d-lg-none d-flex justify-content-center align-items-center nav-mobile-container">
  <div class="container-fluid d-flex flex-column justify-content-between menu-mobile-container">
    <app-stroked-button [routerLink]="'sendout/create'" buttonText="Opret udsendelse" (navLinkClicked)="disableMenu()"></app-stroked-button>

    @for (link of navLinksData; track link.id) {
    <app-navlink [routerLinkName]="link.routerLinkName" [routerLinkActiveName]="'active-mobile'" [extraClasses]="'custom-hover-animation-mobile'" [linkText]="link.linkText" (navLinkClicked)="disableMenu()"></app-navlink>
    }

    <filled-button buttonText="Log ud" className="font-size" (childEvent)="logout()"></filled-button>
  </div>
</nav>
}
