<div class="d-flex align-items-center gap-2 position-relative {{ margin }}">
  @if(showFilter) {
  <div appFilterDirective (outsideClick)="onOutsideClick()" class="position-absolute filters-container {{ leftRightPosition }}">
    <div class="d-flex filter-header-container" (click)="toggleFilter()">
      <div class="d-flex align-items-center justify-content-center header-text">
        {{ filterText }}
      </div>
      <div class="header-filter-text-container">
        <span class="header-filter-text">Filter</span>
      </div>
    </div>

    <div class="filter-checkboxes-container">
      @for (data of filterData; track data.id) {
      <div class="d-flex gap-2 align-items-center filter-checkboxes">
        <input type="checkbox" class="chk-box" [(ngModel)]="data.checked" (change)="onCheckboxChange()" />
        <span class="{{ data.classes }}">{{ data.text }}</span>
      </div>
      }
    </div>
  </div>
  }
  <div class="filter-icon-container" (click)="toggleFilter()">
    <img src="./assets/filter_icon.svg" alt="filter icon" class="img-icon" />
  </div>
  <span class="filter-text" (click)="toggleFilter()">{{ filterText }}</span>
</div>
