import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ContentChange, QuillEditorComponent } from 'ngx-quill';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-text-editor',
  standalone: true,
  imports: [
    QuillEditorComponent,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './text-editor.component.html',
  styleUrl: './text-editor.component.css',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEditorComponent implements OnInit {
  @Input() placeholder: string = 'text here ...';
  @Input() label!: string;
  @Input() mainClass: string = '';
  @Input() labelClass: string = '';
  @Input() MAX_LENGTH!: number;
  @Input() showLimit: boolean = false;
  @Input() editorClass: string = '';
  @Input() iconClass: string = '';

  @Input() formGroup!: FormGroup;
  @Input() controlName: string = '';
  @Input() enablePasteButton: boolean = false;

  charCount: number = 0;

  @ViewChild(QuillEditorComponent, { static: true }) editor:
    | QuillEditorComponent
    | undefined;

  get control(): FormControl {
    return this.formGroup.get(this.controlName) as FormControl;
  }

  ngOnInit() {
    this.updateCharCount();
    const text = this.control.value || '';
    if (text.length > 1000) {
      this.charCount = 1000;
    } else {
      this.charCount = text.length;
    }
  }

  editorOptions = {
    toolbar: [['bold', 'italic', 'underline', 'strike']],
    clipboard: {
      matchVisual: false,
    },
  };

  updateCharCount() {
    const text = this.editor?.quillEditor?.getText().trim() || '';
    if (text.length > 1000) {
      this.charCount = 1000;
    } else {
      this.charCount = text.length;
    }
  }
  onContentChanged(event: ContentChange): void {
    if (event.editor.getLength() > this.MAX_LENGTH) {
      event.editor.deleteText(this.MAX_LENGTH, event.editor.getLength());
    }
    this.updateCharCount();
  }

  onPasteButtonClick() {
    navigator.clipboard
      .readText()
      .then((text) => {
        this.control.setValue((this.control.value || '') + text);
      })
      .catch((err) => {
        console.error('Failed to read clipboard contents: ', err);
      });
  }
}
