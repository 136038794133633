<div class="form-field-container d-flex flex-column {{ className }}" [formGroup]="formGroup" [ngStyle]="{
    '--border-color': control.touched && control.invalid ? 'red' : '#afcbe0'
  }">
  @if (label !== "") {
  <label class="{{ labelClass }}">{{ label }}</label>
  }
  <mat-form-field appearance="outline" class="position-relative">
    <input matInput placeholder="{{ placeholder }}" [formControlName]="controlName" />
    @if (icon) {
    <mat-icon mat-suffix class="position-absolute icon" [matTooltip]="iconTooltip || null">
      <img src="{{ iconLocation }}" alt="icon" class="position-absolute icon-img" [ngClass]="iconClass !== '' ? iconClass : ''" (click)="onIconButtonClick()" />
    </mat-icon>
    }
    @if (enablePasteButton && !this.control.disabled) {
    <mat-icon matSuffix style="margin-top:-8px;" matTooltip="Indsæt fra udklipsholder" (click)="onPasteButtonClick()">content_paste</mat-icon>
    }
    @if (sendBtn) {
    <button class="send-btn position-absolute" (click)="onSendButtonClick()">
      Send
    </button>
    }
    @if (control.touched && control.invalid) {
    <div class="position-absolute form-validation-error-container">
      @if (control.errors?.['required']) {
      <small>Dette felt er påkrævet.</small>
      } @if (control.errors?.['minlength']) {
      <small>Minimum length is 3 characters.</small>
      } @if (control.errors?.['email']) {
      <small>Must be an email.</small>
      }
      @if (control.errors?.['ctaUrl']) {
      <small>Feltet skal være tomt eller indeholde en gyldig URL eller reference</small>
      }
      @if (control.errors?.['templateInternalName']) {
      <small>Må kun indeholde a-z, A-Z, 0-9, mellemrum, bindestreg og underscore</small>
      }
    </div>
    }
  </mat-form-field>
</div>
