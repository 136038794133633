import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../components/molecules/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openDialog(
    heading: string,
    content: string,
    buttonText: string,
    buttonFunction: (id?: number) => void,
    id?: number,
    width?: string,
    dialogContainerClass?: string,
    headingClass?: string,
    contentClass?: string,
    buttonContainerClass?: string,
  ): void {
    this.dialog.open(DialogComponent, {
      width: width,
      panelClass: '',
      data: {
        heading,
        content,
        buttonText,
        buttonFunction,
        id,
        dialogContainerClass,
        headingClass,
        contentClass,
        buttonContainerClass,
      },
    });
  }
}
