<div class="{{ mainClass }}" [ngStyle]="{
        '--border-color': control.touched && control.invalid ? 'red' : '#afcbe0'
    }" [formGroup]="formGroup">
  <label class="{{ labelClass }}">{{label}}</label>

  <quill-editor #editor [modules]="editorOptions" [placeholder]="placeholder" class="{{editorClass}}" (onContentChanged)="onContentChanged($event)" [formControlName]="controlName"></quill-editor>

  @if (enablePasteButton && !this.control.disabled) {
  <mat-icon matSuffix class="{{ iconClass }}" matTooltip="Indsæt fra udklipsholder" (click)="onPasteButtonClick()">content_paste</mat-icon>
  }

  <div class="w-100 d-flex {{ control.touched && control.invalid ? 'justify-content-between' : 'justify-content-end' }} align-items-center">
    @if (control.touched && control.invalid) {
    @if (control.errors?.['required']) {
    <small class="error">Dette felt er påkrævet.</small>
    }
    }

    @if (showLimit) {
    <small class="text-end char-text">{{ charCount }} / {{ MAX_LENGTH }}</small>
    }
  </div>
</div>
