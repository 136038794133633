import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FilledButtonComponent } from '../../atoms/filled-button/filled-button.component';
import { LogoComponent } from '../../atoms/logo/logo.component';
import { MainHeadingComponent } from '../../atoms/main-heading/main-heading.component';
import {
  AuthenticationResult,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { FormFieldComponent } from '../../atoms/form-field/form-field.component';
import { Router } from '@angular/router';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { GlobalDataService } from '../../../services/global-data/global-data.service';
import { environment } from '../../../../environments/environment';
import { UserType } from '../../../types';

export interface User {
  email: string;
  password: string;
}

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FilledButtonComponent,
    LogoComponent,
    MainHeadingComponent,
    FormFieldComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent implements OnInit {
  constructor(
    private msalService: MsalService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private globalDataService: GlobalDataService
  ) {}
  async ngOnInit() {
    const msalInstance = this.msalService.instance as PublicClientApplication;
    console.log("environment :", environment);
    

    await msalInstance.initialize();
    if (sessionStorage.getItem('msalHandleRedirect') == '1') {
      msalInstance.handleRedirectPromise().then((authResult) => {
        if (authResult) {
          msalInstance.setActiveAccount(authResult.account);
          if (authResult.account.idToken) {
            this.authService
              .authenticateToken(authResult.account.idToken)
              .subscribe((authResponse) => {
                console.log(
                  `[AppComponent.ngOnInit] authResponse:`,
                  authResponse
                );
                sessionStorage.removeItem('msalHandleRedirect');
                if (authResponse?.userType == UserType.ActiveDirectory) {
                  this.router.navigate(['sendouts']);
                }
              });
          }
        }
      });
    }
  }

  loginAD() {
    const msalInstance = this.msalService.instance as PublicClientApplication;
    sessionStorage.setItem('msalHandleRedirect', '1');
    this.msalService.loginRedirect({
      scopes: ['user.read'],
    });
  }

  onSubmit() {
    const url = `https://www.clubmatas.dk/advisor/faces/public/exo/advisoridentityprovider?cb=${environment.baseUrl}storelogin`;
    window.location.href = url;
  }
}
