import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { MainHeadingComponent } from '../../atoms/main-heading/main-heading.component';
import { SimpleTextComponent } from '../../atoms/simple-text/simple-text.component';
import {
  MailPreviewData,
  MailPreviewService,
} from '../../../services/mail-preview/mail-preview.service';
import { SafeHtml } from '@angular/platform-browser';
import { debounceTime, Subject } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-email',
  standalone: true,
  imports: [SimpleTextComponent, MainHeadingComponent, CommonModule],
  templateUrl: './email.component.html',
  styleUrl: './email.component.css',
})
export class EmailComponent implements OnChanges, OnDestroy {
  @Input() templateName: string = '';
  @Input() subjectLine: string = '';
  @Input() preheader: string = '';
  @Input() headline: string = '';
  @Input() bodyCopy: string = '';
  @Input() ctaUrl: string = '';
  @Input() disclaimer: string = '';
  @Input() ctaText: string = '';
  @Input() contentImageUrl: string = '';

  @Input() storeStreetName: string = 'Vejnavn 123';
  @Input() storeCity: string = 'By';
  @Input() points: string = '1.234';

  private previewDataSubject$ = new Subject<MailPreviewData>();

  previewHtml$ = new Subject<SafeHtml>();

  constructor(private mailPreviewService: MailPreviewService) {
    this.previewDataSubject$
      .pipe(debounceTime(1000))
      .subscribe(async (previewData) => {
        try {
          this.previewHtml$.next(
            await this.mailPreviewService.getMailPreviewHtml(previewData)
          );
        } catch (error) {
          this.previewHtml$.next('<strong>Unable to load preview</strong>');
        }
      });
  }

  ngOnDestroy() {
    this.previewDataSubject$.complete();
    this.previewHtml$.complete();
  }

  ngOnChanges(): void {
    const mailPreviewData = {
      templateName: this.templateName,
      data: {
        CONTENT_SUBJECT_LINE: this.subjectLine,
        CONTENT_PREHEADER: this.preheader,
        CONTENT_HEADLINE: this.headline,
        CONTENT_BODY_COPY: this.bodyCopy,
        CONTENT_CTA_URL: this.ctaUrl,
        CONTENT_CTA_TEXT: this.ctaText,
        CONTENT_DISCLAIMER: this.disclaimer,
        CONTENT_IMAGE_URL: this.contentImageUrl,
        POINTS: this.points,
        'BUTIK.STREETNAME': this.storeStreetName,
        'BUTIK.CITY': this.storeCity,
      },
    };

    this.previewDataSubject$.next(mailPreviewData);
  }
}
