import { Filter } from '../types';

export const templateFilters: Filter[] = [
  {
    id: 1,
    text: 'Brand',
    classes: 'template-filter-text',
    checked: true,
  },
  {
    id: 2,
    text: 'Konsultation',
    classes: 'template-filter-text',
    checked: true,
  },
  {
    id: 3,
    text: 'Sæson',
    classes: 'template-filter-text',
    checked: true,
  },
  {
    id: 4,
    text: 'Aktiviteter',
    classes: 'template-filter-text',
    checked: true,
  },
  {
    id: 5,
    text: 'Andet',
    classes: 'template-filter-text',
    checked: true,
  },
];

export const sendoutFilters: Filter[] = [
  {
    id: 1,
    text: 'Kladder',
    classes: 'custom-underline kladder-bg',
    checked: true,
  },
  {
    id: 2,
    text: 'Afventer',
    classes: 'custom-underline afventer-bg',
    checked: true,
  },
  {
    id: 3,
    text: 'Godkendt',
    classes: 'custom-underline godkendt-bg',
    checked: true,
  },
  {
    id: 4,
    text: 'Afvist',
    classes: 'custom-underline afvist-bg',
    checked: true,
  },
  {
    id: 5,
    text: 'Udsendt',
    classes: 'custom-underline udsendt-bg',
    checked: true,
  },
  {
    id: 6,
    text: 'Afsluttet',
    classes: 'custom-underline afsluttet-bg',
    checked: true,
  },
];

export const eventFilter: Filter[] = [
  {
    id: 1,
    text: 'Events',
    classes: 'template-filter-text',
    checked: true,
  },
  {
    id: 2,
    text: 'Personlige aftaler',
    classes: 'template-filter-text',
    checked: true,
  },
];
