import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { SearchFieldComponent } from '../../atoms/search-field/search-field.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { Store } from '../../../types';
import { FilterDirectiveDirective } from '../../atoms/filter-directive/filter-directive.directive';
import { StoreService } from '../../../services/store/store.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-store-impersonation-dropdown',
  standalone: true,
  imports: [
    SearchFieldComponent,
    MatMenuModule,
    MatButtonModule,
    FilterDirectiveDirective,
  ],
  templateUrl: './store-impersonation-dropdown.component.html',
  styleUrl: './store-impersonation-dropdown.component.css',
})
export class StoreImpersonationDropdownComponent implements OnInit {
  stores: Store[] = [];
  @Input() showDropDown: boolean = false;
  filteredStores: Store[] = [];
  @Output() storeSelected = new EventEmitter<Store>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private storeService: StoreService
  ) {}

  async ngOnInit(): Promise<void> {
    this.stores = (
      await firstValueFrom(this.storeService.getAllStores())
    ).filter((s) => s.isActive);
    this.stores.sort((a, b) => a.place.localeCompare(b.place, 'da'));
    this.filteredStores = this.stores;
  }

  filterStores(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    if (filterValue !== '') {
      this.filteredStores = this.stores.filter(
        (store) =>
          store.place.toLowerCase().includes(filterValue) ||
          store.id.toString().includes(filterValue)
      );
    } else {
      this.filteredStores = this.stores;
    }
    this.changeDetectorRef.detectChanges();
  }

  handleStoreClick(store: Store) {
    this.storeSelected.emit(store);
  }

  onOutsideClick() {
    this.showDropDown = false;
  }
}
