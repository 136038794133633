import { Component } from '@angular/core';
import { MainHeadingComponent } from '../../atoms/main-heading/main-heading.component';
import { FilledButtonComponent } from '../../atoms/filled-button/filled-button.component';
import { StrokedButtonComponent } from '../../atoms/stroked-button/stroked-button.component';
import { Router } from '@angular/router';
import {
  GlobalDataService,
  SessionData,
} from '../../../services/global-data/global-data.service';
import { AsyncPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { UserType } from '../../../types';

@Component({
  selector: 'app-not-found-page',
  standalone: true,
  imports: [MainHeadingComponent, StrokedButtonComponent, AsyncPipe],
  templateUrl: './not-found-page.component.html',
  styleUrl: './not-found-page.component.css',
})
export class NotFoundPageComponent {
  UserType = UserType;

  sessionData$: BehaviorSubject<SessionData> =
    this.globalDataService.getSessionData();
  constructor(
    private router: Router,
    private globalDataService: GlobalDataService
  ) {}

  goToLogin() {
    this.router.navigate(['']);
  }

  goToOverview() {
    this.router.navigate(['overview']);
  }

  goToSendouts() {
    this.router.navigate(['sendouts']);
  }
}
