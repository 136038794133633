<h4 class="heading-main">{{ mainHeading }}</h4>

<div class="shadow outer-container">
  <div class="d-flex flex-column info-container">
    @if (sendouts.length == 0) {
    <div class="empty-state-label">Ingen udsendelser</div>
    }
    @for (sendout of sendouts; track sendout) {
    @if (sendout.sendouts.length > 0) {
    <app-broadcast-info [week]="sendout.week" [allSendouts]="sendout.sendouts" (buttonClick)="sendoutClick($event)"></app-broadcast-info>
    }
    }
  </div>
</div>
