import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';

import { UserType, UserTypeEnum } from '../../types';
import {
  GlobalDataService,
  SessionData,
} from '../../services/global-data/global-data.service';

export function UserTypeGuard(userType: UserTypeEnum): CanMatchFn {
  return () => {
    const router = inject(Router);
    const globalData = inject(GlobalDataService);

    const sessionData: SessionData = globalData.getSessionData().value;
    if (!sessionData || !sessionData.token) {
      return false;
    }
    // Handle impersonation
    if (userType == UserType.AgillicAdvisor) {
      return (
        sessionData.userType == UserType.AgillicAdvisor ||
        (sessionData.userType == UserType.ActiveDirectory &&
          sessionData.storeImpersonation == true)
      );
    } else {
      return sessionData.userType == userType;
    }
  };
}
