import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MainHeadingSubtextComponent } from '../../atoms/main-heading-subtext/main-heading-subtext.component';
import { FilterComponent } from '../../molecules/filter/filter.component';
import { SearchFieldComponent } from '../../atoms/search-field/search-field.component';
import { FilledButtonComponent } from '../../atoms/filled-button/filled-button.component';
import { TemplateService } from '../../../services/template/template.service';
import { CardComponent } from '../../molecules/card/card.component';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { GlobalDataService } from '../../../services/global-data/global-data.service';
import {
  Template,
  Filter,
  GroupedDataSet,
  UserType,
  UserTypeEnum,
} from '../../../types';
import { DynamicTableComponent } from '../../molecules/dynamic-table/dynamic-table.component';
import { DynamicAccordionComponent } from '../../molecules/dynamic-accordion/dynamic-accordion.component';
import { CardsGroupComponent } from '../../molecules/cards-group/cards-group.component';
import { templateFilters } from '../../../constants/filters.constants';
import { SharedFunctionalityService } from '../../../services/shared-functionality/shared-functionality.service';
import { AsyncPipe } from '@angular/common';
import { templateCategoryGrouping } from '../../../constants/initial.constants';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { SkeletonComponent } from '../../molecules/skeleton/skeleton.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TemplateCategoryPipe } from '../../../pipes/TemplateCategory.pipe';
import { TemplateCardComponent } from '../../molecules/template-card/template-card.component';

@Component({
  selector: 'app-select-template',
  standalone: true,
  imports: [
    MainHeadingSubtextComponent,
    FilterComponent,
    SearchFieldComponent,
    FilledButtonComponent,
    CardComponent,
    MatButtonModule,
    RouterLink,
    RouterLinkActive,
    DynamicTableComponent,
    DynamicAccordionComponent,
    CardsGroupComponent,
    AsyncPipe,
    MatSlideToggleModule,
    FormsModule,
    SkeletonComponent,
    MatExpansionModule,
    TemplateCategoryPipe,
    TemplateCardComponent,
  ],
  templateUrl: './select-template.component.html',
  styleUrl: './select-template.component.css',
})
export class SelectTemplateComponent implements OnInit {
  isLoading: boolean = false;
  displayGrouped: boolean = true;
  storeImpersonation$ = this.globalDataService.getStoreImpersonation();
  cardButtonText: string = 'Vælg';
  emptyStateText: string = 'Ingen Skabelon';

  templates: Template[] = [];
  allTemplates: Template[] = [];

  accordionTemplates: GroupedDataSet<Template> = null!;
  allAccordionTemplates: GroupedDataSet<Template> = null!;

  selectTemplateFilter: Filter[] = templateFilters;

  constructor(
    private templateService: TemplateService,
    private changeDetectorRef: ChangeDetectorRef,
    private snackbarService: SnackbarService,
    private router: Router,
    private globalDataService: GlobalDataService,
    private sharedFunctionalityService: SharedFunctionalityService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getAllTemplates(UserType.AgillicAdvisor);
  }

  getAllTemplates = (userType: UserTypeEnum) => {
    this.isLoading = true;
    this.templateService.getAllTemplates(userType).subscribe({
      next: (templates) => {
        this.templates = templates;
        this.templates.sort((a, b) =>
          a.templateLabel.localeCompare(b.templateLabel, 'da-DK')
        );
        this.allTemplates = [...this.templates];

        this.allAccordionTemplates =
          this.sharedFunctionalityService.getGroupedDataSet<Template>(
            this.templates,
            templateCategoryGrouping,
            (t) => t.category
          );

        this.accordionTemplates = { ...this.allAccordionTemplates };

        this.isLoading = false;
      },
      error: (error) => {
        this.snackbarService.show('Der kunne ikke hentes udsendelser');
        this.isLoading = false;
      },
      complete: () => {
        this.changeDetectorRef.detectChanges();
      },
    });
  };

  templateClick = async (data: Template) => {
    await this.router.navigate(['sendout', 'create', 'select-event'], {
      queryParams: {
        templateId: data.id,
        sendoutDate:
          this.activatedRoute.snapshot.queryParams['sendoutDate'] || null,
      },
    });
  };

  onSlideToggleChange() {
    this.displayGrouped = this.sharedFunctionalityService.onSlideToggleChange(
      this.displayGrouped
    );
  }

  onCategoryFilterChange() {
    this.accordionTemplates = this.sharedFunctionalityService.onFilterChange(
      this.selectTemplateFilter,
      this.allAccordionTemplates,
      'accordionHeading'
    );
  }

  applySearchFilter(filterValue: string) {
    const { filteredItems, slideToggleState } =
      this.sharedFunctionalityService.applySearchFilter(
        filterValue,
        this.allTemplates,
        ['templateLabel'],
        this.changeDetectorRef,
        this.displayGrouped
      );
    this.templates = filteredItems;
    this.displayGrouped = slideToggleState;
  }
}
