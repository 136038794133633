import { Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from '../environments/environment';

export const msalConfig: Configuration = {
  auth: {
    clientId: `${environment.clientId}`,
    authority:
      'https://login.microsoftonline.com/46c3040b-62b3-4840-a06e-b8473957d997',
    redirectUri: `${environment.baseUrl}`, // Adjust as necessary
  },
  cache: {
    cacheLocation: 'sessionStorage', // or 'sessionStorage'
    storeAuthStateInCookie: false, // Set to true for IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ['user.read'],
};

export const protectedResources = {
  api: {
    endpoint: 'https://graph.microsoft.com/v1.0/me',
    scopes: ['User.Read'],
  },
};
