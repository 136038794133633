import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { UserType } from '../../types';
import {
  GlobalDataService,
  SessionData,
} from '../../services/global-data/global-data.service';

export const AuthGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const globalData = inject(GlobalDataService);

  const sessionData: SessionData = globalData.getSessionData().value;

  if (
    sessionData &&
    sessionData.token &&
    (sessionData.userType == UserType.ActiveDirectory ||
      sessionData.userType == UserType.AgillicAdvisor)
  ) {
    return true;
  } else {
    router.navigate(['']);
    return false;
  }
};
