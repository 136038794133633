@if (template) {
<div class="d-flex flex-column gap-4">
  <div class="card-container card-shadow">
    @if (template.availableImages.length > 0) {
    <div class="card-img-container">
      @for (imageUrl of template.availableImages; track $index) {
      <img src="{{ imageUrl }}" class="card-img-main" alt="card image" />
      }
    </div>
    } @else {
    <div class="card-img-container dummy-bg"></div>
    }

    <div class="card-body">
      <h5 class="card-title">{{ template.category | templateCategory }}</h5>
      <h4 class="card-heading">{{ template.templateLabel }}</h4>
      <p class="brand">Brand: {{ template.brand?.name }}</p>
      <div class="description">
        <p>{{ template.description }}</p>
        <p>{{ template.targetGroupDescription }}</p>
      </div>
    </div>
  </div>


  <div class="w-100">
    <filled-button [buttonText]="buttonText" className="card-btn" (childEvent)="onButtonClick()"></filled-button>
  </div>

</div>
}
