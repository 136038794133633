<div class="form-field-container d-flex flex-column {{ className }}" [formGroup]="formGroup" [ngStyle]="{
    '--border-color': control.touched && control.invalid ? 'red' : '#afcbe0'
  }">
  <label *ngIf="label !== ''" class="{{ labelClass }}">{{ label }}</label>
  <mat-form-field appearance="outline" class="position-relative">
    <input type="text" matInput [formControlName]="controlName" [matAutocomplete]="auto" required placeholder="{{ placeholder }}" />
    @if (icon) {
    <mat-icon mat-suffix class="position-absolute icon">
      <img src="../../../../assets/accordion_icon.svg" alt="icon" class="position-absolute icon-img" />
    </mat-icon>
    }
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" [displayWith]="displayBrandName">
      <mat-option *ngFor="let brand of filteredBrands | async" [value]="brand">
        {{ brand.name }}
      </mat-option>
    </mat-autocomplete>
    @if (control.touched && control.invalid) {
    <div class="position-absolute form-validation-error-container">
      @if (control.errors?.['required']) {
      <small>Dette felt er påkrævet.</small>
      } @if (control.errors?.['minlength']) {
      <small>Minimum length is 3 characters.</small>
      } @if (control.errors?.['email']) {
      <small>Must be an email.</small>
      }
    </div>
    }
  </mat-form-field>
</div>
