import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FilledButtonComponent } from '../../atoms/filled-button/filled-button.component';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateFnsModule } from 'ngx-date-fns';
import { SendoutStatusPipe } from '../../../pipes/SendoutStatus.pipe';

@Component({
  selector: 'app-generic-card',
  standalone: true,
  imports: [
    FilledButtonComponent,
    CommonModule,
    MatTooltipModule,
    DateFnsModule,
    SendoutStatusPipe,
  ],
  templateUrl: './generic-card.component.html',
  styleUrl: './generic-card.component.css',
})
export class GenericCardComponent implements OnInit {
  @Input() imageUrl?: string;
  @Input() overline?: string;
  @Input() title?: string;
  @Input() description?: string;
  @Input() items?: { term: string; value: string }[];
  @Input() footer?: string;
  @Input() buttonText?: string;
  @Input() borderColor?: string;

  @Output() buttonClick = new EventEmitter<void>();

  onButtonClick() {
    this.buttonClick.emit();
  }
  ngOnInit(): void {}
}
