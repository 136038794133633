<div class="d-flex flex-column gap-4" [ngStyle]="{ '--border-color': borderColor ? borderColor:'transparent' }">
  <div class="card-container card-shadow">
    @if (imageUrl) {
    <div class="card-img-container">
      <img src="{{ imageUrl }}" class="card-img-main" alt="card image" />
    </div>
    } @else {
    <div class="card-img-container dummy-bg"></div>
    }

    <div class="card-body d-flex flex-column gap-2">
      @if (overline) {
      <h5 class="card-title">{{ overline }}</h5>
      }

      <h4 class="card-heading overflow-hidden two-line-truncate" [matTooltip]="title && title.length > 35 ? title : ''" [matTooltipPosition]="'above'">
        {{ title }}
      </h4>
      @if (description) {
      <p class="description">{{ description }}</p>
      }
      @if (items) {
      <div class="d-flex flex-column">
        @for (item of items; track $index) {
        <div class="item"><strong>{{ item.term }}</strong>: {{ item.value }}</div>
        }
      </div>
      }
      @if (footer){
      <div style="flex: 1 1 auto;"></div>
      <p class="footer">{{ footer }}</p>
      }
    </div>
  </div>

  @if (buttonText) {
  <div class="w-100">
    <filled-button [buttonText]="buttonText" className="card-btn" (childEvent)="onButtonClick()"></filled-button>
  </div>
  }
</div>
