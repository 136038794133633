<div class="custom-pt h-100">
  <app-main-heading-subtext headingText="Overblik" subText="Denne side giver dig et overblik over dine kommende og tidligere udsendelser samt planlagte events. Brug kalenderen for at holde styr på vigtige datoer og se detaljerede informationer om dine aktiviteter." extraClasses="activity-margin"></app-main-heading-subtext>
  @if (isLoading) {
  <app-skeleton></app-skeleton>
  } @else {
  <div class="d-flex flex-column w-100 events-container">
    <div class="d-flex flex-column flex-lg-row w-100 events-inner-container">
      <div class="w-md-25">
        <div>
          <app-upcomming-broadcasts mainHeading="Udsendelser" [sendouts]="segregatedSendouts" (buttonClick)="sendoutDateClick($event)"></app-upcomming-broadcasts>
        </div>
      </div>
      <div class="w-md-75">
        <app-broadcast-events mainHeading="Events" [events]="eventsWithSendoutDate" (buttonClick)="sendoutDateClick($event)"></app-broadcast-events>
      </div>
    </div>
    <app-full-calendar [events]="onlyEvents" [sendouts]="allSendouts"></app-full-calendar>
  </div>
  }
</div>
