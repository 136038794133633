<div class="d-flex flex-column w-100 justify-content-center align-items-center header">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '200px', height: '30px', marginBottom: '0px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '400px', height: '10px', marginBottom: '0px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '400px', height: '10px', marginBottom: '0px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '400px', height: '10px', marginBottom: '0px' }"></ngx-skeleton-loader>
</div>

<ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '350px', height: '40px' }"></ngx-skeleton-loader>

<div class="w-100 d-flex justify-content-between filter">
  <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '40px', height: '40px' }"></ngx-skeleton-loader>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '150px', height: '40px', margin: '0px 30px 0px 0px' }"></ngx-skeleton-loader>
</div>

<ngx-skeleton-loader count="4" appearance="line" [theme]="{ width: '100%', height: '50px' }"></ngx-skeleton-loader>