import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  GlobalDataService,
  SessionData,
} from './global-data/global-data.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MainService implements OnDestroy {
  private apiURL: string = environment.apiURL;

  private sessionData: SessionData | null = null;
  private sessionDataSubscription?: Subscription;

  constructor(private globalData: GlobalDataService) {
    this.sessionDataSubscription = this.globalData
      .getSessionData()
      .subscribe((sessionData) => {
        this.sessionData = sessionData;
      });
  }

  ngOnDestroy(): void {
    this.sessionDataSubscription?.unsubscribe();
  }

  public getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.sessionData ? this.sessionData.token : '',
    });
  }

  public getApiURL(): string {
    return this.apiURL;
  }
}
