<div class="outer-container">
  <div class="inner-container">
    <div class="icon udsendelse-icon-bg"></div>
    <span class="icon-text">Udsendelse</span>
  </div>

  <div class="inner-container">
    <div class="icon event-icon-bg"></div>
    <span class="icon-text">Event</span>
  </div>

  <div class="inner-container">
    <img
      src="../../../../assets/last_period_calendar.png"
      alt="calendar guide image icon"
    />
    <span class="icon-text">Låst periode</span>
  </div>
</div>
