import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NavLogoComponent } from '../../atoms/nav-logo/nav-logo.component';
import { NavlinkComponent } from '../../atoms/navlink/navlink.component';
import { NavlinkToggleBtnComponent } from '../../atoms/navlink-toggle-btn/navlink-toggle-btn.component';
import { MatButtonModule } from '@angular/material/button';
import { FilledButtonComponent } from '../../atoms/filled-button/filled-button.component';
import { StrokedButtonComponent } from '../../atoms/stroked-button/stroked-button.component';
import { StoreImpersonationDropdownComponent } from '../store-impersonation-dropdown/store-impersonation-dropdown.component';
import { Store, UserType } from '../../../types';
import {
  GlobalDataService,
  SessionData,
} from '../../../services/global-data/global-data.service';
import { Router } from '@angular/router';
import { FilterDirectiveDirective } from '../../atoms/filter-directive/filter-directive.directive';
import { AsyncPipe } from '@angular/common';
import { BehaviorSubject, Subscription } from 'rxjs';

export interface NavLinkProps {
  id: number;
  routerLinkName: string;
  linkText: string;
}

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    NavLogoComponent,
    NavlinkComponent,
    NavlinkToggleBtnComponent,
    MatButtonModule,
    FilledButtonComponent,
    StrokedButtonComponent,
    StoreImpersonationDropdownComponent,
    FilterDirectiveDirective,
    AsyncPipe,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css',
})
export class MenuComponent implements OnInit, OnDestroy {
  UserType = UserType;

  sessionData$: BehaviorSubject<SessionData> =
    this.globalDataService.getSessionData();

  sessionDataSubscription?: Subscription;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private globalDataService: GlobalDataService,
    private router: Router
  ) {}

  showMenu: boolean = false;

  isLoading: boolean = false;
  showDropDown: boolean = false;

  navlinksFiltered: NavLinkProps[] = [
    {
      id: 1,
      routerLinkName: 'overview',
      linkText: 'Overblik',
    },
    {
      id: 2,
      routerLinkName: 'sendouts',
      linkText: 'Udsendelser',
    },
    {
      id: 3,
      routerLinkName: 'templates',
      linkText: 'Skabeloner',
    },
  ];

  navLinksData: NavLinkProps[] = [];

  ngOnInit() {
    this.navLinksData = this.navlinksFiltered;
    this.sessionDataSubscription = this.sessionData$.subscribe(
      (sessionData) => {
        this.updateNavLinks(sessionData);
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  ngOnDestroy(): void {
    this.sessionDataSubscription?.unsubscribe();
  }

  updateNavLinks(sessionData: SessionData) {
    if (
      sessionData.userType == UserType.ActiveDirectory &&
      !sessionData.storeImpersonation
    ) {
      this.navLinksData = this.navlinksFiltered.filter((link) => link.id !== 1);
    } else {
      this.navLinksData = this.navlinksFiltered.filter((link) => link.id !== 3);
    }
  }

  toggleShowDropDown() {
    this.showDropDown = !this.showDropDown;
  }

  onOutsideClick() {
    this.showDropDown = false;
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  disableMenu() {
    this.showMenu = false;
  }

  logout() {
    sessionStorage.removeItem('msalHandleRedirect');
    this.globalDataService.clearSessionData();
    this.router.navigate(['']);
  }

  storeClickForImpersonation(store: Store) {
    this.globalDataService.startStoreImpersonation(store);
    this.showDropDown = false;
    this.router.navigate(['overview']);
  }
}
