import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-stroked-button',
  standalone: true,
  imports: [RouterLink, MatButtonModule],
  templateUrl: './stroked-button.component.html',
  styleUrl: './stroked-button.component.css',
})
export class StrokedButtonComponent {
  @Input() routerLinkName: string = '';
  @Input() buttonText: string = '';
  @Input() className: string = '';
  @Input() disabled: boolean = false;
  @Output() navLinkClicked = new EventEmitter<void>();

  handleClick() {
    this.navLinkClicked.emit();
  }
}
