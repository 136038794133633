import { TemplateCategory } from '../types';

export const templateCategoryGrouping = [
  {
    id: TemplateCategory.Brand,
    label: 'Brand',
  },
  {
    id: TemplateCategory.Consultation,
    label: 'Konsultation',
  },
  {
    id: TemplateCategory.Activities,
    label: 'Aktiviteter',
  },
  {
    id: TemplateCategory.Seasonal,
    label: 'Sæson',
  },
  {
    id: TemplateCategory.Other,
    label: 'Andet',
  },
];
