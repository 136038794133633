import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-img-grid',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, MatFormFieldModule],
  templateUrl: './img-grid.component.html',
  styleUrl: './img-grid.component.css',
})
export class ImgGridComponent {
  @Input() formGroup!: FormGroup;
  @Input() controlName: string = '';
  @Input() templateImages: string[] = [];
  @Output() imageSelected = new EventEmitter<string>();
  get control(): FormControl {
    return this.formGroup.get(this.controlName) as FormControl;
  }

  selectImage(image: string) {
    this.control.setValue(image);
    this.control.markAsDirty();
    this.imageSelected.emit(image);
  }
}
