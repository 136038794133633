import { Pipe, PipeTransform } from '@angular/core';
import {
  TemplateCategory,
  TemplateCategoryEnum,
  TemplateStatus,
  TemplateStatusEnum,
} from '../types';

@Pipe({
  name: 'templateCategory',
  standalone: true,
})
export class TemplateCategoryPipe implements PipeTransform {
  transform(value: TemplateCategoryEnum, mode: 'label' = 'label'): string {
    if (mode === 'label') {
      return this.getLabel(value);
    }
    return String(value);
  }
  private getLabel(value: TemplateCategoryEnum): string {
    switch (value) {
      case TemplateCategory.Brand:
        return 'Brand';
        break;
      case TemplateCategory.Consultation:
        return 'Konsultation';
        break;
      case TemplateCategory.Seasonal:
        return 'Sæson';
        break;
      case TemplateCategory.Activities:
        return 'Aktiviteter';
        break;
      case TemplateCategory.Other:
        return 'Andet';
        break;
      default:
        return value;
        break;
    }
  }
}
