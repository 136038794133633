<div class="custom-pt custom-container">
  <app-main-heading-subtext headingText="{{ template && template.id ? 'Redigér skabelon' : 'Opret skabelon' }}" subText="På denne side kan du redigere alle elementer af skabelonen, herunder titlen og skabelonens indhold, såsom hvilke felter det skal være mulige for butikkerne at redigere." extraClasses="activity-margin"></app-main-heading-subtext>

  <div class="form-container">
    <app-main-heading extraClasses="create-template" headingText="Generel information"></app-main-heading>

    <form [formGroup]="userForm" style="padding-right: 1em;">
      <app-form-field labelClass="create-template-label" label="Internt navn" placeholder="Hvad er skabelonens interne navn?" [icon]="false" [formGroup]="userForm" controlName="templateName"></app-form-field>

      <app-form-field labelClass="create-template-label" label="Titel" placeholder="Hvad er skabelonens titel?" [icon]="false" [formGroup]="userForm" controlName="templateLabel"></app-form-field>

      <app-text-area labelClass="create-template-label" label="Beskrivelse " placeholder="Hvad skal skabelonen bruges til?" [formGroup]="userForm" controlName="description"></app-text-area>

      <app-form-field labelClass="create-template-label" label="Målgruppe" placeholder="Hvad er skabelonens målgruppe?" [icon]="false" [formGroup]="userForm" controlName="targetGroupDescription"></app-form-field>

      <app-brand-field label="Brand" labelClass="create-template-label" placeholder="Hvilket brand er skabelonen relevant for?" [icon]="true" [formGroup]="userForm" controlName="brand"></app-brand-field>

      <app-radio-field labelClass="radio-label" labelText="Event/personlig aftale " subTextClass="subtext-label" subText="Kræver skabelonen altid et event/en personlig aftale?" [formGroup]="userForm" controlName="eventRequired"></app-radio-field>

      <app-select label="Kategori" labelClass="select-label" placeholder="Hvad er skabelons kategori?" [option]="categoryOption" [formGroup]="userForm" controlName="category"></app-select>

      <app-main-heading extraClasses="create-template" headingText="Skabelonens indhold"></app-main-heading>

      <app-form-field labelClass="create-template-label" label="Emnelinje" placeholder="Hvad er skabelonens emnelinje?" [icon]="false" [formGroup]="userForm" controlName="defaultContentSubjectLine"></app-form-field>

      <app-radio-field subTextClass="subtext-label" subText="Tillad, at butik redigerer emnelinjen?" [formGroup]="userForm" controlName="allowOverrideSubjectLine"></app-radio-field>

      <app-form-field labelClass="create-template-label" label="Preheader" placeholder="Hvad er skabelonens preheader?" [icon]="false" [formGroup]="userForm" controlName="defaultContentPreheader"></app-form-field>

      <app-radio-field subTextClass="subtext-label" subText="Tillad, at butik ændrer preheaderen?" [formGroup]="userForm" controlName="allowOverridePreheader"></app-radio-field>

      <app-form-field labelClass="create-template-label" label="Overskrift i udsendelsen" placeholder="Hvad er overskriften for skabelonen?" [icon]="false" [formGroup]="userForm" controlName="defaultContentHeadline"></app-form-field>

      <app-radio-field subTextClass="subtext-label" subText="Tillad, at butik ændrer overskriften?" [formGroup]="userForm" controlName="allowOverrideHeadline"></app-radio-field>

      <app-text-editor label="Brødtekst i udsendelsen" placeholder="Hvad er skabelonens brødtekst?" [MAX_LENGTH]="1000" [showLimit]="true" labelClass="labels" editorClass="default-editor-class w-100" mainClass="position-relative d-flex flex-column editor-container" [formGroup]="userForm" controlName="defaultContentBodyCopy"></app-text-editor>

      <app-radio-field subTextClass="subtext-label" subText="Tillad, at butik ændrer brødteksten?" [formGroup]="userForm" controlName="allowOverrideBodyCopy"></app-radio-field>

      <app-form-field labelClass="create-template-label" label="CTA tekst i udsendelsen" placeholder="Hvad er skabelonens CTA tekst?" [icon]="false" [formGroup]="userForm" controlName="defaultContentCtaText"></app-form-field>

      <app-radio-field subTextClass="subtext-label" subText="Tillad, at butik ændrer CTA-teksten?" [formGroup]="userForm" controlName="allowOverrideCtaText"></app-radio-field>

      <app-form-field labelClass="create-template-label" label="CTA URL i udsendelsen" placeholder="Hvad er skabelonens CTA URL?" [icon]="false" [formGroup]="userForm" controlName="defaultContentCtaUrl"></app-form-field>

      <app-radio-field subTextClass="subtext-label" subText="Tillad, at butik ændrer CTA-URLen?" [formGroup]="userForm" controlName="allowOverrideCtaUrl"></app-radio-field>


      <app-text-editor label="Ansvarsfraskrivelse i udsendelsen" placeholder="Hvad er ansvarsfraskrivelsen for skabelonens indhold?" [MAX_LENGTH]="1000" [showLimit]="true" labelClass="labels" editorClass="default-editor-class w-100" mainClass="position-relative d-flex flex-column editor-container" [formGroup]="userForm" controlName="defaultContentDisclaimer"></app-text-editor>


      <app-radio-field subTextClass="subtext-label" subText="Tillad, at butik ændrer ansvarsfraskrivelsen?" [formGroup]="userForm" controlName="allowOverrideDisclaimer"></app-radio-field>

      <app-text-area style="width:100%" labelClass="create-template-label" label="Tilgængelige billeder" placeholder="Hvilke billeder er tilgængelige for skabelonen?" hint="En URL per linie" [icon]="false" [formGroup]="userForm" local controlName="availableImages"></app-text-area>


    </form>
  </div>

  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center gap-4">
      @if (template && template.id) {
      <!-- unpublish -->
      @if (template.status === TemplateStatus.Published && !userForm.dirty) {
      <filled-button buttonText="Afpublicer" className="unpublish-template" (childEvent)="saveTemplate(TemplateStatus.Unpublished)"></filled-button>
      }
      <!-- delete -->
      <app-stroked-button buttonText="Slet skabelon" (navLinkClicked)="openDialog(template.id)"></app-stroked-button>
      }
    </div>

    <div class="d-flex align-items-center gap-4" style="margin-right: 1rem;">
      <filled-button buttonText="Gem skabelon" [disabled]="!userForm.valid || !userForm.dirty" (childEvent)="saveTemplate()"></filled-button>
      @if (template && (template.status == TemplateStatus.PendingAgillicImplementation || template.status == TemplateStatus.Unpublished)) {
      @if (userForm.dirty) {
      <filled-button buttonText="Gem & Publicer" [disabled]="!userForm.valid" (childEvent)="saveTemplate(TemplateStatus.Published)"></filled-button>
      } @else {
      <filled-button buttonText="Publicer" (childEvent)="saveTemplate(TemplateStatus.Published)"></filled-button>
      }
      }
    </div>
  </div>
</div>
