<h4 class="heading-main">{{ mainHeading }}</h4>

<div class="w-md-75 shadow position-relative outer-container">

  @if (events.length > 3) {
  <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" mat-fab extended class="carosuel-shadow position-absolute common left-btn">
    <img src="../../../../assets/left_arrow.svg" alt="carousel button" />
  </button>

  <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" mat-fab extended class="carosuel-shadow position-absolute common right-btn">
    <img src="../../../../assets/right_arrow.svg" alt="carousel button" />
  </button>
  }
  @if (events.length > 2) {
  <div id="carouselExampleControls" class="carousel carousel-dark slide carosuel-container">
    <div class="carousel-inner">
      @if (eventBatches.length === 0) {
      <div class="empty-state-label">Ingen events</div>
      } @for (batch of eventBatches; track batch.id) {
      <div class="carousel-item" [class.active]="batch.id === 1">
        <div class="row padd-left">
          @for (event of batch.batch; track event.id) {
          <div class="col">
            <a [href]="event.link" target="_blank" class="no-style-link">
              <app-card [date]="event.startsAt" imgSrc="{{ event.imageUrl }}" cardTitle="Event" cardHeading="{{ event.name }}" [variant]="event.sendouts" (sendoutDateClick)="sendoutDateClicked($event)"></app-card>
            </a>
          </div>
          }
        </div>
      </div>
      }
    </div>
  </div>
  } @else {
  <div class="h-100 w-100 d-flex justify-content-center align-items-center">
    <div class="d-flex align-items-center event-cards-container">
      @if (eventBatches.length === 0) {
      <div class="empty-state-label">Ingen events</div>
      }

      <div class="d-flex justify-content-center w-100 gap-24">
        @for (batch of eventBatches; track batch.id) { @for (event of batch.batch;
        track event.id) {
        <a [href]="event.link" target="_blank" class="no-style-link">
          <app-card [date]="event.startsAt" imgSrc="{{ event.imageUrl }}" cardTitle="Event" cardHeading="{{ event.name }}" [variant]="event.sendouts" (sendoutDateClick)="sendoutDateClicked($event)"></app-card>
        </a>
        } }
      </div>
    </div>
  </div>
  }
</div>
