import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-navlink',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, MatButtonModule],
  templateUrl: './navlink.component.html',
  styleUrl: './navlink.component.css',
})
export class NavlinkComponent implements OnInit {
  @Input() routerLinkName: string = '';
  @Input() routerLinkActiveName: string = '';
  @Input() extraClasses: string = '';
  @Input() linkText: string = '';

  @Output() navLinkClicked = new EventEmitter<void>();

  public currentRoute: string = '';

  constructor(private route: ActivatedRoute, private router: Router) {}
  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event.constructor.name === 'NavigationEnd') {
        this.currentRoute = this.router.url;
      }
    });
  }

  handleClick() {
    this.navLinkClicked.emit();
  }
}
