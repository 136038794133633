import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function ctaUrlValidator(
  control: AbstractControl
): ValidationErrors | null {
  const strValue = control.value as string;

  // Check for null or empty value
  if (strValue == null || strValue == '') {
    return null;
  }

  // Check for placeholder value
  if (strValue.toLowerCase() == '{{event url}}') {
    return null;
  }

  // Check for http or https url
  if (strValue.match(/^(http|https):\/\/[^ "]+$/i)) {
    return null;
  }

  return {
    ctaUrl: control.value,
  };
}
