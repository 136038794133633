import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MainHeadingSubtextComponent } from '../../atoms/main-heading-subtext/main-heading-subtext.component';
import { MainHeadingComponent } from '../../atoms/main-heading/main-heading.component';
import { FormFieldComponent } from '../../atoms/form-field/form-field.component';
import { RadioFieldComponent } from '../../atoms/radio-field/radio-field.component';
import { FilledButtonComponent } from '../../atoms/filled-button/filled-button.component';
import { TextAreaComponent } from '../../atoms/text-area/text-area.component';
import { SearchFieldComponent } from '../../atoms/search-field/search-field.component';
import { FilterComponent } from '../../molecules/filter/filter.component';
import { SelectFieldComponent } from '../../atoms/select-field/select-field.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FormGroup, Validators } from '@angular/forms';
import { TemplateService } from '../../../services/template/template.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { StrokedButtonComponent } from '../../atoms/stroked-button/stroked-button.component';
import { DialogService } from '../../../services/dialog/dialog.service';
import {
  Brand,
  Filter,
  PostTemplate,
  TemplateCategory,
  TemplateStatus,
  TemplateStatusEnum,
} from '../../../types';
import { BrandFieldComponent } from '../../atoms/brand-field/brand-field.component';
import { Template, UpdateTemplate, Option, Options } from '../../../types';
import { BrandsService } from '../../../services/brands/brands.service';
import { ctaUrlValidator } from '../../../validation/cta-url.validator';
import { TextEditorComponent } from '../../atoms/text-editor/text-editor.component';
import { map, mergeMap, of, Subscription } from 'rxjs';
import {
  GlobalDataService,
  SessionData,
} from '../../../services/global-data/global-data.service';
import { templateInternalNameValidator } from '../../../validation/template-internal-name.validator';

enum TemplateEditorMode {
  Create = 'CREATE',
  Edit = 'EDIT',
}

type TemplateFormValues = Omit<
  Template,
  | 'id'
  | 'createdBy'
  | 'createdAt'
  | 'updatedBy'
  | 'updatedAt'
  | 'status'
  | 'isDeleted'
  | 'availableImages'
  | 'brandId'
> & {
  brand?: Brand;
  availableImages: string;
};

@Component({
  selector: 'app-template',
  standalone: true,
  imports: [
    MainHeadingSubtextComponent,
    MainHeadingComponent,
    FormFieldComponent,
    RadioFieldComponent,
    FilledButtonComponent,
    TextAreaComponent,
    SearchFieldComponent,
    FilterComponent,
    SelectFieldComponent,
    ReactiveFormsModule,
    StrokedButtonComponent,
    BrandFieldComponent,
    TextEditorComponent,
  ],
  templateUrl: './template.component.html',
  styleUrl: './template.component.css',
})
export class TemplateComponent implements OnInit, OnDestroy {
  TemplateStatus = TemplateStatus;
  TemplateEditorMode = TemplateEditorMode;

  templateEditorMode = TemplateEditorMode.Edit;

  sessionDataSubscription?: Subscription;
  sessionData?: SessionData;

  template: Template | null = null;

  userForm: FormGroup;

  categoryOption: Options[] = [
    { value: TemplateCategory.Brand, viewValue: 'Brand' },
    { value: TemplateCategory.Consultation, viewValue: 'Konsultation' },
    { value: TemplateCategory.Seasonal, viewValue: 'Sæson' },
    { value: TemplateCategory.Activities, viewValue: 'Aktiviteter' },
    { value: TemplateCategory.Other, viewValue: 'Andet' },
  ];

  templateFilters: Filter[] = [
    {
      id: 1,
      text: 'Brand',
      classes: 'template-filter-text',
      checked: true,
    },
    {
      id: 2,
      text: 'Sæson',
      classes: 'template-filter-text',
      checked: true,
    },
    {
      id: 3,
      text: 'Andre',
      classes: 'template-filter-text',
      checked: true,
    },
    {
      id: 4,
      text: '...',
      classes: 'template-filter-text',
      checked: true,
    },
  ];

  constructor(
    private templateService: TemplateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private globalDataService: GlobalDataService
  ) {
    this.userForm = new FormGroup({
      templateName: new FormControl('', [templateInternalNameValidator]),
      templateLabel: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      targetGroupDescription: new FormControl('', [Validators.required]),
      brand: new FormControl('', [Validators.required]),
      category: new FormControl('', [Validators.required]),
      eventRequired: new FormControl(null, [Validators.required]),
      defaultContentSubjectLine: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      allowOverrideSubjectLine: new FormControl(null, [Validators.required]),
      defaultContentPreheader: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      allowOverridePreheader: new FormControl(null, [Validators.required]),
      defaultContentHeadline: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      allowOverrideHeadline: new FormControl(null, [Validators.required]),
      defaultContentBodyCopy: new FormControl('', []),
      allowOverrideBodyCopy: new FormControl(null, [Validators.required]),
      defaultContentCtaText: new FormControl('', []),
      allowOverrideCtaText: new FormControl(null, [Validators.required]),
      defaultContentCtaUrl: new FormControl('', [ctaUrlValidator]),
      allowOverrideCtaUrl: new FormControl(null, [Validators.required]),
      defaultContentDisclaimer: new FormControl('', []),
      allowOverrideDisclaimer: new FormControl('', [Validators.required]),
      availableImages: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.sessionDataSubscription = this.globalDataService
      .getSessionData()
      .subscribe({
        next: (data) => {
          this.sessionData = data;
        },
      });

    this.route.paramMap
      .pipe(
        map((params) => params.get('id')),
        mergeMap((id) => {
          if (id == null) {
            // Throw an error
            throw new Error("Template ID doesn't exist");
          } else if (id === 'create') {
            this.templateEditorMode = TemplateEditorMode.Create;
            return of(null);
          } else {
            this.templateEditorMode = TemplateEditorMode.Edit;
            return this.templateService.getTemplateById(Number(id));
          }
        })
      )
      .subscribe({
        next: (template) => {
          if (template != null) {
            this.template = template;
            this.setFormValues({
              ...template,
              availableImages: template.availableImages.join('\n'),
            });
          } else {
            this.setFormValues(this.getDefaultFormValues());
          }
        },
        error: (error) => {},
      });
  }

  ngOnDestroy(): void {
    this.sessionDataSubscription?.unsubscribe();
  }

  filterBrandById(brands: Brand[], brandId: number): Brand {
    return brands.find((brand) => brand.id === brandId) as Brand;
  }

  setFormValues(values: TemplateFormValues): void {
    this.userForm.patchValue({
      ...values,
    });
  }

  createTemplate = (template: PostTemplate) => {
    this.templateService.createTemplate(template).subscribe({
      next: (response) => {},
      error: (error) => {
        console.error('Error fetching templates', error);
        this.snackbarService.show('Skabelonen kunne ikke oprettes');
      },

      complete: () => {
        this.snackbarService.show('Skabelonen er oprettet');
        this.router.navigate(['templates']);
      },
    });
  };

  updateTemplate = (template: UpdateTemplate) => {
    this.templateService.updateTemplate(template).subscribe({
      next: (response) => {},
      error: (error) => {
        console.error('Error fetching templates', error);
        this.snackbarService.show('Skabelonen kunne ikke opdateres');
      },
      complete: () => {
        this.snackbarService.show('Skabelonen er opdateret');
        this.router.navigate(['templates']);
      },
    });
  };

  getDefaultFormValues(): TemplateFormValues {
    return {
      category: TemplateCategory.Other,
      templateName: '',
      templateLabel: '',
      description: '',
      brand: {
        id: 1102164,
        name: 'Matas',
        description: '',
        isActive: true,
      },
      targetGroupDescription: '',
      eventRequired: false,
      storeAvailability: '',
      defaultContentSubjectLine: '',
      allowOverrideSubjectLine: true,
      defaultContentPreheader: '',
      allowOverridePreheader: false,
      defaultContentHeadline: '',
      allowOverrideHeadline: true,
      allowOverrideBodyCopy: false,
      defaultContentBodyCopy: '',
      defaultContentCtaText: '',
      allowOverrideCtaText: false,
      defaultContentCtaUrl: '',
      allowOverrideCtaUrl: false,
      availableImages: '',
      defaultContentDisclaimer: '',
      allowOverrideDisclaimer: false,
    };
  }

  deleteById = (id: number) => {
    this.templateService.deleteTemplateById(id).subscribe({
      next: () => {},
      error: (error) => {
        console.error('Error fetching templates', error);
      },
      complete: () => {
        this.templateService.notifyTemplatesRefresh();
        this.snackbarService.show('Skabelonen er slettet');
        this.changeDetectorRef.detectChanges();
        this.router.navigate(['templates']);
      },
    });
  };

  openDialog(id: number) {
    this.dialogService.openDialog(
      'Slet skabelon',
      'Er du sikker på, at du vil slette denne skabelon?',
      'Slet',
      () => this.deleteById(id),
      undefined,
      '347px',
      'content-container',
      '',
      '',
      'dialog-actions'
    );
  }

  serializeAvailableImages(input: string): string[] {
    return input
      .split('\n')
      .map((image: string) => image.trim())
      .filter((image: string) => image !== '');
  }

  saveTemplate(newStatus?: TemplateStatusEnum) {
    if (!this.sessionData) {
      throw new Error("Session data doesn't exist");
    }

    if (this.userForm.valid) {
      if (this.template !== null) {
        // Existing template
        const updateTemplateBody: UpdateTemplate = {
          id: this.template.id,
          updatedBy: this.sessionData?.userId,
          status: newStatus ?? this.template.status, // If a new status is provided, use that, otherwise use the existing status
          templateName: this.userForm.value.templateName,
          templateLabel: this.userForm.value.templateLabel,
          description: this.userForm.value.description,
          targetGroupDescription: this.userForm.value.targetGroupDescription,
          brandId: this.userForm.value.brand
            ? this.userForm.value.brand.id
            : null,
          category: this.userForm.value.category,
          storeAvailability: 'all',
          eventRequired: this.userForm.value.eventRequired,
          defaultContentSubjectLine:
            this.userForm.value.defaultContentSubjectLine,
          defaultContentPreheader: this.userForm.value.defaultContentPreheader,
          defaultContentHeadline: this.userForm.value.defaultContentHeadline,
          defaultContentBodyCopy: this.userForm.value.defaultContentBodyCopy,
          defaultContentCtaText: this.userForm.value.defaultContentCtaText,
          defaultContentCtaUrl: this.userForm.value.defaultContentCtaUrl,
          defaultContentDisclaimer:
            this.userForm.value.defaultContentDisclaimer,
          availableImages: JSON.stringify(
            this.userForm.value.availableImages
              .toString()
              .split(/,|\r\n|\n|\r/)
              .map((s: string) => s.trim())
              .filter((s: string) => s !== '')
          ),
          allowOverrideSubjectLine:
            this.userForm.value.allowOverrideSubjectLine,
          allowOverridePreheader: this.userForm.value.allowOverridePreheader,
          allowOverrideHeadline: this.userForm.value.allowOverrideHeadline,
          allowOverrideBodyCopy: this.userForm.value.allowOverrideBodyCopy,
          allowOverrideCtaText: this.userForm.value.allowOverrideCtaText,
          allowOverrideCtaUrl: this.userForm.value.allowOverrideCtaUrl,
          allowOverrideDisclaimer: this.userForm.value.allowOverrideDisclaimer,
        };
        this.updateTemplate(updateTemplateBody);
      } else if (this.template === null) {
        // New template
        const newTemplate: PostTemplate = {
          updatedBy: this.sessionData.userId,
          createdBy: this.sessionData.userId,
          status: TemplateStatus.PendingAgillicImplementation,
          templateName: this.userForm.value.templateName,
          templateLabel: this.userForm.value.templateLabel,
          description: this.userForm.value.description,
          targetGroupDescription: this.userForm.value.targetGroupDescription,
          brandId: this.userForm.value.brand.id || null,
          category: this.userForm.value.category,
          storeAvailability: 'all',
          eventRequired: this.userForm.value.eventRequired,
          defaultContentSubjectLine:
            this.userForm.value.defaultContentSubjectLine,
          defaultContentPreheader: this.userForm.value.defaultContentPreheader,
          defaultContentHeadline: this.userForm.value.defaultContentHeadline,
          defaultContentBodyCopy: this.userForm.value.defaultContentBodyCopy,
          defaultContentCtaText: this.userForm.value.defaultContentCtaText,
          defaultContentCtaUrl: this.userForm.value.defaultContentCtaUrl,
          defaultContentDisclaimer:
            this.userForm.value.defaultContentDisclaimer,
          availableImages: JSON.stringify(
            this.userForm.value.availableImages
              .split(/,|\r\n|\n|\r/)
              .map((s: string) => s.trim())
              .filter((s: string) => s !== '')
          ),
          allowOverrideSubjectLine:
            this.userForm.value.allowOverrideSubjectLine,
          allowOverridePreheader: this.userForm.value.allowOverridePreheader,
          allowOverrideHeadline: this.userForm.value.allowOverrideHeadline,
          allowOverrideBodyCopy: this.userForm.value.allowOverrideBodyCopy,
          allowOverrideCtaText: this.userForm.value.allowOverrideCtaText,
          allowOverrideCtaUrl: this.userForm.value.allowOverrideCtaUrl,
          allowOverrideDisclaimer: this.userForm.value.allowOverrideDisclaimer,
        };
        this.createTemplate(newTemplate);
      }
    } else if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      this.userForm.updateValueAndValidity();
    }
  }
}
