<div class="d-flex flex-column">
  <div class="d-flex align-items-center justify-content-end heading-container w-100 position-relative">
    <h4 class="heading position-absolute">Kalenderoverblik</h4>
    <filter filterText="Visning" [filterData]="templateFilters" leftRightPosition="filter-position-right" (filterChange)="onFilterChange()" margin="right"></filter>
  </div>

  <div></div>

  <div class="calendar-outer-container">
    <div class="calendar-inner-container">
      @if (displayPrevButton) {
      <span mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChanged($event)" style="cursor: pointer; padding: 0 8px;">
        <img src="../../../../assets/date_left.svg" alt="date left" />
      </span>
      } @else {
      <span style="padding: 0 8px; opacity: 0.2;">
        <img src="../../../../assets/date_left.svg" alt="date left" />
      </span>
      }
      <span class="date ">
        {{ viewDate | calendarDate : view + "ViewTitle" : "da" }}
      </span>
      @if(displayNextButton){
      <span mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChanged($event)" style="cursor: pointer;padding: 0 8px;">
        <img src="../../../../assets/date_right.svg" alt="date right" />
      </span>
      } @else {
      <span style="padding: 0 8px; opacity: 0.2;">
        <img src="../../../../assets/date_right.svg" alt="date right" />
      </span>
      }
    </div>
    <mwl-calendar-month-view [viewDate]="viewDate" [events]="calenderEvents" [locale]="locale" (dayClicked)="dayClicked($event.day)" (eventClicked)="eventClicked($event.event)" [cellTemplate]="customEventTemplate" [weekStartsOn]="1">
    </mwl-calendar-month-view>

    <ng-template #customEventTemplate let-day="day" let-openDay="openDay" let-locale="locale" let-tooltipPlacement="tooltipPlacement" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay" let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody" let-tooltipDelay="tooltipDelay" let-trackByEventId="trackByEventId" let-validateDrag="validateDrag">
      <div style="flex: 1 0 auto;display:flex;flex-direction: column;" [ngClass]="{
      'lock-period': isLockPeriodStyled(day.date),
      'sunday-cell': isSunday(day.date)
    }">
        <div class="cal-cell-top" [attr.aria-label]="
          { day: day, locale: locale } | calendarA11y : 'monthCell'
        ">
          <span aria-hidden="true">
            <span class="cal-day-badge cal-day-weeknumber" *ngIf="isMonday(day.date)">Uge {{
            getISOWeek(day.date)
          }}</span>
            <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
            day.badgeTotal
          }}</span>
            <span class="cal-day-number">{{
            day.date | calendarDate : "monthViewDayNumber" : locale
          }}</span>
          </span>
        </div>
        <div class="cal-events" *ngIf="day.events.length > 0">
          <div class="cal-event" *ngFor="let event of day.events; trackBy: trackByEventId" [ngStyle]="{ backgroundColor: event.color?.primary }" [ngClass]="event?.cssClass" (mouseenter)="highlightDay.emit({ event: event })" (mouseleave)="unhighlightDay.emit({ event: event })" [mwlCalendarTooltip]="
            event.title | calendarEventTitle : 'monthTooltip' : event
          " [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event" [tooltipTemplate]="tooltipTemplate" [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay" mwlDraggable [class.cal-draggable]="event.draggable" dragActiveClass="cal-drag-active" [dropData]="{ event: event, draggedFrom: day }" [dragAxis]="{ x: event.draggable, y: event.draggable }" [validateDrag]="validateDrag" [touchStartLongPress]="{ delay: 300, delta: 30 }" (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })">
            {{ event.title }}
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <app-calendar-helpers></app-calendar-helpers>
</div>
