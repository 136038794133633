@if (data.length === 0) {
<div class="empty-state-label">{{ emptyStateText }}</div>
} @else {
<table class="table">
  <thead>
    <tr>
      @for (column of columns; track column.header) {
      <th scope="col">
        {{ column.header }}
        @if (column.sort) {
        <img src="../../../../assets/accordion_icon.svg" alt="icon" class="img-margin cursor-pointer" (click)="sortData(column)" />
        }
      </th>
      }
    </tr>
  </thead>
  <tbody>
    @for (row of data; track row) {
    <tr (click)="handleRowClick(row)" [ngStyle]="{
        '--border-color': (row.status | sendoutStatus : 'color')
      }">
      @for (column of columns; track column.header) {
      <td>
        @if (column.field === 'store') {
        {{ row.store?.place }} <small>({{ row.store?.id }})</small>
        } @else if (column.field === "sendoutDate" || column.field === "createdAt") {
        {{ row[column.field] | dfnsFormat : "d.M.yyyy" }}
        } @else if (column.field === "contactPerson") {
        {{ row[column.field] || "" }}
        } @else if (column.field == 'status') {
        {{ row[column.field] | sendoutStatus }}
        } @else {
        {{ row[column.field] }}
        }
      </td>
      }
    </tr>
    }
  </tbody>
</table>
}
