import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { MainService } from '../main.service';
import { Brand, Event, EventType } from '../../types';
import { parseISO } from 'date-fns';

interface RawEvent {
  id: string;
  type: string;
  status: string;
  storeId: string;
  startsAt: string;
  endsAt: string;
  name: string;
  imageUrl: string;
  isDeleted: boolean | null;
  link: string | null;
  consultationId: string | null;
  bookingCategoryId?: number | null;
  bookingCategoryLabel?: string | null;
  externalPartnerBrandId?: number | null;
  externalPartnerBrand?: Brand | null;
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private http: HttpClient, private mainService: MainService) {}

  getAllEventsByStore(storeId: number): Observable<Event[]> {
    return this.http
      .get<RawEvent[]>(
        this.mainService.getApiURL() +
          `Event/${storeId === 77707 ? 14427 : storeId}`,
        {
          headers: this.mainService.getHeaders(),
        }
      )
      .pipe(
        map((events: RawEvent[]): Event[] => {
          return events.map((event: RawEvent): Event => {
            return {
              id: event.id,
              type:
                event.type == 'Event'
                  ? EventType.Event
                  : EventType.PersonalAppointment,
              status: event.status,
              storeId: event.storeId,
              startsAt: parseISO(event.startsAt),
              endsAt: parseISO(event.endsAt),
              name: event.name,
              imageUrl: event.imageUrl,
              isDeleted: event.isDeleted == true,
              link: event.link,
              consultationId: event.consultationId,
              externalPartnerBrandId: event.externalPartnerBrandId || null,
              externalPartnerBrand: event.externalPartnerBrand || null,
              bookingCategoryId: event.bookingCategoryId || null,
              bookingCategoryLabel: event.bookingCategoryLabel || null,
            };
          });
        })
      );
  }
}
