<div
  [ngClass]="
    dialogContainerClass !== '' ? dialogContainerClass : 'content-container'
  "
>
  <h2
    mat-dialog-title
    class="padd-0 marg-0"
    [ngClass]="headingClass !== '' ? headingClass : ''"
  >
    {{ heading }}
  </h2>
  <mat-dialog-content
    class="padd-0"
    [ngClass]="contentClass !== '' ? contentClass : ''"
  >
    {{ content }}
  </mat-dialog-content>
</div>
<mat-dialog-actions
  [ngClass]="
    buttonContainerClass !== '' ? buttonContainerClass : 'dialog-actions'
  "
>
  <button
    mat-button
    mat-dialog-close
    (click)="dialogRef.close()"
    class="close-btn"
  >
    Luk
  </button>
  <filled-button
    buttonText="{{ data.buttonText }}"
    (childEvent)="onButtonClick()"
    className="delete-close-btn"
  ></filled-button>
</mat-dialog-actions>
