import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { MainService } from '../main.service';
import { Store } from '../../types';
import { differenceInMinutes } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private cacheTtlMinutes = 5;
  private allStores: Store[] = [];
  private loadedAt: Date = new Date(0);
  constructor(private http: HttpClient, private mainService: MainService) {}

  getStoreById(storeId: number): Observable<Store | null> {
    return this.getAllStores().pipe(
      map((stores) => {
        return stores.find((store) => store.id === storeId) || null;
      })
    );
  }

  getAllStores(): Observable<Store[]> {
    if (differenceInMinutes(new Date(), this.loadedAt) > this.cacheTtlMinutes) {
      return this.http
        .get<Store[]>(this.mainService.getApiURL() + `Store/Stores`, {
          headers: this.mainService.getHeaders(),
        })
        .pipe(
          map((stores) => {
            const newStoreArray = stores.map((store) => {
              if (store.district == 'lukkede butikker') {
                store.isActive = false;
              }
              return store;
            });
            this.allStores = newStoreArray;
            this.loadedAt = new Date();
            return newStoreArray;
          })
        );
    } else {
      return of(this.allStores);
    }
  }
}
