import { Component } from '@angular/core';
import { GlobalDataService } from '../../../services/global-data/global-data.service';
import { Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-store-impersonation-header',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './store-impersonation-header.component.html',
  styleUrl: './store-impersonation-header.component.css',
})
export class StoreImpersonationHeaderComponent {
  sessionData$ = this.globalDataService.getSessionData();
  constructor(
    private globalDataService: GlobalDataService,
    private router: Router
  ) {}

  handleQuitImpersonation() {
    this.globalDataService.stopStoreImpersonation();
    this.router.navigate(['sendouts']);
  }
}
