import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { Filter } from '../../../types';
import { FilterDirectiveDirective } from '../../atoms/filter-directive/filter-directive.directive';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'filter',
  standalone: true,
  imports: [
    MatRadioModule,
    FormsModule,
    FilterDirectiveDirective,
    CommonModule,
    MatSlideToggleModule,
  ],
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterComponent {
  @Input() filterText: string = '';
  @Input() filterData: Filter[] = [];
  @Input() leftRightPosition: string = '';
  @Input() margin: string = '';
  @Output() filterChange = new EventEmitter<void>();
  @Output() slideToggleChange = new EventEmitter<boolean>();
  @Input() slideToggleState: boolean = true;
  @Input() showFilter = false;

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  onCheckboxChange() {
    this.filterChange.emit();
  }

  onSlideToggleChange(newState: boolean) {
    this.slideToggleChange.emit(newState);
  }

  onOutsideClick() {
    this.showFilter = false;
  }
}
