import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-main-heading',
  standalone: true,
  imports: [],
  templateUrl: './main-heading.component.html',
  styleUrl: './main-heading.component.css'
})
export class MainHeadingComponent {
  @Input() headingText: string = "";
  @Input() extraClasses: string = "";
}
