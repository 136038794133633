<div
  class="form-field-container d-flex flex-column {{ className }}"
  [formGroup]="formGroup"
  [ngStyle]="{
    '--border-color': control.touched && control.invalid ? 'red' : '#afcbe0'
  }"
>
  @if (label !== "") {
  <label class="{{ labelClass }}">{{ label }}</label>
  }
  <mat-form-field appearance="outline" class="position-relative">
    <mat-select
      [formControlName]="controlName"
      name="select"
      placeholder="{{ placeholder }}"
    >
      @for (data of option; track data) {
      <mat-option [value]="data.value">{{ data.viewValue }}</mat-option>
      }
    </mat-select>

    <img
      src="../../../../assets/accordion_icon.svg"
      alt=""
      class="position-absolute select-icon"
    />
    @if (control.touched && control.invalid) {
    <div class="position-absolute form-validation-error-container">
      @if (control.errors?.['required']) {
      <small>Dette felt er påkrævet.</small>
      } @if (control.errors?.['minlength']) {
      <small>Minimum length is 3 characters.</small>
      }
    </div>
    }
  </mat-form-field>
</div>
