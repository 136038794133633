<div class="custom-pt custom-container" [class]="{ 'custom-container-impersonation': (storeImpersonation$ | async) == true }">
  <app-main-heading-subtext headingText="Vælg skabelon" subText="På denne side skal du vælge den skabelon, der skal danne ramme for den udsendelse, som du er i gang med at oprette. Brug filtrene eller søgefeltet for nemt at sortere og finde den specifikke skabelon, som du måtte lede efter." extraClasses="activity-margin"></app-main-heading-subtext>
  <div class="d-flex align-items-center justify-content-between mb-5">
    <app-search-field placeholder="Søg skabelon" (valueChange)="applySearchFilter($event)"></app-search-field>
    <div class="custom-margin">
      <mat-slide-toggle [(ngModel)]="displayGrouped" labelPosition="before">Gruppér</mat-slide-toggle>
    </div>
  </div>

  @if (isLoading) {
  <app-skeleton></app-skeleton>
  }

  @else {
  @if (displayGrouped) {
  <div class="custom-margin">
    <mat-accordion multi>
      @for (group of accordionTemplates.groups; track group.id; let first = $first) {
      <mat-expansion-panel [expanded]="first">
        <mat-expansion-panel-header>
          <mat-panel-title class="text-capitalize">
            {{ group.label }} ({{ group.entities.length }})
          </mat-panel-title>
        </mat-expansion-panel-header>

        @if (group.entities.length == 0) {
        <div class="empty-state-label">{{ emptyStateText }}</div>
        }
        @else {

        <div class="d-flex justify-content-start flex-wrap pt-3 pb-3" style="gap: 30px;">
          @for (entity of group.entities; track $index) {
          <app-template-card buttonText="Vælg skabelon" [template]="entity" (buttonClick)="templateClick(entity)"></app-template-card>
          }
        </div>
        }
      </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
  } @else {
  <div class="d-flex justify-content-start flex-wrap pt-3 pb-3" style="padding-left: 24px; gap: 30px;">
    @for (entity of templates; track $index) {
    <app-template-card buttonText="Vælg skabelon" [template]="entity" (buttonClick)="templateClick(entity)"></app-template-card>
    }
  </div>
  }
  }
</div>
