<div class="custom-pt custom-container" [ngClass]="sessionData.storeImpersonation ? 'impersonation-height' : ''">
  @if (!isLoading && sessionData) {
  <app-main-heading-subtext headingText="Udsendelser" subText="{{
     (sessionData.userType == UserType.AgillicAdvisor || sessionData.storeImpersonation)
      ? 'Denne side giver dig et overblik over alle dine udsendelser. Dem der er gemt som kladde, afventer godkendelse, er blevet godkendt mm. Brug filtrene for nemt at sortere og finde specifikke udsendelser.'
      : 'Denne side giver dig mulighed for at administrere de enkelte udsendelser. Godkende såvel som redigere eller afvise udsendelser. Brug sektionerne til at se, hvilke udsendelser der afventer, er godkendt, afvist eller afsluttet.'
    }}" extraClasses="activity-margin"></app-main-heading-subtext>

  <div class="d-flex align-items-center justify-content-between mb-5">
    <app-search-field placeholder="Søg Udsendelser" (valueChange)="applySearchFilter($event)"></app-search-field>
    <div class="custom-margin">
      <mat-slide-toggle [(ngModel)]="slideToggleState" labelPosition="before">Gruppér</mat-slide-toggle>
    </div>
  </div>




  @if (slideToggleState) {
  <!-- <app-dynamic-accordion [columns]="columns" [dataset]="sendoutsAccordion" (clickEvent)="sendoutClick($event)" [showCards]="(sessionData.userType == UserType.AgillicAdvisor || sessionData.storeImpersonation)" [cardClass]="(sessionData.userType == UserType.AgillicAdvisor || sessionData.storeImpersonation) ? 'mb-0' : ''" [emptyStateText]="emptyStateText"></app-dynamic-accordion> -->
  <mat-accordion multi>
    @for (group of sendoutsAccordion.groups; track group.id; let first = $first) {
    <mat-expansion-panel [expanded]="first">
      <mat-expansion-panel-header>
        <mat-panel-title class="text-capitalize">
          {{ group.label }} ({{ group.entities.length }})
        </mat-panel-title>
      </mat-expansion-panel-header>

      @if (group.entities.length == 0) {
      <div class="empty-state-label">{{ emptyStateText }}</div>
      }
      @else {

      <div class="d-flex justify-content-start flex-wrap pt-3 pb-3" style="gap: 30px;">
        @for (entity of group.entities; track $index) {
        <app-generic-card buttonText="Vis udsendelse" [borderColor]="entity.status | sendoutStatus : 'color'" [imageUrl]="entity.contentImageUrl" [overline]="entity.status | sendoutStatus : 'label'" [title]="entity.sendoutName" [items]="[{term: 'Udsendelsesdato', value: entity.sendoutDate | dfnsFormat : 'd.M.yyyy'}]" [footer]="'Redigeret ' + (entity.updatedAt | dfnsFormat:'d.M.yyyy')" (buttonClick)="sendoutClick(entity)"></app-generic-card>
        }
      </div>
      }
    </mat-expansion-panel>
    }
  </mat-accordion>
  } @else {
  <div class="d-flex justify-content-start flex-wrap pt-3 pb-3" style="padding-left: 24px; gap: 30px;">
    <!-- <app-cards-group [emptyStateText]="emptyStateText" [cards]="templates" [btnText]="cardButtonText" (clickEvent)="templateClick($event)"></app-cards-group> -->

    @for (entity of sendouts; track $index) {
    <app-generic-card buttonText="Vis udsendelse" [borderColor]="entity.status | sendoutStatus : 'color'" [imageUrl]="entity.contentImageUrl" [overline]="entity.status | sendoutStatus : 'label'" [title]="entity.sendoutName" [items]="[{term: 'Udsendelsesdato', value: entity.sendoutDate | dfnsFormat : 'd.M.yyyy'}]" [footer]="'Redigeret ' + (entity.updatedAt | dfnsFormat:'d.M.yyyy')" (buttonClick)="sendoutClick(entity)"></app-generic-card>
    }


  </div>
  <!-- <app-cards-group [cardTitle]="cardTitle" [emptyStateText]="emptyStateText" [cards]="sendouts" [btnText]="cardButtonText" (clickEvent)="sendoutClick($event)"></app-cards-group> -->
  }

  }
  @else {
  <app-skeleton></app-skeleton>
  }
</div>
