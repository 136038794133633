<div class="custom-pt custom-container" [class]="{ 'custom-container-impersonation': (sessionData$ | async)?.storeImpersonation == true}">
  <app-main-heading-subtext headingText="Vælg event eller personlig aftale" subText="På denne side skal du vælge det event eller den personalige aftale, som er relevant for din udsendelse. Brug filtrene eller søgefeltet for nemt at sortere og finde det specifikke event eller den specifikke personlige aftale, som du måtte lede efter." extraClasses="activity-margin"></app-main-heading-subtext>

  <div class="d-flex align-items-center justify-content-between mb-5">
    <app-search-field placeholder="Søg event eller personlig aftale" (valueChange)="applySearchFilter($event)"></app-search-field>
    <div class="custom-margin">
      <mat-slide-toggle [(ngModel)]="slideToggleState" labelPosition="before">Gruppér</mat-slide-toggle>
    </div>
  </div>

  <div class="accordion-margin">
    @if (isLoading || template == null) {
    <p>loading...</p>
    }
    @else {
    @if (slideToggleState) {
    <mat-accordion multi>
      @for (group of allEventsAccordion.groups; track group.id; let first = $first) {
      <mat-expansion-panel [expanded]="first">
        <mat-expansion-panel-header>
          <mat-panel-title class="text-capitalize">
            {{ group.label }} ({{ group.entities.length }})
          </mat-panel-title>
        </mat-expansion-panel-header>

        @if (group.entities.length == 0) {
        <div class="empty-state-label">{{ emptyStateText }}</div>
        }
        @else {

        <div class="d-flex justify-content-start flex-wrap pt-3 pb-3" style="gap: 30px;">
          @for (entity of group.entities; track $index) {
          <app-generic-card buttonText="Vælg event" [imageUrl]="entity.imageUrl" [overline]="(entity.type | eventType) + (entity.bookingCategoryLabel ? ' - ' + entity.bookingCategoryLabel : '')" [title]="entity.name" [items]="entity.type == EventType.Event ? [{ term: 'Dato', value: entity.startsAt | dfnsFormat : 'd.M.yyyy'}] : undefined" (buttonClick)="addEventUpdateSendout(entity)"></app-generic-card>
          }
        </div>
        }
      </mat-expansion-panel>
      }
    </mat-accordion>
    } @else {
    <div class="d-flex justify-content-start flex-wrap pt-3 pb-3" style="padding-left: 24px; gap: 30px;">
      @for (entity of events; track $index) {
      <app-generic-card buttonText="Vælg event" [imageUrl]="entity.imageUrl" [overline]="(entity.type | eventType) + (entity.bookingCategoryLabel ? ' - ' + entity.bookingCategoryLabel : '')" [title]="entity.name" [items]="entity.type == EventType.Event ? [{ term: 'Dato', value: entity.startsAt | dfnsFormat : 'd.M.yyyy'}] : undefined" (buttonClick)="addEventUpdateSendout(entity)"></app-generic-card>
      }
    </div>
    }
    }
  </div>

  <div class="d-flex flex-column flex-md-row align-items-center justify-content-between margin-bottom margin-btn gap-3">
    <div style="flex: 1 0 auto;"></div>
    <div class="d-flex gap-3 flex-column flex-md-row width-100 button-container">
      @if (mode == SelectEventMode.Create && template && !template.eventRequired) {
      <filled-button buttonText="Spring over" className="back-btn margin" (childEvent)="springOverClick()"></filled-button>
      }
      @if (mode == SelectEventMode.Edit && template && !template.eventRequired) {
      <filled-button buttonText="Fortsæt uden event" className="back-btn margin" (childEvent)="saveWithoutEvent()"></filled-button>
      }
    </div>
  </div>

</div>
