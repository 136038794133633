<div class="img-grid-container position-relative" [formGroup]="formGroup" [ngStyle]="{
    '--border-color': control.touched && control.invalid ? 'red' : '#afcbe0'
  }">
  <h6 class="img-label">Billede</h6>
  <div class="img-grid">
    @for (image of templateImages; track image) {
    <div class="img-container cursor" (click)="selectImage(image)" [class.selected]="formGroup.controls['contentImageUrl'].value === image" [ngStyle]="{ 'pointer-events': control.disabled ? 'none':'auto'}">
      <img [src]="image" alt="email image" class="box-img" />
    </div>
    }
  </div>
  @if (control.touched && control.invalid) {
  <div class="position-absolute form-validation-error-container">
    @if (control.errors?.['required']) {
    <small>Dette felt er påkrævet.</small>
    } @if (control.errors?.['minlength']) {
    <small>Minimum length is 3 characters.</small>
    } @if (control.errors?.['email']) {
    <small>Must be an email.</small>
    }
  </div>
  }
</div>
