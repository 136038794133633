import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, map, Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export type MailPreviewData = {
  templateName: string;
  data: {
    CONTENT_HEADLINE?: string | null;
    CONTENT_BODY_COPY?: string | null;
    CONTENT_SUBJECT_LINE?: string | null;
    CONTENT_PREHEADER?: string | null;
    CONTENT_IMAGE_URL?: string | null;
    CONTENT_CTA_TEXT?: string | null;
    CONTENT_CTA_URL?: string | null;
    CONTENT_DISCLAIMER?: string | null;
    POINTS?: string | null;
    'BUTIK.STREETNAME'?: string | null;
    'BUTIK.CITY'?: string | null;
  };
};

@Injectable({
  providedIn: 'root',
})
export class MailPreviewService {
  private mailPreviewBaseUrl = environment.mailPreviewBaseUrl;
  private mailPreviewApiKey = environment.agillicEmailKeys;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  getHeaders() {
    return {
      'X-API-Key': this.mailPreviewApiKey,
    };
  }
  async getMailPreviewHtml(data: MailPreviewData): Promise<SafeHtml> {
    const response$ = this.http.post(
      `${this.mailPreviewBaseUrl}/api/accounts/1/render/:renderSamTemplate`,
      data,
      {
        responseType: 'text',
        headers: this.getHeaders(),
      }
    );
    const response = await firstValueFrom(response$);
    return this.sanitizer.bypassSecurityTrustHtml(response);
  }

  async sendMailPreview(to: string, data: MailPreviewData): Promise<void> {
    const response$ = this.http.post(
      `${this.mailPreviewBaseUrl}/api/accounts/1/render/:sendSamTemplate`,
      { to, ...data },
      {
        responseType: 'text',
        headers: this.getHeaders(),
      }
    );
    const response = await firstValueFrom(response$);
  }
}
