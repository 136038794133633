import { Injectable } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { GroupedDataSet, GroupedDataGroup, Event } from '../../types';
import { TemplateService } from '../template/template.service';
import { EventsService } from '../events/events.service';
import { GlobalDataService } from '../global-data/global-data.service';

@Injectable({
  providedIn: 'root',
})
export class SharedFunctionalityService {
  constructor(
    private templateService: TemplateService,
    private eventsService: EventsService,
    private globalDataService: GlobalDataService
  ) {}

  /**
   * Generic search filter function
   * @param searchValue The search query string
   * @param allItems The full list of items (can be Templates, Events, etc.)
   * @param key The list of key to search on (e.g., 'templateName' or 'storeId')
   * @param changeDetectorRef Reference to trigger change detection
   * @returns Filtered items and the toggle state
   */
  applySearchFilter<T>(
    searchValue: string,
    allItems: T[],
    keys: Array<string>,
    changeDetectorRef: ChangeDetectorRef,
    slideToggleState: boolean
  ): { filteredItems: T[]; slideToggleState: boolean } {
    searchValue = searchValue.trim().toLowerCase();
    let filteredItems: T[] = [];

    if (searchValue !== '') {
      filteredItems = allItems.filter((item) =>
        keys.some((key) => {
          const keyParts = key.split('.');
          let value = item as any;

          for (let part of keyParts) {
            if (value && value[part] !== undefined) {
              value = value[part];
            } else {
              return false;
            }
          }

          return String(value).toLowerCase().includes(searchValue);
        })
      );
      slideToggleState = false;
    } else {
      filteredItems = [...allItems];
      slideToggleState = true;
    }

    changeDetectorRef.detectChanges();
    return { filteredItems, slideToggleState };
  }

  /**
   * Generic toggle function for slide toggles
   */
  onSlideToggleChange(slideToggleState: boolean): boolean {
    return !slideToggleState;
  }

  /**
   * Generic filter change function
   * @param filters The list of filters
   * @param allItemsAccordion The accordion data (any structure)
   * @param key The key to match filters with
   * @returns Filtered accordion data
   */
  onFilterChange<T>(
    filters: { text: string; checked: boolean }[],
    allItemsAccordion: any,
    key: keyof T
  ): any {
    const activeFilters = filters
      .filter((f) => f.checked)
      .map((f) => f.text.toLowerCase());

    return {
      ...allItemsAccordion,
      accordion: allItemsAccordion.accordion.filter((accordion: T) =>
        activeFilters.includes(String(accordion[key]).toLowerCase())
      ),
    };
  }

  getGroupedDataSet<T>(
    entities: T[],
    groups: { id: string; label: string }[],
    groupAccessor: (entity: T) => string
  ): GroupedDataSet<T> {
    const groupObj: { [key: string]: T[] } = {};

    entities.forEach((entity) => {
      const groupKey = groupAccessor(entity);
      if (!groupObj[groupKey]) {
        groupObj[groupKey] = [];
      }
      groupObj[groupKey].push(entity);
    });

    const groupArray = groups.map((group) => {
      return {
        id: group.id,
        label: group.label,
        entities: groupObj[group.id] || [],
      };
    });

    return { groups: groupArray };
  }

  // /**
  //  * Transforms a list of items into categorized accordion data.
  //  *
  //  * @template T - The type of the input data.
  //  *
  //  * @param {T[]} cards - The list of items to be categorized.
  //  * @param {{ [key: string]: GroupedData }} categories - The categories to group items into, based on the categoryKey.
  //  * @param {keyof T} categoryKey - The key in the item object used to determine the item's category.
  //  * @param {string} cardTitle - The title for the card.
  //  * @param {string} btnText - The text for the button displayed in the accordion.
  //  *
  //  * @returns {GroupedDataSet} - Returns the transformed accordion data with categorized items.
  //  */
  // transformDataToAccordion<T>(
  //   cards: T[],
  //   categories: { [key: string]: GroupedData },
  //   categoryKey: keyof T,
  //   cardTitle: string,
  //   btnText: string
  // ): GroupedDataSet {
  //   const resultCategories = { ...categories };

  //   if ('kladder' in resultCategories) {
  //     cards.forEach((card: any) => {
  //       const status = card.status as string;

  //       card.imageUrl = card.contentImageUrl
  //         ? card.contentImageUrl
  //         : card.availableImages
  //         ? card.getCardImage(card.availableImages)
  //         : 'https://d1zobue3wpsc7w.cloudfront.net/w/720x200.png?text=[Image%20missing]';
  //       if (resultCategories[status]) {
  //         resultCategories[status].cards.push(card);
  //       }
  //     });
  //   } else if ('event' in resultCategories) {
  //     cards.forEach((card: any) => {
  //       const typeOfEvent = card.type as string;
  //       if (typeOfEvent === 'Event') {
  //         resultCategories['event'].cards.push(card);
  //       } else {
  //         resultCategories['personalDeals'].cards.push(card);
  //       }
  //     });
  //   } else {
  //     cards.forEach((card) => {
  //       const category = String(card[categoryKey]).toLowerCase();
  //       if (resultCategories[category]) {
  //         resultCategories[category].cards.push(card);
  //       }
  //     });
  //   }

  //   return {
  //     cardTitle,
  //     btnText,
  //     accordion: Object.values(resultCategories),
  //   };
  // }

  findEventById(eventId: string, events: Event[]): Event | null {
    const event = events.find((event) => event.id === eventId);
    return event ? event : null;
  }
}
