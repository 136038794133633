@if (sessionData$ | async; as sessionData) {
@if (sessionData.storeImpersonation) {
<div class="pt-10">
  <div class="store-impersonation-container">
    <div class="d-flex align-items-center gap-2">
      <img src="../../../../assets/impersonation_icon.svg" alt="impersonation icon" class="icon" />
      <h5 class="store-name">{{ sessionData.store?.place }} <small>({{sessionData.store?.id}})</small></h5>
    </div>

    <div class="d-flex align-items-end gap-1">
      <span class="impersonation-text">Bemærk! Du tilgår i øjeblikket modulet som den valgte butik. Alle
        handlinger, du udfører, vil derfor blive registreret som blev de udført
        af butikken.</span>
      <span class="quit-impersonation-text" (click)="handleQuitImpersonation()">Klik her og vend tilbage til din egen visning</span>
    </div>
  </div>
</div>
}
}
